import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { T } from '@tolgee/react';
import { OrderInterface } from '../../../../../components/OrdersTable/interfaces/Order.interface';
import { SypacButton, SypacText } from '@sypac/component-library-react';
import { MapIcon } from '../../../../../assets/MapIcon';
import HideMap from '../../../../../assets/HideMap';
import Map from '../../../../../components/Map/Map';
import {
	MapMarker,
	PinVariant,
} from '../../../../../components/Map/Map.interface';
import { DriverDto } from '../../../../../services/truck.services';

interface TruckDetailsMapProps {
	selectedOrder: OrderInterface | undefined;
	allocatedTruck?: DriverDto;
}

const TruckDetailsMap: React.FC<TruckDetailsMapProps> = (props) => {
	const { selectedOrder, allocatedTruck } = props;
	const [markers, setMarkers] = useState<MapMarker[]>([]);
	const [loadingMap, setLoading] = useState<boolean>(false);
	const [isMapOpen, setIsMapOpen] = useState<boolean>(true);

	const drivers: any = useMemo(() => {
		return (selectedOrder?.trucks || [])
			.map((truck) => truck.driver)
			.filter((driver) => driver !== undefined);
	}, [selectedOrder?.trucks]);

	const markersMap = useCallback(() => {
		const setMarkersAndLoading = (items: any[]) => {
			setLoading(true);
			const newMarkers = items.map((r: DriverDto) => ({
				latitude: r?.lastLocation?.coordinates[1] || 47.04014,
				longitude: r?.lastLocation?.coordinates[0] || 28.80058,
				variant: PinVariant.truck,
				truck: r,
			}));
			setMarkers(newMarkers);
			setTimeout(() => setLoading(false), 300);
		};

		if (drivers) {
			setMarkersAndLoading(drivers);
		}

		if (allocatedTruck) {
			setMarkersAndLoading([allocatedTruck]);
		}
	}, [allocatedTruck, drivers]);

	useEffect(() => {
		markersMap();
	}, [markersMap]);

	const toggleMap = () => {
		setIsMapOpen(!isMapOpen);
	};

	return (
		<div className="bg-white border border-solid border-gray-10 rounded-xl">
			{isMapOpen && (
				<div className="flex justify-between px-5 pt-4 mb-4">
					<div className="flex items-center gap-3 cursor-pointer ml-auto">
						<span className="flex scale-[1.1]">
							<HideMap />
						</span>
						<SypacButton variant="secondary" size="small">
							<button
								className="w-full border-none bg-transparent p-0 group"
								type="button"
								onClick={toggleMap}
							>
								<SypacText variant="body-normal-medium">
									<p className="transition group-hover:text-gray-80">
										<T keyName="acceptedStep.hideMap">Hide map</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
					</div>
				</div>
			)}

			{isMapOpen ? (
				<div className="h-[320px] px-5 mb-4">
					<div
						className={`w-full h-full ${
							loadingMap ? 'bg-gray-1' : 'bg-transparent'
						}`}
					>
						{!loadingMap ? <Map markers={markers} /> : null}
					</div>
				</div>
			) : (
				<SypacButton
					variant="secondary"
					size="large"
					className="box-border w-full px-5 py-4"
				>
					<button
						className="w-full h-[42px] bg-alabaster rounded-10 px-5 pt-4 transition"
						type="button"
						onClick={toggleMap}
					>
						<span className="mt-[4px] scale-[1.2]">
							<MapIcon />
						</span>
						<SypacText variant="body-normal-medium">
							<p className="transition group-hover:text-gray-80">
								<T keyName="acceptedStep.showMap">Show map</T>
							</p>
						</SypacText>
					</button>
				</SypacButton>
			)}
		</div>
	);
};

export default TruckDetailsMap;
