import React, { useCallback, useEffect, useState } from 'react';
import Select, { StylesConfig } from 'react-select';
import { useFormik } from 'formik';
import classNames from 'classnames';
import {
	SypacButton,
	SypacCheckbox,
	SypacDatepicker,
	SypacIcon,
	SypacInput,
	SypacText,
} from '@sypac/component-library-react';
import { T, useTranslate } from '@tolgee/react';
import * as Yup from 'yup';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Flag from 'react-world-flags';
import dayjs from 'dayjs';

import { toastVariant } from '../CompaniesTable/toastVariant/toastVariant';
import {
	CompanyEditableData,
	CompanyInformationEditableProps,
} from './CompanyInformationEditable.interface';
import './company-information-editable.css';
import { COUNTRIES } from '../DropDownOption/countries.constant';
import { DropDownOption } from '../DropDownOption/DropDownOption';
import { colourErrorStyles } from '../DropDownOption/DropDownStyles';
import { WhatsUp } from '../../assets/WhatsUp';
import { Telegram } from '../../assets/Telegram';
import { Viber } from '../../assets/Viber';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { Geo } from '../SearchLocation/locations.interface';
import { GeoService } from '../../services/geo.services';
import useDebounce from '../../hooks/useDebounce';

const colourStyles: StylesConfig<any, true> = {
	control: (styles) => ({ ...styles, backgroundColor: 'white' }),
	multiValue: (styles, { data }) => {
		return {
			...styles,
			backgroundColor: data.background,
			borderRadius: '15px',
		};
	},
	multiValueLabel: (styles, { data }) => ({
		...styles,
		color: data.color,
	}),
	multiValueRemove: (styles, { data }) => ({
		...styles,
		color: data.color,
		':hover': {
			backgroundColor: data.background,
			color: 'white',
		},
	}),
};

const PREFERRED_COUNTIES = ['pl', 'at', 'de', 'gb', 'fr'];

const CompanyInformationEditable = ({
	data,
	onSave,
}: CompanyInformationEditableProps) => {
	const { t } = useTranslate();
	const [readOnly, setReadOnly] = useState<boolean>(true);
	const [selectedCountry, selectCountry] = useState<any>({});
	const [locationResults, setLocationResults] = useState<boolean>(false);
	const [searchLocation, setSearchLocation] = useState<string>('');
	const searchQuery = useDebounce(searchLocation, 500);
	const [locations, setLocations] = useState<Geo[]>([]);
	const [billingLocationResults, setBillingLocationResults] =
		useState<boolean>(false);
	const [searchBillingLocation, setSearchBillingLocation] =
		useState<string>('');
	const billingSearchQuery = useDebounce(searchBillingLocation, 500);
	const [billingLocations, setBillingLocations] = useState<Geo[]>([]);

	const getSearchLocation = useCallback(() => {
		if (searchQuery.length) {
			GeoService.getLocations({ phrase: searchQuery, maxResults: '10' })
				.then(({ data }) => {
					setLocations(data);
				})
				.catch(console.error);
		} else {
			setLocations([]);
		}
	}, [searchQuery]);

	useEffect(() => {
		getSearchLocation();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchQuery]);

	const getBillingSearchLocation = useCallback(() => {
		if (billingSearchQuery.length) {
			GeoService.getLocations({ phrase: billingSearchQuery, maxResults: '10' })
				.then(({ data }) => {
					setBillingLocations(data);
				})
				.catch(console.error);
		} else {
			setBillingLocations([]);
		}
	}, [billingSearchQuery]);

	useEffect(() => {
		getBillingSearchLocation();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [billingSearchQuery]);

	const companyTypes = [
		{
			label: 'Buyer',
			value: 'customer',
			background: '#494C83',
			color: 'white',
		},
		{
			label: 'Seller',
			value: 'producer',
			background: '#20AC93',
			color: 'white',
		},
		{
			label: 'Carrier',
			value: 'transporter',
			background: '#ffb352',
			color: 'black',
		},
	];

	const getCompanies = (types: string[]) => {
		return types?.map((type) => {
			return companyTypes?.find((opt) => opt.value === type);
		});
	};

	const isCustomer = data.companyTypes.includes('customer');
	const availableCompanyTypes = isCustomer
		? ['customer']
		: ['producer', 'transporter'];

	const setCountry = (data: any, _: any) => {
		selectCountry(data);
		formik.setFieldValue('country', data.value);
	};

	useEffect(() => {
		if (data.country) {
			const country = COUNTRIES.find((item) => item.value === data.country);
			selectCountry({ value: country?.value, label: country?.label });
		}
	}, [data.country]);

	const initialValues = {
		companyName: data.companyName,
		established: data.established,
		companyTypes: data.companyTypes,
		country: data.country,
		vatNumber: data.vatNumber,
		registryNumber: data.registryNumber,
		taxNumber: data.taxNumber,
		registryAddress: data.registryAddress,
		billingAddress: data.billingAddress,
		emailPrimary: data.emailPrimary?.replace('-', ''),
		emailSecondary: data.emailSecondary?.replace('-', ''),
		phoneNumberPrimary: data.phoneNumberPrimary?.replace('-', ''),
		phoneNumberSecondary: data.phoneNumberSecondary?.replace('-', ''),
		primaryIsViber: data.primaryIsViber,
		primaryIsTelegram: data.primaryIsTelegram,
		primaryIsWhatsUp: data.primaryIsWhatsUp,
		secondaryIsViber: data.secondaryIsViber,
		secondaryIsTelegram: data.secondaryIsTelegram,
		secondaryIsWhatsUp: data.secondaryIsWhatsUp,
		allowExcludedTransporter: data.allowExcludedTransporter,
	};

	const formik = useFormik({
		initialValues,
		validationSchema: Yup.object({
			companyTypes: Yup.array().min(1).required(),
			emailPrimary: Yup.string()
				.email(
					t(
						'companyInformationEditable.emailPrimary',
						'Primary email should should be an email',
					),
				)
				.required(
					t(
						'companyInformationEditable.emailPrimary',
						'Primary email should should be an email',
					),
				),
			billingAddress: Yup.string().required(
				t(
					'companyInformationEditable.billingAddress',
					'Billing Address is required',
				),
			),
			registryAddress: Yup.string().required(
				t(
					'companyInformationEditable.registryAddress',
					'Registration address is required',
				),
			),
		}),
		onSubmit: (values) => {
			setReadOnly(true);
			onSave && onSave(values as unknown as CompanyEditableData);
		},
	});

	function onChangeCompany(options: any) {
		formik.setFieldValue(
			'companyTypes',
			options ? options.map((item: any) => item.value) : [],
		);
	}

	const changeBoolValues = (
		key:
			| 'primaryIsViber'
			| 'primaryIsTelegram'
			| 'primaryIsWhatsUp'
			| 'secondaryIsViber'
			| 'secondaryIsTelegram'
			| 'secondaryIsWhatsUp'
			| 'allowExcludedTransporter',
	) => {
		const value = !formik.values[key];
		formik?.setFieldValue(key, value);
	};

	const changePhone = (
		key: 'phoneNumberPrimary' | 'phoneNumberSecondary',
		value: string,
	) => {
		formik?.setFieldValue(key, value);
	};

	const onSelectLocation = (location: Geo) => {
		formik.setFieldValue('registryAddress', location.label);
		setLocationResults(false);
	};

	const onSelectBillingLocation = (location: Geo) => {
		formik.setFieldValue('billingAddress', location.label);
		setBillingLocationResults(false);
	};

	const onSubmit = (e: React.FormEvent<HTMLFormElement> | undefined) => {
		formik.validateForm(formik.values);
		formik.handleSubmit(e);
		if (!formik.isValid) {
			toastVariant(
				t(
					'companyInformationEditable.pleaseFillRequiredFields',
					'Please fill in all required fields',
				),
				true,
			);
		}
	};

	const toggleButton = () => {
		changeBoolValues('allowExcludedTransporter');
	};

	const registryRef = useDetectClickOutside({
		onTriggered: () => setLocationResults(false),
	});
	const billingRef = useDetectClickOutside({
		onTriggered: () => setBillingLocationResults(false),
	});

	return (
		<form
			onSubmit={onSubmit}
			className="flex flex-col company-information-editable-border px-10 py-5 gap-6"
		>
			<div className="flex justify-between items-center company-information-editable-border-bottom pb-5">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-90 -tracking-[0.01em]">
						<T keyName="companyInformationEditable.generalCompanyInformation">
							General company information
						</T>
					</p>
				</SypacText>
				{readOnly ? (
					<SypacButton variant="secondary" size="small">
						<button
							className="px-5 py-[5px]"
							onClick={() => setReadOnly(false)}
						>
							<SypacIcon
								iconName="Document Add"
								size="custom"
								width="24px"
								height="24px"
							/>
							<SypacText variant="body-regular-medium">
								<p>
									<T keyName="companyInformationEditable.edit">Edit</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
				) : (
					<div className="flex gap-4">
						<SypacButton variant="secondary" size="small">
							<button
								className="border-red text-red px-5 py-[5px]"
								onClick={() => {
									setReadOnly(true);
									formik?.setTouched({}, false);
									formik.setValues(initialValues);
								}}
							>
								<SypacIcon
									iconName="Close Circle"
									size="custom"
									width="24px"
									height="24px"
								/>
								<SypacText variant="body-regular-medium">
									<p>
										<T keyName="companyInformationEditable.cancel">Cancel</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
						<SypacButton variant="secondary" size="small">
							<button
								type="submit"
								className="border-mountain-meadow text-mountain-meadow px-5 py-[5px]"
							>
								<SypacIcon
									iconName="Check Circle"
									size="custom"
									width="24px"
									height="24px"
								/>
								<SypacText variant="body-regular-medium">
									<p>
										<T keyName="companyInformationEditable.save">Save</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
					</div>
				)}
			</div>
			<div className="grid grid-cols-2 gap-4 company-information-editable-border-bottom pb-5">
				<SypacInput
					error={!!(formik.touched.companyName && formik.errors.companyName)}
				>
					<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em]">
						<T keyName="companyInformationEditable.companyName">Company Name</T>
					</label>
					<input
						readOnly={readOnly}
						className={classNames(
							'py-2.5 pl-3 border rounded-md placeholder:text-gray-22',
							{ 'pointer-events-none': readOnly },
						)}
						name="companyName"
						type="text"
						placeholder={t(
							'companyInformationEditable.enterCompanyName',
							'Enter Company Name',
						)}
						value={formik?.values.companyName}
						onChange={(event) => formik?.handleChange(event)}
					/>
				</SypacInput>
				<SypacInput
					error={!!(formik.touched.established && formik.errors.established)}
				>
					<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em]">
						<T keyName="companyInformationEditable.established">Established</T>
					</label>
					<SypacDatepicker
						name="establishedDate"
						className={classNames({ 'pointer-events-none': readOnly })}
						placeholder={t(
							'companyInformationEditable.enterEstablished',
							'Enter Established',
						)}
						min="1950-01-01"
						max={dayjs().toString()}
						value={dayjs(formik?.values.established).format('DD MMM. YYYY')}
						onCallback={({ detail }: any) =>
							formik.setFieldValue('established', detail)
						}
						inputReadOnly={true}
					/>
					{/* <input
						readOnly={readOnly}
						className={classNames(
							'py-2.5 pl-3 border rounded-md placeholder:text-gray-22',
							{ 'pointer-events-none': readOnly },
						)}
						name="established"
						type="text"
						placeholder={t(
							'companyInformationEditable.enterEstablished',
							'Enter Established',
						)}
						value={formik?.values.established}
						onChange={(event) => formik?.handleChange(event)}
					/> */}
				</SypacInput>
				<SypacInput error={!!(formik.touched.country && formik.errors.country)}>
					<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em]">
						<T keyName="companyInformationEditable.companyType">Company type</T>
					</label>
					<Select
						closeMenuOnSelect={false}
						value={getCompanies(formik.values.companyTypes)}
						isMulti
						options={getCompanies(availableCompanyTypes)}
						isDisabled={readOnly}
						onChange={onChangeCompany}
						className={classNames({ 'pointer-events-none': readOnly })}
						placeholder={t(
							'companyInformationEditable.companyTypes',
							'Company types',
						)}
						name="companyTypes"
						onBlur={formik.handleBlur}
						menuPlacement={'auto'}
						classNamePrefix="dropdown"
						styles={
							formik.touched.companyTypes && formik.errors.companyTypes
								? { ...colourStyles, ...colourErrorStyles }
								: colourStyles
						}
					/>
				</SypacInput>
				<SypacInput error={!!(formik.touched.country && formik.errors.country)}>
					<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em]">
						<T keyName="companyInformationEditable.country">Country</T>
					</label>
					<Select
						className={classNames({ 'pointer-events-none': readOnly })}
						placeholder={t('companyInformationEditable.country', 'Country')}
						name="country"
						onChange={setCountry}
						onBlur={formik.handleBlur}
						options={COUNTRIES}
						menuPlacement={'auto'}
						components={{
							Option: (options) =>
								DropDownOption(options, formik.values.country),
						}}
						classNamePrefix="dropdown"
						value={
							formik.values.country
								? {
										value: selectedCountry.value,
										label: (
											<div className="flex flex-row">
												<div
													className={`flex flex-row h-8 hover:bg-mercury-50 justify-start items-center w-full min-w-full`}
												>
													<Flag
														code={selectedCountry.value}
														className="h-6 w-10 rounded"
													/>
													<div className="ml-2 text-base text-gray-100 truncate">
														{selectedCountry.label}
													</div>
												</div>
											</div>
										),
								  }
								: ''
						}
						styles={
							formik.touched.country && formik.errors.country
								? { ...colourStyles, ...colourErrorStyles }
								: colourStyles
						}
					/>
				</SypacInput>
				<SypacInput
					error={!!(formik.touched.vatNumber && formik.errors.vatNumber)}
				>
					<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em]">
						<T keyName="companyInformationEditable.vatNumber">VAT Number</T>
					</label>
					<input
						readOnly={readOnly}
						className={classNames(
							'py-2.5 pl-3 border rounded-md placeholder:text-gray-22',
							{ 'pointer-events-none': readOnly },
						)}
						name="vatNumber"
						type="text"
						placeholder={t(
							'companyInformationEditable.enterVatNumber',
							'Enter VAT Number',
						)}
						value={formik?.values.vatNumber}
						onChange={(event) => formik?.handleChange(event)}
					/>
				</SypacInput>
				<SypacInput
					error={!!(formik.touched.taxNumber && formik.errors.taxNumber)}
				>
					<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em]">
						<T keyName="companyInformationEditable.companyTaxNumber">
							Tax Identification Number
						</T>
					</label>
					<input
						readOnly={readOnly}
						className={classNames(
							'py-2.5 pl-3 border rounded-md placeholder:text-gray-22',
							{ 'pointer-events-none': readOnly },
						)}
						name="taxNumber"
						type="text"
						placeholder={t(
							'companyInformationEditable.enterCompanyTaxNumber',
							'Enter Tax Identification Number',
						)}
						value={formik?.values.taxNumber}
						onChange={(event) => formik?.handleChange(event)}
					/>
				</SypacInput>
				<SypacInput
					error={
						!!(formik.touched.registryNumber && formik.errors.registryNumber)
					}
				>
					<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em]">
						<T keyName="companyInformationEditable.companyRegistryNumber">
							Company Registration Number
						</T>
					</label>
					<input
						readOnly={readOnly}
						className={classNames(
							'py-2.5 pl-3 border rounded-md placeholder:text-gray-22',
							{ 'pointer-events-none': readOnly },
						)}
						name="registryNumber"
						type="text"
						placeholder={t(
							'companyInformationEditable.enterCompanyRegistryNumber',
							'Enter Company Registration Number',
						)}
						value={formik?.values.registryNumber}
						onChange={(event) => formik?.handleChange(event)}
					/>
				</SypacInput>
			</div>

			{!readOnly && isCustomer ? (
				<>
					<div className="flex flex-col gap-5">
						<SypacText variant="body-regular-medium">
							<p className="text-base text-gray-40">Self-service</p>
						</SypacText>

						<div className="flex flex-col gap-3">
							<div className="flex gap-4 items-center">
								<SypacText
									variant="body-regular-medium"
									className="w-[185px] border-0 border-solid border-r-[2px] border-gray-10 pr-4"
								>
									<p className="text-xl text-primary-dark-gray">
										{formik.values.allowExcludedTransporter
											? 'Self-service enabled'
											: 'Self-service disabled'}
									</p>
								</SypacText>

								<button
									type="button"
									className={`relative flex w-[32px] h-[14px] border-none rounded-full items-center transition duration-300 ease-in-out cursor-pointer ${
										formik.values.allowExcludedTransporter
											? 'bg-secondary-violet'
											: 'bg-gray-10'
									}`}
									onClick={toggleButton}
								>
									<div
										className={`absolute box-border left-0 w-[20px] h-[20px] rounded-full bg-white border-[2px] border-solid cursor-pointer transition-transform duration-300 ease-in-out ${
											formik.values.allowExcludedTransporter
												? 'translate-x-[14px] border-secondary-violet'
												: '-translate-x-[2px] border-gray-10'
										}`}
									/>
								</button>
							</div>

							<SypacText variant="body-regular-medium">
								<p className="text-base text-gray-40">
									Allows this company to use it's own trucks to pickup ordered
									goods.
								</p>
							</SypacText>
						</div>
					</div>
					<div className="border-0 border-solid border-t-[1px] border-gray-10" />
				</>
			) : null}

			<div className="flex flex-col gap-6">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-90 -tracking-[0.01em]">
						<T keyName="companyInformationEditable.generalCompanyInformation">
							General company information
						</T>
					</p>
				</SypacText>
				<div className="grid grid-cols-2 gap-x-4 gap-y-5">
					<div ref={registryRef} className="relative">
						<SypacInput
							error={
								!!(
									formik.touched.registryAddress &&
									formik.errors.registryAddress
								)
							}
						>
							<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em]">
								<T keyName="companyInformationEditable.registryAddress">
									Registration address
								</T>
							</label>

							<input
								readOnly={readOnly}
								autoComplete="off"
								type="text"
								name="registryAddress"
								className={`py-2.5 pl-3 border rounded-md placeholder:text-gray-22 transition ${
									locationResults
										? 'border-cornflower-blue'
										: 'hover:border-gray-30'
								} ${readOnly ? 'pointer-events-none' : ''}`}
								placeholder={t(
									'companyInformationEditable.enterRegistryAddress',
									'Enter Registration address',
								)}
								onBlur={formik.handleBlur}
								value={formik?.values.registryAddress}
								onFocus={() => setLocationResults(true)}
								onChange={(event) => {
									formik.handleChange(event);
									setSearchLocation(event.target.value);
								}}
							/>
							{formik?.touched.registryAddress &&
							formik?.errors.registryAddress ? (
								<span className="input-error">
									{formik.errors.registryAddress}
								</span>
							) : null}
						</SypacInput>

						<div
							className={`w-[303px] flex-col shadow-dropdown border-[1px] border-gray-10 rounded-lg max-h-72 overflow-hidden bg-white z-50 ${
								isCustomer ? 'absolute top-[70px]' : 'fixed'
							} ${locationResults ? 'flex' : 'hidden'}`}
							style={
								!isCustomer
									? {
											// @ts-ignore
											bottom: `${registryRef?.current?.offsetTop - 878}px`,
									  }
									: undefined
							}
						>
							{locations?.length ? (
								<div className="flex flex-col w-full p-3 overflow-y-scroll">
									{locations.map((location: Geo) => (
										<div
											key={location.locationId}
											className="flex items-center hover:bg-gray-10-opacity-50 rounded-md cursor-pointer mr-[9px]"
											onClick={() => onSelectLocation(location)}
										>
											<div className="mx-5">
												<Flag
													className="rounded-[3px]"
													code={location.countryCode.toLocaleUpperCase()}
													width={22}
													height={16}
												/>
											</div>
											<SypacText
												variant="body-normal-medium"
												className="my-[15px]"
											>
												<p>
													{location.address?.city}, {location.address?.street}{' '}
													str.,
													{location.address?.houseNumber
														? ` ${location.address?.houseNumber},`
														: ''}{' '}
													Zip {location.address?.postalCode}
												</p>
											</SypacText>
										</div>
									))}
								</div>
							) : null}
						</div>
					</div>

					<div ref={billingRef} className="relative">
						<SypacInput
							error={
								!!(
									formik.touched.billingAddress && formik.errors.billingAddress
								)
							}
						>
							<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em]">
								<T keyName="companyInformationEditable.billingAddress">
									Billing Address
								</T>
							</label>

							<input
								readOnly={readOnly}
								autoComplete="off"
								type="text"
								name="billingAddress"
								className={`py-2.5 pl-3 border rounded-md placeholder:text-gray-22 transition ${
									billingLocationResults
										? 'border-cornflower-blue'
										: 'hover:border-gray-30'
								} ${readOnly ? 'pointer-events-none' : ''}`}
								placeholder={t(
									'companyInformationEditable.enterBillingAddress',
									'Enter Billing Address',
								)}
								onBlur={formik.handleBlur}
								value={formik?.values.billingAddress}
								onFocus={() => setBillingLocationResults(true)}
								onChange={(event) => {
									formik.handleChange(event);
									setSearchBillingLocation(event.target.value);
								}}
							/>
							{formik?.touched.billingAddress &&
							formik?.errors.billingAddress ? (
								<span className="input-error">
									{formik.errors.billingAddress}
								</span>
							) : null}
						</SypacInput>

						<div
							className={`w-[303px] flex-col shadow-dropdown border-[1px] border-gray-10 rounded-lg max-h-72 overflow-hidden bg-white z-50 ${
								isCustomer ? 'absolute top-[70px]' : 'fixed'
							} ${billingLocationResults ? 'flex' : 'hidden'}`}
							style={
								!isCustomer
									? {
											// @ts-ignore
											bottom: `${billingRef?.current?.offsetTop - 878}px`,
									  }
									: undefined
							}
						>
							{billingLocations?.length ? (
								<div className="flex flex-col w-full p-3 overflow-y-scroll">
									{billingLocations.map((location: Geo) => (
										<div
											key={location.locationId}
											className="flex items-center hover:bg-gray-10-opacity-50 rounded-md cursor-pointer mr-[9px]"
											onClick={() => onSelectBillingLocation(location)}
										>
											<div className="mx-5">
												<Flag
													className="rounded-[3px]"
													code={location.countryCode.toLocaleUpperCase()}
													width={22}
													height={16}
												/>
											</div>
											<SypacText
												variant="body-normal-medium"
												className="my-[15px]"
											>
												<p>
													{location.address?.city}, {location.address?.street}{' '}
													str.,
													{location.address?.houseNumber
														? ` ${location.address?.houseNumber},`
														: ''}{' '}
													Zip {location.address?.postalCode}
												</p>
											</SypacText>
										</div>
									))}
								</div>
							) : null}
						</div>
					</div>

					<SypacInput
						error={
							!!(formik.touched.emailPrimary && formik.errors.emailPrimary)
						}
					>
						<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em]">
							<T keyName="companyInformationEditable.emailAddress">
								Email address
							</T>
						</label>
						<input
							readOnly={readOnly}
							className={classNames(
								'py-2.5 pl-3 border rounded-md placeholder:text-gray-22',
								{ 'pointer-events-none': readOnly },
							)}
							name="emailPrimary"
							type="text"
							placeholder={t(
								'companyInformationEditable.enterEmailAddress',
								'Enter Email address',
							)}
							value={formik?.values.emailPrimary}
							onChange={(event) => formik?.handleChange(event)}
						/>
						{formik?.touched.emailPrimary && formik?.errors.emailPrimary ? (
							<span className="input-error">{formik.errors.emailPrimary}</span>
						) : null}
					</SypacInput>

					<SypacInput
						error={
							!!(formik.touched.emailSecondary && formik.errors.emailSecondary)
						}
					>
						<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em]">
							<T keyName="companyInformationEditable.secondaryEmailAddress">
								Secondary email address
							</T>
						</label>
						<input
							readOnly={readOnly}
							className={classNames(
								'py-2.5 pl-3 border rounded-md placeholder:text-gray-22',
								{ 'pointer-events-none': readOnly },
							)}
							name="emailSecondary"
							type="text"
							placeholder={t(
								'companyInformationEditable.enterSecondaryEmailAddress',
								'Enter Secondary email address',
							)}
							value={formik?.values.emailSecondary}
							onChange={(event) => formik?.handleChange(event)}
						/>
						{formik?.touched.emailSecondary && formik?.errors.emailSecondary ? (
							<span className="input-error">
								{formik.errors.emailSecondary}
							</span>
						) : null}
					</SypacInput>

					<div className="flex flex-col gap-4">
						<SypacInput
							error={
								!!(
									formik.touched.phoneNumberPrimary &&
									formik.errors.phoneNumberPrimary
								)
							}
						>
							<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em]">
								<T keyName="companyInformationEditable.primaryPhoneNumber">
									Primary phone number
								</T>
							</label>
							<PhoneInput
								disabled={readOnly}
								containerClass={'border border-mischka border-solid rounded-md'}
								inputClass={classNames({
									'pointer-events-none': readOnly,
								})}
								placeholder={t(
									'companyInformationEditable.enterPrimaryPhoneNumber',
									'Enter Primary Phone Number',
								)}
								value={formik?.values.phoneNumberPrimary}
								onChange={(phone) => changePhone('phoneNumberPrimary', phone)}
								preferredCountries={PREFERRED_COUNTIES}
								country="pl"
							/>
						</SypacInput>
						<div className="flex justify-end gap-[14px]">
							<div className="flex gap-1.5 items-center">
								{!readOnly ? (
									<SypacCheckbox size="md">
										<input
											name="primaryIsWhatsUp"
											checked={formik?.values.primaryIsWhatsUp}
											onChange={() => changeBoolValues('primaryIsWhatsUp')}
											type="checkbox"
										/>
									</SypacCheckbox>
								) : null}
								{formik?.values.primaryIsWhatsUp || !readOnly ? (
									<WhatsUp />
								) : null}
							</div>
							<div className="flex gap-1.5 items-center">
								{!readOnly ? (
									<SypacCheckbox size="md">
										<input
											name="primaryIsTelegram"
											checked={formik?.values.primaryIsTelegram}
											onChange={() => changeBoolValues('primaryIsTelegram')}
											type="checkbox"
										/>
									</SypacCheckbox>
								) : null}
								{formik?.values.primaryIsTelegram || !readOnly ? (
									<Telegram />
								) : null}
							</div>
							<div className="flex gap-1.5 items-center">
								{!readOnly ? (
									<SypacCheckbox size="md">
										<input
											name="primaryIsViber"
											checked={formik?.values.primaryIsViber}
											onChange={() => changeBoolValues('primaryIsViber')}
											type="checkbox"
										/>
									</SypacCheckbox>
								) : null}
								{formik?.values.primaryIsViber || !readOnly ? <Viber /> : null}
							</div>
						</div>
					</div>
					<div className="flex flex-col gap-4">
						<SypacInput
							error={
								!!(
									formik.touched.phoneNumberSecondary &&
									formik.errors.phoneNumberSecondary
								)
							}
						>
							<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em]">
								<T keyName="companyInformationEditable.secondaryPhoneNumber">
									Secondary phone number
								</T>
							</label>
							<PhoneInput
								disabled={readOnly}
								containerClass={'border border-mischka border-solid rounded-md'}
								inputClass={classNames({
									'pointer-events-none': readOnly,
								})}
								placeholder={t(
									'companyInformationEditable.enterSecondaryPhoneNumber',
									'Enter Secondary Phone Number',
								)}
								country="pl"
								value={formik?.values.phoneNumberSecondary}
								onChange={(phone) => changePhone('phoneNumberSecondary', phone)}
								preferredCountries={PREFERRED_COUNTIES}
							/>
						</SypacInput>
						<div className="flex justify-end gap-[14px]">
							<div className="flex gap-1.5 items-center">
								{!readOnly ? (
									<SypacCheckbox size="md">
										<input
											name="secondaryIsWhatsUp"
											checked={formik?.values.secondaryIsWhatsUp}
											onChange={() => changeBoolValues('secondaryIsWhatsUp')}
											type="checkbox"
										/>
									</SypacCheckbox>
								) : null}
								{formik?.values.secondaryIsWhatsUp || !readOnly ? (
									<WhatsUp />
								) : null}
							</div>
							<div className="flex gap-1.5 items-center">
								{!readOnly ? (
									<SypacCheckbox size="md">
										<input
											name="secondaryIsTelegram"
											checked={formik?.values.secondaryIsTelegram}
											onChange={() => changeBoolValues('secondaryIsTelegram')}
											type="checkbox"
										/>
									</SypacCheckbox>
								) : null}
								{formik?.values.secondaryIsTelegram || !readOnly ? (
									<Telegram />
								) : null}
							</div>
							<div className="flex gap-1.5 items-center">
								{!readOnly ? (
									<SypacCheckbox size="md">
										<input
											name="secondaryIsViber"
											checked={formik?.values.secondaryIsViber}
											onChange={() => changeBoolValues('secondaryIsViber')}
											type="checkbox"
										/>
									</SypacCheckbox>
								) : null}
								{formik?.values.secondaryIsViber || !readOnly ? (
									<Viber />
								) : null}
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
	);
};

export default CompanyInformationEditable;
