const TermsIcon = () => {
	return (
		<svg
			width="22"
			height="22"
			viewBox="0 0 22 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M2.5 7C2.5 4.17157 2.5 2.75736 3.37868 1.87868C4.25736 1 5.67157 1 8.5 1H14.5C17.3284 1 18.7426 1 19.6213 1.87868C20.5 2.75736 20.5 4.17157 20.5 7V15C20.5 17.8284 20.5 19.2426 19.6213 20.1213C18.7426 21 17.3284 21 14.5 21H8.5C5.67157 21 4.25736 21 3.37868 20.1213C2.5 19.2426 2.5 17.8284 2.5 15V7Z"
				stroke="#454545"
				strokeWidth="1.5"
			/>
			<path
				d="M7.5 1.5V21"
				stroke="#454545"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M1.5 11H3.5"
				stroke="#454545"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M1.5 15H3.5"
				stroke="#454545"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M1.5 7H3.5"
				stroke="#454545"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M11 5.5H16"
				stroke="#454545"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M11 9H16"
				stroke="#454545"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
		</svg>
	);
};

export default TermsIcon;
