import { IconInterface } from '../interfaces/icon.interface';

export const MapPin = (props: IconInterface) => {
	const { color } = props;

	return (
		<svg
			width="16"
			height="18"
			viewBox="0 0 16 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1.5 7.51464C1.5 3.9167 4.41015 1 8 1C11.5899 1 14.5 3.9167 14.5 7.51464C14.5 11.0844 12.4254 15.2499 9.18863 16.7396C8.43408 17.0868 7.56592 17.0868 6.81137 16.7396C3.57458 15.2499 1.5 11.0844 1.5 7.51464Z"
				stroke={color || 'gray'}
				strokeWidth="1.5"
			/>
			<ellipse cx="8" cy="7.4" rx="2.4375" ry="2.4" fill={color || 'gray'} />
		</svg>
	);
};
