import React, { useMemo, useState } from 'react';
import OrderDetailsStepper from '../../../../../../components/OrderDetailsStepper/OrderDetailsStepper';
import FulfilmentSchedule from '../../AdditionalDetails/FulfilmentSchedule';
import PickUpDeliveryDetails from '../../AdditionalDetails/PickUp&DeliveryDetails';
import BaseOrderDetails from '../../../../../../components/BaseOrderDetails/BaseOrderDetails';
import { AdminOrderProps } from '../../../adminOrder.interface';
import PaymentTerms from '../../AdditionalDetails/PaymentTerms';

const Delivered: React.FC<AdminOrderProps> = (props) => {
	const { selectedOrder } = props;
	const { deliveryIncluded } = selectedOrder!;
	const [stepperMargin, setStepperMargin] = useState<number>(0);

	const simpleOrder = useMemo(() => {
		return selectedOrder?.trucks?.length === 1;
	}, [selectedOrder?.trucks]);

	const handleWidthChange = (value: number) => {
		setStepperMargin(value);
	};

	return (
		<>
			<div style={{ marginBottom: `${stepperMargin}px` }}>
				<OrderDetailsStepper
					current={
						simpleOrder && deliveryIncluded
							? 12
							: simpleOrder && !deliveryIncluded
							? 6
							: 5
					}
					simpleOrder={simpleOrder}
					isFinalStep={true}
					order={selectedOrder}
					onWidthChange={handleWidthChange}
				/>
			</div>

			<div className="px-10 pt-7 pb-3.5">
				<BaseOrderDetails
					selectedOrder={selectedOrder}
					isOpen={true}
					simpleOrder={simpleOrder}
					deliveryIncluded={deliveryIncluded!}
					target={selectedOrder?.target!}
				/>
			</div>

			<div className="px-10 py-3.5">
				<PaymentTerms selectedOrder={selectedOrder} forSeller={true} />
			</div>

			{deliveryIncluded && (
				<div className="px-10 py-3.5">
					<PaymentTerms selectedOrder={selectedOrder} forSeller={false} />
				</div>
			)}

			<div className="px-10 py-3.5">
				{deliveryIncluded ? (
					<PickUpDeliveryDetails
						selectedOrder={selectedOrder}
						target={selectedOrder?.target}
						isPickUp={false}
						simpleOrder={simpleOrder}
					/>
				) : (
					<PickUpDeliveryDetails
						selectedOrder={selectedOrder}
						target={selectedOrder?.target}
						isPickUp={true}
						simpleOrder={simpleOrder}
					/>
				)}
			</div>

			<div className="px-10 py-3.5">
				{deliveryIncluded ? (
					<PickUpDeliveryDetails
						selectedOrder={selectedOrder}
						target={selectedOrder?.target}
						isPickUp={true}
						simpleOrder={simpleOrder}
					/>
				) : (
					<FulfilmentSchedule
						selectedOrder={selectedOrder}
						step={
							simpleOrder && deliveryIncluded ? 12 : !deliveryIncluded ? 6 : 5
						}
						deliveryIncluded={deliveryIncluded!}
						simpleOrder={simpleOrder}
					/>
				)}
			</div>

			<div className="px-10 pb-7 pt-3.5">
				{deliveryIncluded ? (
					<FulfilmentSchedule
						selectedOrder={selectedOrder}
						step={
							simpleOrder && deliveryIncluded ? 12 : !deliveryIncluded ? 6 : 5
						}
						deliveryIncluded={deliveryIncluded!}
						simpleOrder={simpleOrder}
					/>
				) : (
					<PickUpDeliveryDetails
						selectedOrder={selectedOrder}
						target={selectedOrder?.target}
						isPickUp={false}
						simpleOrder={simpleOrder}
					/>
				)}
			</div>
		</>
	);
};

export default Delivered;
