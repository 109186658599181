import React, { useState } from 'react';
import {
	SypacText,
	SypacBookTabs,
	SypacTabBook,
} from '@sypac/component-library-react';
import { useTranslate, T } from '@tolgee/react';
import ProducerAndCarrierBilling from './components/ProducerAndCarrierBilling';
import ClientBillingForAdmin from './components/ClientBillingForAdmin';

const Billing: React.FC = () => {
	const { t } = useTranslate();
	const [tab, setTab] = useState<string>('client');

	return (
		<>
			<div className="relative flex flex-col gap-5 w-[calc(100vw-67px)] xl-2xl:w-[calc(100vw-83px)] h-full">
				<SypacText variant="heading-4" className="ml-[30px]">
					<p className="text-gray-80">
						<T keyName="producerBilling.adminDashboard">Admin dashboard</T>
					</p>
				</SypacText>

				<div className="flex justify-between border-0 border-solid border-b-[1px] border-gray-10">
					<div className="w-[616px] ml-[30px]">
						<SypacBookTabs className="mt-3.5">
							<SypacTabBook
								label={t('billing.client', 'Buyer')}
								button-id="client"
								onClick={() => setTab('client')}
							/>
							<SypacTabBook
								label={t('billing.producer', 'Seller')}
								button-id="producer"
								onClick={() => setTab('producer')}
							/>
							<SypacTabBook
								label={t('billing.transporter', 'Carrier')}
								button-id="transporter"
								onClick={() => setTab('transporter')}
							/>
						</SypacBookTabs>
					</div>
				</div>

				<div className="flex h-full">
					{tab === 'transporter' ? (
						<ProducerAndCarrierBilling target="transporter" />
					) : tab === 'producer' ? (
						<ProducerAndCarrierBilling />
					) : (
						<ClientBillingForAdmin />
					)}
				</div>
			</div>
		</>
	);
};

export default Billing;
