import React from 'react';
import { SypacIcon } from '@sypac/component-library-react';
import ProducerCompanyIcon from '../../assets/ProducerCompanyIcon';
import { Person } from '../../assets/Person';

export const RenderIconByTarget = ({ target }: { target: string }) => {
	const Icons: Record<string, () => JSX.Element> = {
		customer: () => (
			<span className="flex scale-[0.9] m-0">
				<Person color="white" />
			</span>
		),
		transporter: () => (
			<SypacIcon iconName="Bus" size="custom" width="20px" height="24px" />
		),
		producer: () => (
			<span className="flex scale-[0.9] m-0">
				<ProducerCompanyIcon color="white" />
			</span>
		),
	};
	const Icon = Icons[target] || Icons['customer'];
	return <Icon />;
};
