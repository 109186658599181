import React from 'react';
import { SypacText } from '@sypac/component-library-react';
import { T } from '@tolgee/react';
import { Checkmark } from '../../../assets/Checkmark';
import { ExpandedStepperProps } from '../OrderDetailsStepper.interface';

const badgeStatus = [
	{ keyName: 'expandedStepper.inProgress', label: 'In Progress' },
	{ keyName: 'expandedStepper.done', label: 'Done' },
	{ keyName: 'expandedStepper.toDo', label: 'To do' },
];

const ExpandedStepper: React.FC<ExpandedStepperProps> = ({
	currentStep,
	current,
}) => {
	const isCurrent = (index: number) => current === index;
	const isCompleted = (index: number) => current > index;
	const isLastStep = () => current === currentStep?.length;

	const stepBackgroundColor = (index: number) => {
		if (isCurrent(index) && !isLastStep()) return 'bg-peach';
		if (isCompleted(index) || isLastStep()) return 'bg-shadow-blue';
		return 'bg-light-grey';
	};

	const boxBackgroundColor = (index: number) => {
		if (isCurrent(index) && !isLastStep()) return 'bg-peach';
		if (isCompleted(index) || isLastStep()) return 'bg-gray-30';
		return 'bg-light-grey';
	};

	const stepTextColor = (index: number) => {
		if (isCurrent(index) && !isLastStep()) return 'text-goldenrod';
		if (isCompleted(index) || isLastStep()) return 'text-white';
		return 'text-cadet-grey';
	};

	const titleTextColor = (index: number) => {
		if (isCurrent(index) && !isLastStep()) return 'text-goldenrod';
		if (isCompleted(index) || isLastStep()) return 'text-gray-80';
		return 'text-cadet-grey';
	};

	const renderBadgeStatus = (index: number) => {
		if (isCurrent(index) && !isLastStep()) return badgeStatus[0];
		if (isCompleted(index) || isLastStep()) return badgeStatus[1];
		return badgeStatus[2];
	};

	return (
		<div className="flex w-full flex-col gap-4">
			{currentStep?.map((label, idx) => {
				const badge = renderBadgeStatus(idx + 1);

				return (
					<div key={idx} className="flex justify-between px-10">
						<div className="flex gap-5">
							<div
								className={`w-[20px] h-[20px] flex justify-center items-center ${boxBackgroundColor(
									idx + 1,
								)} rounded-md`}
							>
								<SypacText variant="overline-regular-large">
									<p className={`text-xs ${stepTextColor(idx + 1)}`}>
										{idx + 1}
									</p>
								</SypacText>
							</div>
							<SypacText variant="overline-regular-large">
								<p
									className={`text-base ${titleTextColor(
										idx + 1,
									)} tracking-tighter`}
								>
									<T keyName={`orderStepper.${label}`}>{label}</T>
								</p>
							</SypacText>
						</div>

						<div
							className={`w-[94px] h-[20px] flex justify-center items-center ${stepBackgroundColor(
								idx + 1,
							)} rounded-md`}
						>
							{isCompleted(idx + 1) || isLastStep() ? (
								<span className="p-0 m-0 flex scale-[0.6] mr-[4px]">
									<Checkmark />
								</span>
							) : null}
							<SypacText variant="overline-regular-large">
								<p className={`text-xs ${stepTextColor(idx + 1)}`}>
									<T keyName={badge.keyName}>{badge.label}</T>
								</p>
							</SypacText>
						</div>
					</div>
				);
			})}
			<div className="border-0 border-solid border-t-[1px] border-gray-10" />
		</div>
	);
};

export default ExpandedStepper;
