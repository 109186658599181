import React from 'react';
import {
	SypacVerticalTimeline,
	SypacVerticalTimelineStep,
} from '@sypac/component-library-react';
import TruckAllocated from '../../../../../../components/Notification/TruckAllocated';
import { useTranslate } from '@tolgee/react';

interface NotificationTruckAllocatedProps {
	notification: boolean;
	date: string;
	driverName: string;
	truckName: string;
	truckPlates: string;
	truckId: string;
	truckType: string;
}
const NotificationTruckAllocated = ({
	notification,
	date,
	driverName,
	truckPlates,
	truckId,
	truckName,
	truckType,
}: NotificationTruckAllocatedProps) => {
	const { t } = useTranslate();

	return (
		<div className="flex flex-row gap-6">
			<SypacVerticalTimeline>
				<SypacVerticalTimelineStep isActive={notification} minWidth={280}>
					{date}
				</SypacVerticalTimelineStep>
			</SypacVerticalTimeline>
			<div className="h-fit w-full">
				<TruckAllocated
					notification={notification}
					title={t(
						'notificationTruckAllocated.truckAllocated',
						'Truck allocated',
					)}
					description={t(
						'notificationTruckAllocated.orderSentToDriver',
						'Order sent to driver — awaiting confirmation.',
					)}
					driverName={driverName}
					truckName={truckName}
					truckType={truckType}
					truckPlates={truckPlates}
					truckId={truckId}
				/>
			</div>
		</div>
	);
};

export default NotificationTruckAllocated;
