import React, { useContext } from 'react';
import { SypacText } from '@sypac/component-library-react';
import { useTranslate } from '@tolgee/react';
import { ClientStepperProps } from './ClientStepper.interface';
import { OrderContext } from '../../context/OrderContext/order.context';

const ClientStepper: React.FC<ClientStepperProps> = (props) => {
	const { t } = useTranslate();
	const { currentStep } = props;
	const { order } = useContext(OrderContext);

	const CLIENT_STEPS: string[] = [
		t('clientSelectQuantity.selectQuantity', 'Select quantity'),
		order?.deliveryIncluded !== false
			? t('clientSelectQuantity.scheduleYourDelivery', 'Schedule your delivery')
			: t('clientSelectQuantity.scheduleYourPickUp', 'Schedule your pick up'),
		t('clientSelectQuantity.selectPaymentMethod', 'Select payment method'),
	];

	const firstStepIndex = Math.max(0, currentStep - 1);
	const displayedSteps = CLIENT_STEPS.slice(firstStepIndex, firstStepIndex + 3);

	return (
		<div className="flex w-[1133px] sm:w-full gap-6 sm:gap-10 items-center">
			{displayedSteps.map((step, index) => {
				const isActive = index + firstStepIndex + 1 === currentStep;

				return (
					<React.Fragment key={index}>
						<div
							className={`flex gap-4 sm:gap-6 items-center ${
								isActive ? 'text-gray-80' : 'text-gray-10'
							}`}
						>
							<SypacText variant="overline-regular-large">
								<p className="xl-2xl:text-2xl text-base">
									0{index + firstStepIndex + 1}
								</p>
							</SypacText>
							<SypacText variant="overline-regular-large">
								<p
									className={`${
										isActive
											? 'xl-2xl:text-2xl text-base'
											: 'xl-2xl:text-xl text-base'
									}`}
								>
									{step}
								</p>
							</SypacText>
						</div>
						{index !== displayedSteps.length - 1 && (
							<div
								className={`w-[84px] h-[3px] rounded-full ${
									isActive
										? 'bg-gradient-to-r from-gray-60 to-white'
										: 'bg-gray-10'
								}`}
							/>
						)}
					</React.Fragment>
				);
			})}
		</div>
	);
};

export default ClientStepper;
