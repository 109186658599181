import React, { useState } from 'react';
import { TransporterOrderProps } from '../../../transporterOrder.interface';
import OrderDetailsStepper from '../../../../../../components/OrderDetailsStepper/OrderDetailsStepper';
import TruckSchedule from '../../AdditionalDetails/TruckSchedule';
import PickUpDeliveryDetails from '../../../../../Admin/OrderDetails/components/AdditionalDetails/PickUp&DeliveryDetails';
import BaseOrderDetails from '../../../../../../components/BaseOrderDetails/BaseOrderDetails';

const PendingAndPaid: React.FC<TransporterOrderProps> = (props) => {
	const { selectedOrder, currentStep } = props;
	const [stepperMargin, setStepperMargin] = useState<number>(0);

	const handleWidthChange = (value: number) => {
		setStepperMargin(value);
	};

	return (
		<>
			<div style={{ marginBottom: `${stepperMargin}px` }}>
				<OrderDetailsStepper
					current={currentStep!}
					simpleOrder={selectedOrder?.trucks?.length === 1}
					isFinalStep={currentStep === 12}
					order={selectedOrder}
					onWidthChange={handleWidthChange}
				/>
			</div>

			<div className="px-10 pt-7 pb-3.5">
				<BaseOrderDetails
					selectedOrder={selectedOrder}
					isOpen={true}
					simpleOrder={selectedOrder?.trucks?.length === 1}
					deliveryIncluded={selectedOrder?.deliveryIncluded!}
					target={selectedOrder?.target!}
				/>
			</div>

			<div className="px-10 py-3.5">
				<PickUpDeliveryDetails
					selectedOrder={selectedOrder}
					target={selectedOrder?.target}
					isPickUp={false}
					simpleOrder={selectedOrder?.trucks?.length === 1}
				/>
			</div>

			<div className="px-10 py-3.5">
				<PickUpDeliveryDetails
					selectedOrder={selectedOrder}
					target={selectedOrder?.target}
					isPickUp={true}
					simpleOrder={selectedOrder?.trucks?.length === 1}
				/>
			</div>

			<div className="px-10 pb-7 pt-3.5">
				<TruckSchedule selectedOrder={selectedOrder} />
			</div>
		</>
	);
};

export default PendingAndPaid;
