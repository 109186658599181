import React from 'react';
import {
	SypacBadge,
	SypacIcon,
	SypacText,
} from '@sypac/component-library-react';
import { TableStatusProps } from './TableStatus.interface';
import { PaymentStatusColor } from './TableStatus.constants';

const PaymentStatusPayment: React.FC<TableStatusProps> = ({
	state,
	days,
	isPartial,
	classes,
}) => {
	return (
		<div className={`flex items-center justify-start ${classes}`}>
			<SypacBadge
				color={PaymentStatusColor[state]}
				size="large"
				className="w-full"
			>
				{state === 'pending' ? (
					<>
						<div className="flex gap-1 items-center w-fit justify-center cursor-auto">
							<SypacIcon
								iconName="Clock White"
								size="custom"
								width="12px"
								height="12px"
							/>
							<SypacText variant="overline-regular-large">
								<p className="text-gray-80">Pending</p>
							</SypacText>
						</div>
						{isPartial ? (
							<SypacBadge
								color={PaymentStatusColor[state]}
								size="small"
								className="ml-3 w-fit cursor-auto"
							>
								<span className="cursor-auto">
									<SypacText variant="overline-regular-large">
										<p className="text-gray-80 min-w-max">Partial payment</p>
									</SypacText>
								</span>
							</SypacBadge>
						) : null}
						{days ? (
							<SypacBadge
								color={PaymentStatusColor[state]}
								size="small"
								className="ml-3 cursor-auto"
							>
								<span className="cursor-auto">
									<SypacText variant="overline-regular-large">
										<p className="text-gray-80">{days}d.</p>
									</SypacText>
								</span>
							</SypacBadge>
						) : null}
					</>
				) : state === 'overdue' ? (
					<>
						<div className="flex gap-1 items-center text-red w-fit justify-center cursor-auto">
							<SypacIcon
								iconName="Danger Filled"
								size="custom"
								width="12px"
								height="11px"
							/>
							<SypacText variant="overline-regular-large">
								<p className="text-white">Overdue</p>
							</SypacText>
						</div>
						{days ? (
							<SypacBadge
								color={PaymentStatusColor[state]}
								size="small"
								className="ml-3 cursor-auto"
							>
								<span className="cursor-auto">
									<SypacText variant="overline-regular-large">
										<p className="text-white">{days}d.</p>
									</SypacText>
								</span>
							</SypacBadge>
						) : null}
					</>
				) : state === 'paid' ? (
					<>
						<div className="flex gap-1 text-white w-fit justify-center cursor-auto">
							<SypacIcon
								iconName="Unread"
								size="custom"
								width="19px"
								height="16px"
							/>
							<SypacText variant="overline-regular-large">
								<p className="text-white">Paid</p>
							</SypacText>
						</div>
						{isPartial ? (
							<SypacBadge
								color={PaymentStatusColor[state]}
								size="small"
								className="ml-3 w-fit cursor-auto"
							>
								<span className="cursor-auto">
									<SypacText variant="overline-regular-large">
										<p className="text-white min-w-max">In instalments</p>
									</SypacText>
								</span>
							</SypacBadge>
						) : null}
					</>
				) : null}
			</SypacBadge>
		</div>
	);
};

export default PaymentStatusPayment;
