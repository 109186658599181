import { IconInterface } from '../interfaces/icon.interface';

export const StatusClock = (props: IconInterface) => {
	const { color } = props;

	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8 7.99978H7.5C7.5 8.13239 7.55268 8.25957 7.64645 8.35334L8 7.99978ZM8.5 4.88867C8.5 4.61253 8.27614 4.38867 8 4.38867C7.72386 4.38867 7.5 4.61253 7.5 4.88867H8.5ZM9.59089 10.2978C9.78615 10.493 10.1027 10.493 10.298 10.2978C10.4933 10.1025 10.4933 9.78594 10.298 9.59067L9.59089 10.2978ZM8.5 7.99978V4.88867H7.5V7.99978H8.5ZM7.64645 8.35334L9.59089 10.2978L10.298 9.59067L8.35355 7.64623L7.64645 8.35334Z"
				fill={color || '#454545'}
			/>
			<path
				d="M12.9746 3.02544L13.3281 2.67189L13.3281 2.67189L12.9746 3.02544ZM13.9595 4.0104L14.4595 4.01292C14.4581 4.2871 14.2362 4.50902 13.962 4.5104L13.9595 4.0104ZM11.9822 4.52035C11.706 4.52174 11.481 4.29901 11.4797 4.02287C11.4783 3.74673 11.701 3.52175 11.9771 3.52036L11.9822 4.52035ZM13.4695 2.02802C13.4709 1.75188 13.6958 1.52915 13.972 1.53054C14.2481 1.53193 14.4709 1.75691 14.4695 2.03304L13.4695 2.02802ZM14.4396 7.04362C14.4021 6.77004 14.5935 6.51785 14.8671 6.48035C15.1407 6.44285 15.3929 6.63424 15.4304 6.90782L14.4396 7.04362ZM2.72163 2.72164C5.66091 -0.217641 10.4096 -0.246613 13.3281 2.67189L12.621 3.379C10.0997 0.857701 5.98421 0.873274 3.42874 3.42874L2.72163 2.72164ZM13.2784 13.2784C10.3391 16.2176 5.59039 16.2466 2.67189 13.3281L3.379 12.621C5.90029 15.1423 10.0158 15.1267 12.5713 12.5713L13.2784 13.2784ZM2.67189 13.3281C-0.246613 10.4096 -0.217641 5.66091 2.72163 2.72164L3.42874 3.42874C0.873273 5.98421 0.857702 10.0997 3.379 12.621L2.67189 13.3281ZM13.3281 2.67189L14.3131 3.65685L13.606 4.36396L12.621 3.379L13.3281 2.67189ZM13.962 4.5104L11.9822 4.52035L11.9771 3.52036L13.957 3.51041L13.962 4.5104ZM13.4595 4.00789L13.4695 2.02802L14.4695 2.03304L14.4595 4.01292L13.4595 4.00789ZM15.4304 6.90782C15.7392 9.16078 15.0201 11.5367 13.2784 13.2784L12.5713 12.5713C14.0841 11.0584 14.7075 8.99766 14.4396 7.04362L15.4304 6.90782Z"
				fill={color || '#454545'}
			/>
		</svg>
	);
};
