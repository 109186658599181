import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import Payments from '../../components/Payments/Payments';
import { OrderContext } from '../../context/OrderContext/order.context';
import { useNavigate } from 'react-router-dom';
import ClientStepper from '../../components/ClientStepper/ClientStepper';
import ClientOrderSummary from '../../components/ClientOrderSummary/ClientOrderSummary';
import { GeoService } from '../../services/geo.services';
import { OrdersService, Prices } from '../../services/orders.services';
import { QuantityPickerContext } from '../../context/QuantityPickerContext/quantity-picker.context';
import ClientStoreBreadcrumbs from '../../components/ClientStoreBreadcrumbs/ClientStoreBreadcrumbs';

const ProductsPayment = () => {
	const navigate = useNavigate();
	const { order } = useContext(OrderContext);
	const [quantity, setQuantity] = useState<number>(0);
	const [prices, setPrices] = useState<Prices>({
		productPriceForClient: 0,
		productPriceForClientWithVat: 0,
		transportPriceForClient: 0,
		transportPriceForClientWithVat: 0,
	});

	const currentProduct = useMemo(() => order?.product, [order?.product]);
	const currentCategory = useMemo(() => order?.category, [order?.category]);
	const currentGroup = useMemo(() => order?.group, [order?.group]);

	const { totalQuantityCounter, inputQuantity } = useContext(
		QuantityPickerContext,
	);

	useEffect(() => {
		if (totalQuantityCounter?.length) {
			const result = totalQuantityCounter?.reduce(
				(total, item) => total + item.payload * item.quantity,
				0,
			);
			setQuantity(result);
		} else {
			setQuantity(0);
		}
	}, [totalQuantityCounter]);

	const getPrices = useCallback(async () => {
		try {
			let quantity = 0;
			let trucksNr = 1;
			if (totalQuantityCounter?.length) {
				quantity = totalQuantityCounter?.reduce(
					(total, item) => total + item.payload * item.quantity,
					0,
				);
				trucksNr = totalQuantityCounter?.reduce(
					(total, item) => total + item.quantity,
					0,
				);
			}
			if (inputQuantity) {
				quantity = inputQuantity;
			}
			let latDelivery = 0;
			let longDelivery = 0;
			if (order?.location?.locationId) {
				const resp = await GeoService.getLocationDetails({
					locationid: order?.location?.locationId,
				});
				latDelivery = resp?.data?.Latitude;
				longDelivery = resp?.data?.Longitude;
			}
			const { data } = await OrdersService.calculatePrice({
				quantity,
				trucksNr,
				latPickup: order?.product?.location?.coordinates?.length
					? order?.product?.location?.coordinates[1]
					: 47.04014,
				longPickup: order?.product?.location?.coordinates.length
					? order?.product?.location?.coordinates[0]
					: 28.80058,
				latDelivery,
				longDelivery,
				productId: currentProduct?.id!,
			});
			setPrices(data);
		} catch (e) {}
	}, [
		currentProduct?.id,
		inputQuantity,
		order?.location?.locationId,
		order?.product?.location?.coordinates,
		totalQuantityCounter,
	]);

	useEffect(() => {
		getPrices().then(() => {});
	}, [getPrices]);

	const onSuccess = (id: number) => {
		navigate(`/store/${id}/thank-you`);
	};

	return (
		<div className="w-full flex flex-col gap-4 h-[calc(100vh-60px)] gap-x-2.5 overflow-x-hidden xl:overflow-x-auto mt-[68px] sm:mt-0">
			{/*<SearchBar
				placeholder={t('productsPayment.searchPlaceholder', 'Search Products')}
			/>*/}

			<div className="w-[calc(100vw-26px)] sm:w-[1133px] xl:self-center flex flex-col gap-4 pt-3.5 sm:pt-0 mb-1 xl-2xl:mb-4 px-3.5 sm:px-12 xl:ml-0">
				<ClientStoreBreadcrumbs
					currentCategory={currentCategory}
					currentGroup={currentGroup}
					currentProduct={currentProduct}
					categoryId={order?.category?.id}
				/>
			</div>
			<div className="border border-solid border-gray-10 border-t-0 border-l-0 border-r-0" />

			<div className="w-[calc(100vw-26px)] sm:w-[1133px] xl:self-center xl-2xl:py-3 px-3.5 sm:px-12 xl:ml-0">
				<ClientStepper currentStep={3} />
			</div>
			<div className="border border-solid border-gray-10 border-t-0 border-l-0 border-r-0" />

			<div className="sm:w-[calc(100vw-154px)] xl:w-[1133px] xl:self-center flex flex-col sm:flex-row gap-12 mb-6 sm:mb-0 mt-2 xl-2xl:mt-3.5 sm:ml-12 sm:mr-7.5 xl:mx-0">
				<Payments
					onSuccess={onSuccess}
					currentProduct={currentProduct!}
					prices={prices}
					quantity={quantity}
				/>

				<div className="hidden sm:inline-table sm:w-[412px] xl:w-[460px]">
					<ClientOrderSummary
						currentProduct={currentProduct!}
						prices={prices}
						quantity={quantity}
						step={3}
					/>
				</div>
			</div>
		</div>
	);
};

export default ProductsPayment;
