import React, { useEffect, useRef, useState } from 'react';
import {
	SypacVerticalTimeline,
	SypacVerticalTimelineStep,
} from '@sypac/component-library-react';
import { useTranslate } from '@tolgee/react';
import IssuesDefault from '../../../../../../components/Notification/IssuesDefault';
import { DriverIssuesEnum } from '../../../../../../services/truck.services';
import { ContentDto } from './NotificationIssueSolved';

interface NotificationDriverReportedIssueProps {
	notification: boolean;
	date: string;
	content: ContentDto;
}

const NotificationDriverReportedIssue = ({
	notification,
	date,
	content,
}: NotificationDriverReportedIssueProps) => {
	const { t } = useTranslate();
	const [boxHeight, setBoxHeight] = useState<number>(0);
	const boxRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const element = boxRef.current;
		const resizeObserver = new ResizeObserver((entries) => {
			for (let entry of entries) {
				setBoxHeight(entry.contentRect.height);
			}
		});

		if (element) {
			resizeObserver.observe(element);
		}

		return () => {
			if (element) {
				resizeObserver.unobserve(element);
			}
		};
	}, []);

	return (
		<div className="flex flex-row gap-6">
			<SypacVerticalTimeline>
				<SypacVerticalTimelineStep
					isActive={notification}
					minWidth={Math.ceil(boxHeight / 10) * 10}
				>
					{date}
				</SypacVerticalTimelineStep>
			</SypacVerticalTimeline>

			<div ref={boxRef} className="h-fit w-full">
				<IssuesDefault
					notification={notification}
					title={t(
						'NotificationDriverIssue.driverReportedAnIssue',
						'Driver reported an issue',
					)}
					content={content}
					newIssue={content.status === DriverIssuesEnum.CREATED}
				/>
			</div>
		</div>
	);
};

export default NotificationDriverReportedIssue;
