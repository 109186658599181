import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	SypacButton,
	SypacText,
	SypacBreadcrumbs,
	SypacLink,
} from '@sypac/component-library-react';
import Close from '../../../../../assets/Close';
import { Category } from '../../../../../interfaces/category.interface';
import { T } from '@tolgee/react';
import './product-group.css';

export interface ProductGroupProps {
	groups?: Category[];
	onSelect: (category: Category) => void;
	title: string;
	description?: string;
	breadcrumbs?: {
		name: string;
		link: string;
	}[];
	showClose?: boolean;
}

const ProductGroup = ({
	groups,
	onSelect,
	title,
	breadcrumbs,
	description,
	showClose,
}: ProductGroupProps) => {
	const navigate = useNavigate();
	const [showBlur, setShowBlur] = useState<string | undefined>(undefined);

	const handleImageBlur = (key?: string) => {
		if (key !== showBlur) {
			setShowBlur(key);
		}
	};

	const renderGroup = (item: Category) => {
		return (
			<div
				key={item.id}
				className="flex flex-col items-center"
				onClick={() => onSelect(item)}
			>
				<div
					style={{ backgroundImage: `url(${item.imageUrl})` }}
					className="relative w-[203px] h-[203px] mb-5 bg-no-repeat bg-cover bg-center rounded-10 cursor-pointer"
					onMouseEnter={() => handleImageBlur(item.id)}
					onMouseLeave={() => handleImageBlur(undefined)}
				>
					{showBlur === item.id ? (
						<div className="absolute w-full h-full bg-black-20 rounded-10" />
					) : null}
				</div>

				<div className="flex flex-col gap-2 self-start">
					<SypacText variant="body-normal-large">
						<p className="max-w-[203px] text-xl text-gray-80 truncate">
							{item.name}
						</p>
					</SypacText>
					<SypacText variant="body-normal-large">
						<p className="text-sm text-gray-40">
							{item.countSubcategories !== 0 ? (
								<>
									{item.countSubcategories}{' '}
									<T keyName="clientSelectGroups.categoriesInThisGroup">
										categories in this group
									</T>
								</>
							) : (
								<>
									{item.countPrododucts}{' '}
									<T keyName="clientSelectCategory.productsInThisCategory">
										products in this category
									</T>
								</>
							)}
						</p>
					</SypacText>
				</div>
			</div>
		);
	};

	return (
		<div className="w-full h-full flex items-center justify-center">
			<div className="max-w-[690px] w-full flex flex-col">
				{showClose && (
					<div className="flex justify-between items-center mb-12">
						<SypacText variant="body-normal-medium">
							<p className="text-gray-60">
								<T keyName="productGroup.addNewProduct">Add new product</T>
							</p>
						</SypacText>
						<SypacButton variant="secondary" size="medium">
							<button
								onClick={() => navigate('/products/')}
								className="p-2 border border-solid border-gray-10 transition hover:border-primary-violet"
							>
								<Close />
							</button>
						</SypacButton>
					</div>
				)}

				<div className="flex flex-col gap-2 mb-12">
					<SypacText variant="heading-5">
						<p className="text-xl text-gray-80">{title}</p>
					</SypacText>

					{!!description && (
						<SypacText variant="body-regular-medium">
							<p className="text-sm text-gray-60">{description}</p>
						</SypacText>
					)}

					{!!breadcrumbs?.length && (
						<SypacBreadcrumbs>
							{breadcrumbs.map((item, i) => (
								<SypacLink key={i} variant="breadcrumbs">
									<a className="text-sm text-gray-60" href={item.link}>
										{item.name}
									</a>
								</SypacLink>
							))}
						</SypacBreadcrumbs>
					)}
				</div>

				<div className="border border-solid border-gray-10 rounded-xl p-5 shadow-dropdown-avatar">
					<div className="max-h-[825px] grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 overflow-y-auto scrollbar-custom pr-[5px]">
						{groups?.map((item) => renderGroup(item))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProductGroup;
