import { IconInterface } from '../interfaces/icon.interface';

const ExclamationCircle = (props: IconInterface) => {
	const { color = '#494C83' } = props;

	return (
		<svg
			width="18"
			height="19"
			viewBox="0 0 18 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle
				cx="8.92969"
				cy="9.28516"
				r="8"
				stroke={color}
				strokeWidth="1.5"
			/>
			<path
				d="M8.92969 5.28516V10.0852"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<circle cx="8.92988" cy="12.4851" r="0.8" fill={color} />
		</svg>
	);
};

export default ExclamationCircle;
