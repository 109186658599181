import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import Flag from 'react-world-flags';
import { NumericFormat } from 'react-number-format';
import dayjs from 'dayjs';
import { SypacButton, SypacText } from '@sypac/component-library-react';
import { ModalConfirmPaymentStatusProps } from './ModalConfirmPayment.interface';
import Table from '../Table/Table';
import COLUMNS from './columns.constants';
import OverdueBadge from '../../assets/OverdueBadge';
import PaidBadge from '../../assets/PaidBadge';
import PaymentStatusPayment from '../TableStatus/PaymentStatusPayment';
import { LineHorizontal } from '../../assets/LineHorizontal';
import { TableStatusEnum } from '../TableStatus/TableStatus.enum';
import { T } from '@tolgee/react';
import { useGetCompanyById } from '../../hooks/use-get-company-by-id';
import Close from '../../assets/Close';

const ModalConfirmPaymentStatus = ({
	data,
	isOpen,
	onClose,
	onSubmit,
}: ModalConfirmPaymentStatusProps) => {
	const [company] = useGetCompanyById({
		id: data.companyId,
	});
	const [isOverdue, setOverdue] = useState<boolean>(false);
	const [firstPartial, setFirstPartial] = useState<boolean>(true);
	const [secondPartial, setSecondPartial] = useState<boolean>(true);
	const [firstStatus, setFirstStatus] = useState<string>();
	const [secondStatus, setSecondStatus] = useState<string>();
	const [days, setDays] = useState<number>(0);
	const [futureBalance, setBalance] = useState<number>(0);

	const checkSubmit = () => {
		onSubmit();
	};

	useEffect(() => {
		if (data?.paidData?.amount && data?.invoice) {
			const invoice = data.invoice;
			const overdue =
				dayjs(invoice.dueDate).toISOString() < dayjs().toISOString();
			setOverdue(overdue);
			const firstStatus = overdue
				? TableStatusEnum.OVERDUE
				: TableStatusEnum.PENDING;
			setFirstStatus(firstStatus);
			setFirstPartial(!!invoice.payments?.length);
			const days = overdue
				? dayjs().diff(invoice?.dueDate, 'day')
				: dayjs(invoice?.dueDate).diff(dayjs(), 'day');
			setDays(days);
			const balance = invoice.balanceDue - parseFloat(data.paidData.amount);
			setSecondPartial(balance !== 0);
			setSecondStatus(
				balance > 0 ? TableStatusEnum.PENDING : TableStatusEnum.PAID,
			);
			setBalance(balance);
		}
	}, [data?.paidData?.amount, data?.invoice]);

	return (
		<Modal
			isOpen={!!isOpen}
			onRequestClose={onClose}
			className="modal-inside outline-none max-w-[720px]"
			overlayClassName="modal-overlay"
			shouldCloseOnOverlayClick={false}
			ariaHideApp={false}
		>
			<div className="flex flex-col">
				<div className="flex flex-row items-center justify-between p-4">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">Confirm payment date & amount</p>
					</SypacText>
					<SypacButton variant="subTitle">
						<button
							type="button"
							className="flex justify-center items-center h-[32px] w-[32px] p-0 bg-white border border-solid border-gray-10 transition hover:border-gray-60 rounded-lg"
							onClick={onClose}
						>
							<Close width="10" height="10" />
						</button>
					</SypacButton>
				</div>

				<div className="border-0 border-t border-solid border-gray-10" />

				<div className="flex flex-col items-center justify-center p-4 gap-6">
					<div className="w-full flex flex-col gap-5">
						<div className="flex gap-5">
							<div className="flex items-center justify-center w-[62px] h-[62px] rounded-10 bg-gray-10">
								<SypacText variant="heading-5">
									<p className="text-gray-90">
										{data?.companyName
											.split(' ')
											.map((name) => name[0])
											.join('')
											.toUpperCase()
											.slice(0, 2)}
									</p>
								</SypacText>
							</div>
							<div className="flex flex-col gap-2.5">
								<SypacText variant="heading-5">
									<p className="text-gray-90">{data?.companyName}</p>
								</SypacText>
								<div className="flex gap-2.5">
									<span className="rounded-[3px] overflow-hidden">
										<Flag
											code={company?.countryCode.toLocaleUpperCase()}
											width={24}
											height={16}
										/>
									</span>
									<SypacText variant="body-normal-medium">
										<p className="text-gray-80">{company?.registryAddress}</p>
									</SypacText>
								</div>
							</div>
						</div>
						<div className="bg-alabaster rounded-10">
							<div className="flex flex-col justify-center dashed-box p-5 gap-5">
								<div>
									<SypacText variant="body-regular-small">
										<p className="text-gray-80">
											FAKTURA NR/Invoice no {data.invoiceName}
										</p>
									</SypacText>
								</div>
								{data.tableData.length ? (
									<div>
										<Table columns={COLUMNS} data={data.tableData} />
									</div>
								) : null}
								<div className="text-end">
									<SypacText variant="body-regular-small">
										<p className="text-gray-80">
											Amount to be paid:{' '}
											<NumericFormat
												type="text"
												className="font-bold"
												displayType="text"
												thousandSeparator="."
												decimalSeparator=","
												value={parseFloat(data.amount?.toFixed(2) || '0')}
												suffix={' zł'}
											/>
										</p>
									</SypacText>
								</div>
							</div>
						</div>
					</div>

					<div className="w-full flex justify-start items-start box-border">
						<span className="flex scale-[1.4] ml-2">
							{isOverdue ? <OverdueBadge /> : <PaidBadge />}
						</span>

						<div className="flex flex-col ml-[26px]">
							<div className="grid grid-cols-12 gap-x-6">
								<div className="col-span-5">
									<SypacText variant="body-normal-medium">
										<p className="text-gray-90">Amount paid:</p>
									</SypacText>
								</div>
								<div className="col-span-7">
									<SypacText variant="body-regular-medium">
										<p className="text-gray-90">
											<NumericFormat
												type="text"
												displayType="text"
												thousandSeparator="."
												decimalSeparator=","
												value={parseFloat(data.paidData?.amount) || 0}
												suffix={' zł'}
											/>{' '}
											{secondPartial ? '(partial payment)' : '(full amount)'};
										</p>
									</SypacText>
								</div>
								{secondPartial ? (
									<>
										<div className="col-span-5">
											<SypacText variant="body-normal-medium">
												<p className="text-gray-90">Left to pay:</p>
											</SypacText>
										</div>
										<div className="col-span-7">
											<SypacText variant="body-regular-medium">
												<p className="text-gray-90">
													<NumericFormat
														type="text"
														displayType="text"
														thousandSeparator="."
														decimalSeparator=","
														value={parseFloat(futureBalance?.toFixed(2) || '0')}
														suffix={' zł'}
													/>
												</p>
											</SypacText>
										</div>
									</>
								) : null}
								<div className="col-span-5">
									<SypacText variant="body-normal-medium">
										<p className="text-gray-90">Payment execution:</p>
									</SypacText>
								</div>
								<div className="col-span-7">
									<SypacText variant="body-regular-medium">
										<p className="text-gray-90">
											{isOverdue ? `Overdue, ${days} days.` : 'On time'}
										</p>
									</SypacText>
								</div>
								{firstStatus && secondStatus ? (
									<>
										<div className="col-span-12 mt-5">
											<SypacText variant="body-regular-small">
												<p className="text-gray-40">
													{secondPartial
														? `The status of the payment will be changed from "${
																firstStatus.charAt(0).toUpperCase() +
																firstStatus.slice(1)
														  }" to "${
																secondStatus.charAt(0).toUpperCase() +
																secondStatus.slice(1)
														  } / partial payment ". Please check the summary table for detailed information about
											the payment.`
														: `The status of the payment will be changed from "${
																firstStatus.charAt(0).toUpperCase() +
																firstStatus.slice(1)
														  }"
										to "${
											secondStatus.charAt(0).toUpperCase() +
											secondStatus.slice(1)
										}". Please check the summary table for detailed
										information about the payment.`}
												</p>
											</SypacText>
										</div>
										<div className="col-span-12 mt-5">
											<div className="flex flex-row">
												<PaymentStatusPayment
													isPartial={firstPartial}
													state={firstStatus}
													days={days}
												/>
												<div className="flex items-center justify-start mx-6">
													<LineHorizontal />
												</div>
												<PaymentStatusPayment
													days={days}
													state={secondStatus}
													isPartial={secondPartial}
												/>
											</div>
										</div>
									</>
								) : null}
								<div className="col-span-12 mt-5">
									<div className="flex flex-row w-full gap-x-5">
										<div className="flex flex-row justify-start items-center rounded-10 border border-solid border-mountain-meadow p-4 w-full">
											<SypacText variant="overline-regular-large">
												<p className="text-mountain-meadow">Paid amount:</p>
											</SypacText>
											<SypacText variant="body-regular-large" className="ml-3">
												<p className="text-gray-80">
													{parseFloat(data?.paidData?.amount).toFixed(2)}
												</p>
											</SypacText>
											<div className="bg-gray-10 rounded px-3 h-6 flex flex-row justify-center items-center ml-3">
												<SypacText variant="body-regular-small">
													<p className="text-gray-80">zł</p>
												</SypacText>
											</div>
										</div>
										{futureBalance > 0 ? (
											<div className="flex flex-row justify-start items-center rounded-10 border border-solid border-gray-20 p-4 w-full">
												<SypacText variant="overline-regular-large">
													<p className="text-gray-80">Left to pay:</p>
												</SypacText>
												<SypacText
													variant="body-regular-large"
													className="ml-3"
												>
													<p className="text-gray-80">
														{futureBalance.toFixed(2)}
													</p>
												</SypacText>
												<div className="bg-gray-10 rounded px-3 h-6 flex flex-row justify-center items-center ml-3">
													<SypacText variant="body-regular-small">
														<p className="text-gray-80">zł</p>
													</SypacText>
												</div>
											</div>
										) : null}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="w-full flex justify-center p-3 gap-3 border-0 border-t border-solid border-gray-10 box-border">
					<SypacButton
						variant="secondary"
						size="small"
						onClick={onClose}
						className="w-full"
					>
						<button
							type="reset"
							className="w-full h-[44px] flex items-center justify-center rounded-lg transition"
						>
							<SypacText variant="body-regular-medium">
								<p>
									<T keyName="modalConfirmationCode.cancel">Cancel</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>

					<SypacButton variant="secondary" size="small" className="w-full">
						<button
							type="button"
							onClick={checkSubmit}
							className="w-full h-[44px] flex items-center justify-center border-primary-violet rounded-lg hover:border-kimberly"
						>
							<SypacText variant="body-regular-medium">
								<p>
									<T keyName="modalConfirmationCode.confirm">Confirm</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
				</div>
			</div>
		</Modal>
	);
};

export default ModalConfirmPaymentStatus;
