import React, { useMemo, useState } from 'react';
import OrderDetailsStepper from '../../../../../../components/OrderDetailsStepper/OrderDetailsStepper';
import BaseOrderDetails from '../../../../../../components/BaseOrderDetails/BaseOrderDetails';
import PickUpSchedule from '../../AdditionalDetails/PickUpSchedule';
import { ProducerOrderProps } from '../../../producerOrder.interface';
import PickUpDeliveryDetails from '../../../../../Admin/OrderDetails/components/AdditionalDetails/PickUp&DeliveryDetails';

const PendingPayment: React.FC<ProducerOrderProps> = (props) => {
	const { selectedOrder } = props;
	const [stepperMargin, setStepperMargin] = useState<number>(0);

	const deliveryIncluded = useMemo(() => {
		return selectedOrder?.deliveryIncluded;
	}, [selectedOrder?.deliveryIncluded]);

	const handleWidthChange = (value: number) => {
		setStepperMargin(value);
	};

	return (
		<>
			<div style={{ marginBottom: `${stepperMargin}px` }}>
				<OrderDetailsStepper
					current={deliveryIncluded ? 4 : 5}
					simpleOrder={selectedOrder?.trucks?.length === 1}
					order={selectedOrder}
					onWidthChange={handleWidthChange}
				/>
			</div>

			<div className="px-10 pt-7 pb-3.5">
				{deliveryIncluded ? (
					<PickUpSchedule
						selectedOrder={selectedOrder}
						step={deliveryIncluded ? 4 : 5}
					/>
				) : (
					<BaseOrderDetails
						selectedOrder={selectedOrder}
						isOpen={true}
						simpleOrder={selectedOrder?.trucks?.length === 1}
						deliveryIncluded={deliveryIncluded!}
						target={selectedOrder?.target!}
					/>
				)}
			</div>

			<div className={`px-10 ${deliveryIncluded ? 'pb-7 pt-3.5' : 'py-3.5'}`}>
				{deliveryIncluded ? (
					<BaseOrderDetails
						selectedOrder={selectedOrder}
						isOpen={true}
						simpleOrder={selectedOrder?.trucks?.length === 1}
						deliveryIncluded={deliveryIncluded!}
						target={selectedOrder?.target!}
					/>
				) : (
					<PickUpDeliveryDetails
						selectedOrder={selectedOrder}
						target={selectedOrder?.target}
						isPickUp={true}
						simpleOrder={selectedOrder?.trucks?.length === 1}
					/>
				)}
			</div>

			{!deliveryIncluded && (
				<div className="px-10 pb-7 pt-3.5">
					<PickUpSchedule
						selectedOrder={selectedOrder}
						step={deliveryIncluded ? 4 : 5}
					/>
				</div>
			)}
		</>
	);
};

export default PendingPayment;
