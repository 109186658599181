import { Counter } from '../../components/QuantityPicker/QuantityPicker.interface';

export const COUNTERS: Counter[] = [
	{
		id: '1',
		name: 'Aluminium 3 ton',
		payload: 3,
		car: 'aluminium',
	},
	{
		id: '2',
		name: 'Aluminium 6 ton',
		payload: 6,
		car: 'aluminium',
	},
	{
		id: '3',
		name: 'Aluminium 9 ton',
		payload: 9,
		car: 'aluminium',
	},
	{
		id: '4',
		name: 'Aluminium 13 ton',
		payload: 13,
		car: 'aluminium',
	},
	{
		id: '5',
		name: 'Hooklifter 21 tons',
		payload: 21,
		car: 'hooklifter',
	},
	{
		id: '6',
		name: 'Steel 25 tons',
		payload: 25,
		car: 'steel',
	},
	{
		id: '7',
		name: 'Aluminium 27 ton',
		payload: 27,
		car: 'aluminium',
	},
];
