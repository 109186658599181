import React, { useState } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { SypacIcon, SypacText } from '@sypac/component-library-react';
import { SortingItemInterface, SortingProps } from './Sorting.interface';
import { SortingItem } from './SortingItem';
import { T, useTranslate } from '@tolgee/react';

export const Sorting = (props: SortingProps) => {
	const { t } = useTranslate();
	const { options, action, current } = props;
	const [selected, setSelected] = useState<SortingItemInterface>(current!);
	const [isOpen, setIsOpen] = useState<boolean>(false);

	const closeDropdown = () => {
		setIsOpen(false);
	};

	const ref = useDetectClickOutside({ onTriggered: closeDropdown });

	return (
		<div ref={ref} className="flex gap-x-3 relative justify-start items-start">
			<div
				className="flex gap-2 items-center cursor-pointer"
				onClick={() => setIsOpen(!isOpen)}
			>
				<SypacText variant="body-regular-medium">
					<p className="text-gray-80">
						<T keyName="sorting.sortedBy">Sorted by:</T>
					</p>
				</SypacText>
				<SypacText variant="body-regular-medium">
					<p className="text-gray-80">
						{selected?.title ? (
							<T keyName={`sorting.${selected?.title}`}>{selected?.title}</T>
						) : (
							''
						)}
					</p>
				</SypacText>
				<SypacIcon
					iconName="Alt Arrow Down"
					size="custom"
					width="20px"
					height="10px"
				/>
			</div>
			{isOpen && (
				<div className="flex flex-col absolute top-8 right-0 p-3 rounded-lg shadow-dropdown bg-white min-w-[145px] w-max z-30">
					{options.map(({ title, value }) => {
						return (
							<SortingItem
								key={Math.random()}
								title={t(`sorting.${title.toLowerCase()}`, title)}
								value={value}
								action={(item) => {
									setSelected(item);
									action(item);
								}}
							/>
						);
					})}
				</div>
			)}
		</div>
	);
};
