import React, { useCallback, useState } from 'react';
import { MutatingDots } from 'react-loader-spinner';
import { NumericFormat } from 'react-number-format';
import {
	SypacBox,
	SypacButton,
	SypacText,
} from '@sypac/component-library-react';
import { PaymentsTable } from '../../../components/PaymentsTable/PaymentsTable';
import BillingTabs from '../../../components/BillingTabs/BillingTabs';
import ModalFilterDate from '../../../components/ModalFilterDate/ModalFilterDate';
import ModalDeposit from '../../../components/ModalDeposit/ModalDeposit';
import { useGetStats } from '../../../hooks/use-get-stats';
import { useGetInvoices } from '../../../hooks/use-get-invoices';
import { BillingTab } from '../../../components/BillingTabs/BillingTabs.interface';
import { SortingItemInterface } from '../../../components/Sorting/Sorting.interface';
import { BillingService } from '../../../services/billing.service';
import { toastVariant } from '../../../components/CompaniesTable/toastVariant/toastVariant';
import Pagination from '../../../components/Pagination/Pagination';
import { LIMIT } from '../../../constants';
import OrderDetails from '../OrderDetails';
import { OrderInterface } from '../../../components/OrdersTable/interfaces/Order.interface';
import { OrderTarget } from '../../../components/OrdersTable/interfaces/OrderStatus.interface';
import { OrdersService } from '../../../services/orders.services';
import { InvoiceInterface } from '../../../components/PaymentsTable/interfaces/Payment.interface';
import { PaymentTypeAction } from '../../../components/PaymentsTable/interfaces/PaymentStatus.interface';
import { T, useTranslate } from '@tolgee/react';
import { useDetectClickOutside } from 'react-detect-click-outside';

const ClientBilling: React.FC = () => {
	const { t } = useTranslate();

	const [searchQuery] = useState<string>();
	const [currentTab, setCurrentTab] = useState<string>('all');
	const [sorting] = useState<SortingItemInterface>();
	const [dateFrom, setDateFrom] = useState<string>();
	const [dateTo, setDateTo] = useState<string>();
	const [isOpenDateFilter, setOpenDateFilter] = useState<boolean>(false);
	const [isDepositOpen, setDepositOpen] = useState<boolean>(false);
	const [page, setPage] = useState<number>(0);
	const [refresh, setRefresh] = useState<number>(0);
	const [isOpen, setOpenDetails] = useState<boolean>(false);
	const [selectedOrder, setOrder] = useState<OrderInterface | undefined>(
		undefined,
	);

	const selectTab = (tab: string) => {
		setPage(0);
		setCurrentTab(tab);
	};

	const [stats] = useGetStats({
		target: 'customer',
		refresh,
	});
	const hasOverduePayments = useCallback(
		(overdue: string | undefined) => {
			if (!overdue) {
				return true;
			}

			return parseInt(overdue, 10) > 0;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[stats?.count?.overdue],
	);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [invoicesList, _, invoicesAreLoading] = useGetInvoices({
		orderId: searchQuery,
		status: currentTab,
		fromDate: dateFrom || undefined,
		toDate: dateTo || undefined,
		sorting: sorting?.value,
		limit: LIMIT,
		offset: page * LIMIT,
		refresh,
	});

	const lines =
		invoicesList?.items?.map((invoice) => invoice.invoiceLines[0]!) || [];

	const avatarsMap: Record<string, string> = {};
	lines?.forEach((line) => {
		avatarsMap[line.assigneeUser.uid] = line.assigneeUser.name;
	});

	const tabs: BillingTab[] = [
		{
			label: t('clientBilling.all', 'All'),
			value: 'all',
			count: stats?.count?.all!,
		},
		{
			label: t('clientBilling.pending', 'Pending'),
			value: 'pending',
			count: stats?.count?.pending!,
		},
		{
			label: t('clientBilling.overdue', 'Overdue'),
			value: 'overdue',
			count: stats?.count?.overdue!,
		},
		{
			label: t('clientBilling.paid', 'Paid'),
			value: 'paid',
			count: stats?.count?.paid!,
		},
	];

	const makeDeposit = async (val: string) => {
		try {
			await BillingService.createDeposit({
				paymentMethod: 'bank',
				amount: parseFloat(val),
			});
			toastVariant(
				t(
					`clientBilling.invoiceForDepositWhereCreated`,
					'Invoice for deposit where created',
				),
			);
			setRefresh(refresh + 1);
		} catch (e) {
			toastVariant(
				t(
					`clientBilling.depositCannotBeCreated`,
					'Deposit cannot be created. Please contact support for more details',
				),
				true,
			);
		}
	};

	const viewOrder = async (orderId?: number) => {
		try {
			const { data } = await OrdersService.getOrder(
				orderId!,
				OrderTarget.customer,
			);
			setOrder(data as unknown as OrderInterface);
			setOpenDetails(true);
		} catch (e) {
			console.log(e);
		}
	};

	const additionalClick = (value: InvoiceInterface | number, type?: string) => {
		if (type === PaymentTypeAction.view_order) {
			viewOrder(value as number).then(() => {});
		}
	};

	const closeModal = () => {
		setOrder(undefined);
		setOpenDetails(false);
	};

	const detailsRef = useDetectClickOutside({
		onTriggered: () => closeModal(),
	});

	return (
		<div className="flex flex-col gap-5 w-[calc(100vw-67px)] xl-2xl:w-[calc(100vw-83px)] h-full">
			<div className="grid grid-cols-2 gap-8 md:grid-cols-1 lg:grid-cols-2 ml-5 mr-7.5">
				<div className="flex flex-col gap-4">
					<div className="flex justify-between">
						<SypacText variant="heading-4">
							<p className="text-gray-80">
								<T keyName="producerBilling.manageYourWalletBilling">
									Manage your wallet & billing
								</T>
							</p>
						</SypacText>
						{/* <SypacButton variant="secondary" size="small">
							<button className="px-8 py-3">
								<SypacText variant="body-regular-medium">
									<p>
										<T keyName="clientBilling.contactToSypac">
											Contact to sypac
										</T>
									</p>
								</SypacText>
							</button>
						</SypacButton> */}
					</div>
					<SypacText variant="body-regular-small">
						<p className="text-base text-gray-40">
							<T keyName="clientBilling.downloadInvoicesControlBillingDetails">
								Download invoices and control your billing details.
							</T>
						</p>
					</SypacText>
					{/* <div className="mt-3 w-full">
						<SearchBar
							placeholder={t('clientBilling.searchOrder', 'Search order')}
							onClick={setSearchQuery}
						/>
					</div> */}
				</div>

				<SypacBox>
					<div className="flex p-[10px] gap-3 justify-between">
						<div className="flex flex-col gap-3">
							<SypacText variant="body-regular-small">
								<p className="text-sm text-gray-40">
									<T keyName="clientBilling.currentBalance">Current balance:</T>
								</p>
							</SypacText>

							<div className="flex items-center gap-5">
								<SypacText variant="heading-4">
									<p className="text-xl text-gray-80">
										<NumericFormat
											type="text"
											displayType="text"
											thousandSeparator="."
											decimalSeparator=","
											value={parseFloat(stats?.amount || '0')}
											suffix={' zł'}
										/>
									</p>
								</SypacText>

								<div className="flex gap-3">
									<div className="h-[26px] flex items-center justify-center border border-solid border-gray-10 bg-light-grey rounded-full px-[18px] box-border">
										<SypacText variant="body-regular-medium">
											<p className="text-sm text-gray-80 mt-[2px]">
												<T keyName="clientBilling.creditLimit">Credit limit:</T>{' '}
												<NumericFormat
													type="text"
													displayType="text"
													thousandSeparator="."
													decimalSeparator=","
													value={parseInt(stats?.creditLimit || '0')}
													suffix={' zł'}
												/>
											</p>
										</SypacText>
									</div>

									<div className="h-[26px] flex items-center justify-center border border-solid border-gray-10 bg-light-grey rounded-full px-[18px] box-border">
										<SypacText variant="body-regular-medium">
											<p className="text-sm text-gray-80 mt-[2px]">
												<T keyName="assignees.paymentTerm">Payment term:</T>{' '}
												<NumericFormat
													type="text"
													displayType="text"
													value={13}
													suffix={' days'}
												/>
											</p>
										</SypacText>
									</div>
								</div>
							</div>

							{hasOverduePayments(stats?.count?.overdue) && (
								<SypacText variant="body-regular-medium">
									<p className="text-sm text-gray-40 tracking-tighter">
										<T keyName="clientBilling.youHave">You have </T>
										<span className="text-red">
											{stats?.count?.overdue}
										</span>{' '}
										<span className="text-red">
											<T keyName="clientBilling.overdueOrder">overdue order</T>
										</span>
										<T keyName="clientBilling.totalUnpaidAmount">
											, please make a payment. Total unpaid amount:
										</T>
										<NumericFormat
											className="text-red px-1"
											type="text"
											displayType="text"
											thousandSeparator="."
											decimalSeparator=","
											value={parseFloat(stats?.amountTotal?.overdue || '0')}
											suffix={' zł'}
										/>
									</p>
								</SypacText>
							)}
						</div>

						<SypacButton variant="primary" size="small">
							<button
								type="button"
								className="rounded-lg px-[30px] py-[10px]"
								onClick={() => setDepositOpen(true)}
							>
								<SypacText variant="body-regular-medium">
									<p>
										<T keyName="clientBilling.deposit">Deposit</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
					</div>
				</SypacBox>
			</div>

			<div className="border-0 border-solid border-t-[1px] border-gray-10" />

			<div className="flex gap-7.5 md:flex-col lg:flex-row ml-5 mr-7.5">
				<div className="flex w-full max-w-[745px] items-center">
					<BillingTabs
						data={tabs}
						callback={selectTab}
						activeTab={currentTab}
					/>
				</div>
				{/* <div className="flex flex-row w-full justify-between items-center">
					<AvatarDropDown items={avatars} />
					<SypacButton variant="link">
						<button
							className="underline"
							onClick={() => setOpenDateFilter(true)}
						>
							<SypacText variant="body-regular-medium">
								<p>
									<T keyName="clientBilling.filterByDateRange">
										Filter by date range
									</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
					<Sorting options={sortOptions} action={(item) => setSorting(item)} />
				</div> */}
			</div>

			{invoicesAreLoading ? (
				<div className="flex w-full h-full items-center justify-center">
					<MutatingDots
						height="100"
						width="100"
						color="#7693F4"
						secondaryColor="#494C83"
						radius="12.5"
						ariaLabel="mutating-dots-loading"
						wrapperStyle={{}}
						wrapperClass=""
						visible={true}
					/>
				</div>
			) : (
				<div className="h-full relative ml-5 mr-7.5 border border-solid border-gray-10 rounded-10 overflow-hidden whitespace-nowrap">
					<div className="w-full h-[calc(100%-52px)] overflow-y-auto scroll-smooth pr-[5px]">
						<PaymentsTable
							target={OrderTarget.customer}
							rows={invoicesList?.items || []}
							rowClick={() => {}}
							additionalClick={additionalClick}
							currentTab={currentTab}
						/>
					</div>
					<div className="w-full absolute bottom-0 border-0 border-t border-solid border-t-gray-10 rounded-tl-10 rounded-tr-10 shadow-pagination">
						<div className="flex justify-between items-center h-[51px] px-3">
							<Pagination
								showText={true}
								count={invoicesList?.count!}
								page={page}
								onClick={(item) => setPage(item)}
							/>
						</div>
					</div>
				</div>
			)}
			<ModalFilterDate
				isOpen={isOpenDateFilter}
				onSuccess={(from, to) => {
					setDateFrom(from);
					setDateTo(to);
					setOpenDateFilter(false);
				}}
				onClose={() => {
					setOpenDateFilter(false);
					setDateTo('');
					setDateFrom('');
				}}
			/>
			<ModalDeposit
				isOpen={isDepositOpen}
				onClose={() => setDepositOpen(false)}
				onSubmit={(val) => makeDeposit(val)}
			/>
			{isOpen && selectedOrder?.id ? (
				<div ref={detailsRef}>
					<OrderDetails
						isOpen={!!(isOpen && selectedOrder?.id)}
						selectedOrder={selectedOrder}
					/>
				</div>
			) : null}
		</div>
	);
};

export default ClientBilling;
