import { useEffect, useState } from 'react';

import { ProductsResponse } from '../services/products.service';
import { useApiFacade } from './use-api-facade';

type UseGetProducts = [
	ProductsResponse | undefined,
	Error | undefined,
	boolean,
];

export const useGetProductsTmpl = (
	categoryId?: string,
	limit: number = 100,
	offset: number = 0,
	role = 'producer',
	countryCode?: string,
): UseGetProducts => {
	const facade = useApiFacade();
	const [data, setData] = useState<any>();
	const [error, setError] = useState<Error>();
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		facade.client
			.get(`/v1/${role}/products/templates`, {
				params: {
					limit,
					offset,
					categoryId,
					language: localStorage.getItem('lang') || 'pl',
					countryCode,
				},
			})
			.then((response) => {
				setData(response.data);
				setIsLoading(false);
			})
			.catch((err) => {
				setError(err);
				setIsLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [categoryId, limit, offset, countryCode, facade]);

	return [data, error, isLoading];
};
