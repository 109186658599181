import dayjs from 'dayjs';

/**
 * Returns a human-readable string representing the time since the given date string.
 * @param {string} dateString - The date string to compare with the current time.
 * @param {boolean} includeAgo - Add 'ago' string to the end of the result.
 * @returns {string} - A human-readable string indicating how much time has passed since the given date.
 */
export const formatTimeSince = (
	dateString: string,
	includeAgo: boolean = true,
): string => {
	const date = new Date(dateString);
	const now = new Date();

	const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);
	const diffInMinutes = Math.floor(diffInSeconds / 60);
	const diffInHours = Math.floor(diffInMinutes / 60);
	const diffInDays = Math.floor(diffInHours / 24);
	const diffInWeeks = Math.floor(diffInDays / 7);

	const ago = includeAgo ? ' ago' : '';

	if (diffInSeconds < 60) {
		return 'Just now';
	} else if (diffInMinutes < 60) {
		return `${diffInMinutes}min${ago}`;
	} else if (diffInHours < 24) {
		return `${diffInHours}h${ago}`;
	} else if (diffInDays < 8) {
		return `${diffInDays}d${ago}`;
	} else {
		return `${diffInWeeks}w${ago}`;
	}
};

/**
 * Converts an ISO date string to a specific time zone and formats it to H:mm.
 * @param {string} isoDateString - The ISO date string.
 * @param {number} [timeZoneOffset=0] - The time zone offset in hours (e.g., 3 for UTC+3). Default is 0.
 * @returns {string} - The formatted time string in H:mm format.
 */
export const convertToTimeZone = (
	isoDateString: string,
	timeZoneOffset: number = 0,
): string => {
	const date = new Date(isoDateString);
	const adjustedDate = new Date(
		date.getTime() + timeZoneOffset * 60 * 60 * 1000,
	);
	const hours = adjustedDate.getHours();
	const minutes = adjustedDate.getMinutes().toString().padStart(2, '0');
	return `${hours}:${minutes}`;
};

/**
 * Formats a range of delivery dates from an array of date objects.
 * @param {Array} dates - An array of date objects. If `isOffer` is true, `key` specifies which date field to use.
 * @param {boolean} [minMaxRange=false] - Determines whether to format dates as a minimum and maximum range or standard delivery range.
 * @param {string} [key] - The key in each date object that holds the date value (used only if `isOffer` is true).
 * @param {boolean} [reverseMinMax=false] - Reverses the range.
 * @returns {string} - A formatted string representing the date range. If all dates are the same day, returns that single date.
 */
export const deliveryDates = (
	dates: any[],
	minMaxRange: boolean = false,
	key?: string,
	reverseMinMax: boolean = false,
): string => {
	if (!dates.length) return '';

	const getDates = (dateKey: string) =>
		dates.map((dateObj) => dayjs(dateObj[dateKey]));

	const formatDateRange = (startDate: dayjs.Dayjs, endDate: dayjs.Dayjs) => {
		const sameMonthYear =
			startDate.isSame(endDate, 'month') && startDate.isSame(endDate, 'year');
		return sameMonthYear
			? `${startDate.format('D')} - ${endDate.format('D MMM. YYYY')}`
			: `${startDate.format('D MMM')} - ${endDate.format('D MMM. YYYY')}`;
	};

	if (reverseMinMax) {
		const minFromDate = Math.min(
			...getDates('deliveryFrom').map((day) => day.valueOf()),
		);
		const maxToDate = Math.max(
			...getDates('deliveryTo').map((day) => day.valueOf()),
		);
		const midFromDate = Math.min(
			...getDates('deliveryTo').map((day) => day.valueOf()),
		);
		const midToDate = Math.max(
			...getDates('deliveryFrom').map((day) => day.valueOf()),
		);

		const minDay = dayjs(minFromDate);
		const maxDay = dayjs(maxToDate);
		const midFromDay = dayjs(midFromDate);
		const midToDay = dayjs(midToDate);

		const range1 = formatDateRange(minDay, midFromDay);
		const range2 = formatDateRange(midToDay, maxDay);

		if (range1 === range2) {
			return key === 'deliveryFrom'
				? minDay.format('D MMM. YYYY')
				: maxDay.format('D MMM. YYYY');
		}

		return key === 'deliveryFrom' ? range1 : range2;
	}

	const [minDate, maxDate] = minMaxRange
		? [
				Math.min(...getDates(key!).map((day) => day.valueOf())),
				Math.max(...getDates(key!).map((day) => day.valueOf())),
		  ]
		: [
				Math.min(...getDates('deliveryFrom').map((day) => day.valueOf())),
				Math.max(...getDates('deliveryTo').map((day) => day.valueOf())),
		  ];

	const minDay = dayjs(minDate);
	const maxDay = dayjs(maxDate);

	if (minDay.isSame(maxDay, 'day')) return minDay.format('DD MMM. YYYY');
	return formatDateRange(minDay, maxDay);
};

/**
 * Calculates the remaining days from the issue date based on a payment term.
 * If overdue, returns the number of days past the due date.
 * @param {string} termDateString - The due date as a string.
 * @returns {number} - The number of remaining days to pay the invoice or the number of overdue days (negative value).
 */
export const calculateRemainingDays = (termDateString: string): number => {
	const termDate = new Date(termDateString);
	const today = new Date();
	const differenceInTime = termDate.getTime() - today.getTime();
	return Math.ceil(differenceInTime / (1000 * 3600 * 24) - 1);
};
