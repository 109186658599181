import React, { useEffect, useState } from 'react';
import { SypacAvatar, SypacText } from '@sypac/component-library-react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { AvatarDropDownProps, AvatarItem } from './AvatarDropDown.interface';

const AvatarDropDown = (props: AvatarDropDownProps) => {
	const { items, onChange, userIsAssigned } = props;
	const [listItems, setListItems] = useState<AvatarItem[]>([]);
	const [dropDownItems, setDropDownItems] = useState<AvatarItem[]>([]);
	const [openDropDown, setOpenDropDown] = useState<boolean>(false);
	const [selectedItem, setItem] = useState<string>();

	const dropDownRef = useDetectClickOutside({
		onTriggered: () => openDropDown && setOpenDropDown(false),
	});

	useEffect(() => {
		if (items.length && items.length > 6) {
			const avatars = JSON.parse(JSON.stringify(items));
			const firstItems = avatars.splice(0, 5);
			const secondItems = avatars.splice(-5);
			setListItems(firstItems);
			setDropDownItems(secondItems);
		} else {
			setListItems(items);
		}
	}, [items]);

	const openDropDownItems = () => {
		setOpenDropDown(!openDropDown);
	};

	const selectItem = (item: AvatarItem) => {
		setOpenDropDown(false);
		setItem(item.id);
		onChange && onChange(item.id);
	};

	return (
		<div className="relative w-fit" ref={dropDownRef}>
			<div className="flex flex-row">
				{listItems?.length
					? listItems?.map((item, index) => (
							<SypacAvatar
								onClick={() => selectItem(item)}
								initials={item.fullName}
								isGrouped={true}
								isChosen={userIsAssigned}
								key={index}
								size="md"
								className="cursor-pointer scale-75 xl-2xl:scale-100"
							>
								{item.avatarUrl ? (
									<img src={item.avatarUrl} alt={item.fullName} />
								) : null}
							</SypacAvatar>
					  ))
					: null}
				{dropDownItems.length ? (
					<div
						className="relative cursor-pointer flex flex-row justify-center items-center h-[47px] w-[47px] rounded-[24px] outline-2 outline-white outline bg-primary-violet"
						onClick={openDropDownItems}
					>
						<SypacText variant="body-regular-small">
							<p className="text-white font-bold">+{dropDownItems.length}</p>
						</SypacText>
					</div>
				) : null}
			</div>
			{openDropDown && dropDownItems.length ? (
				<div className="flex border border-solid border-gray-10 bg-white rounded-10 shadow-dropdown-avatar p-3 absolute right-0 mt-3 z-10">
					<div className="flex flex-col overflow-y-auto">
						{dropDownItems?.map((item, index) => {
							return (
								<div
									key={index}
									className={`flex flex-row items-center rounded-7 py-2 px-3 cursor-pointer hover:bg-gray-10-opacity-50 ${
										item.id === selectedItem ? 'bg-gray-10-opacity-50' : ''
									} ${index !== 0 ? 'mt-2' : ''}`}
									onClick={() => selectItem(item)}
								>
									<SypacAvatar initials={item.fullName} size="sm">
										{item.avatarUrl ? (
											<img src={item.avatarUrl} alt={item.fullName} />
										) : null}
									</SypacAvatar>
									<SypacText variant="body-regular-small" className="ml-3">
										<p className="text-gray-80">{item.fullName}</p>
									</SypacText>
								</div>
							);
						})}
					</div>
				</div>
			) : null}
		</div>
	);
};

export default AvatarDropDown;
