import React from 'react';
import { SypacTabs } from '@sypac/component-library-react';
import { BillingTab, BillingTabsProps } from './BillingTabs.interface';
import { TabsButton } from '../TabsButton/TabsButton';

const BillingTabs: React.FC<BillingTabsProps> = ({
	data,
	callback,
	activeTab,
	classNames,
}) => {
	return (
		<SypacTabs className={`flex w-fit p-[3px] gap-[4px] ${classNames}`}>
			{data
				? data?.map(({ label, value, count }: BillingTab) => {
						return (
							<TabsButton
								key={value}
								label={label}
								buttonId={value!}
								onClick={() => callback(value ? value : label.toLowerCase())}
								isSelected={
									activeTab === value || activeTab === label.toLowerCase()
								}
								notification={+count}
								color="#E8E8E8"
								classNames={classNames}
							/>
						);
				  })
				: null}
		</SypacTabs>
	);
};

export default BillingTabs;
