import React, { useCallback, useEffect, useState } from 'react';
import {
	SypacBox,
	SypacButton,
	SypacIcon,
	SypacInput,
	SypacText,
} from '@sypac/component-library-react';
import { T, useTranslate } from '@tolgee/react';
import classNames from 'classnames';
import { ChevronDownIcon } from '../../assets/ChevronDownIcon';
import { DumpTruck } from '../../assets/DumpTruck';
import Flag from 'react-world-flags';
import {
	DriverDto,
	FleetBaseDto,
	TruckResponse,
	TruckService,
	UpdateFleetBase,
} from '../../services/truck.services';
import { SemiTrailer } from '../../assets/SemiTrailer';
import CircleClose from '../../assets/CircleClose';
import CircleValid from '../../assets/CircleValid';
import Map from '../Map/Map';
import { Geo } from '../SearchLocation/locations.interface';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { GeoService } from '../../services/geo.services';
import useDebounce from '../../hooks/useDebounce';
import { ChevronRightIcon } from '../../assets/ChevronRightIcon';
import { MapMarker, PinVariant } from '../Map/Map.interface';
import { toastVariant } from '../CompaniesTable/toastVariant/toastVariant';

interface CompanyFleetProps {
	fleet: TruckResponse | undefined;
	carrierBase: FleetBaseDto | undefined;
	companyId: number | undefined;
	refresh: (value: number) => void;
}

const radiusOptions = [
	{ label: '25 km', value: '25' },
	{ label: '50 km', value: '50' },
	{ label: '75 km', value: '75' },
	{ label: '100 km', value: '100' },
	{ label: '200 km', value: '200' },
];

const CompanyFleet = (props: CompanyFleetProps) => {
	const { fleet, carrierBase, companyId, refresh } = props;
	const { t } = useTranslate();
	const [showMore, setShowMore] = useState<{ [key: string]: boolean }>({});
	const [editable, setEditable] = useState<boolean>(false);
	const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
	const [locationResults, setLocationResults] = useState<boolean>(false);
	const [searchLocation, setSearchLocation] = useState<string>('');
	const searchQuery = useDebounce(searchLocation, 500);
	const [locations, setLocations] = useState<Geo[]>([]);
	const [baseLocation, setBaseLocation] = useState<Geo | undefined>(undefined);
	const [markers, setMarkers] = useState<any[]>([]);
	const [location, setLocation] = useState<Partial<MapMarker> | undefined>(
		undefined,
	);

	useEffect(() => {
		if (carrierBase) {
			const newMarker = {
				latitude: carrierBase.location.coordinates[1],
				longitude: carrierBase.location.coordinates[0],
				variant: PinVariant.fleet_base,
				fleetBase: {
					address: carrierBase.address,
					countryCode: carrierBase.countryCode,
				},
				radius: {
					value: carrierBase?.radiusDistance,
					isAdjustable: false,
				},
			};

			setMarkers((prevMarkers) => {
				const filteredMarkers = prevMarkers.filter(
					(marker) => marker.variant !== PinVariant.fleet_base,
				);
				return [...filteredMarkers, newMarker];
			});
			setLocation(newMarker);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [carrierBase]);

	const formik = useFormik({
		initialValues: {
			baseLocation: carrierBase?.address || '',
			workingRadius: carrierBase?.radiusDistance?.toString() || '',
		},
		enableReinitialize: true,
		onSubmit: async (values) => {
			const baseData: UpdateFleetBase = {
				radiusDistance: +values.workingRadius,
				address: values.baseLocation,
				lat: location?.latitude!,
				long: location?.longitude!,
			};

			await TruckService.updateFleetBaseById(companyId?.toString()!, {
				...baseData,
			});
			closeEdit();
			refresh(1);
		},
		validationSchema: Yup.object({
			baseLocation: Yup.string().required(
				t('fleet.fleetBaseLocationRequired', 'Fleet base location is required'),
			),
			workingRadius: Yup.string().required(
				t('fleet.workingRadiusRequired', 'Working radius is required'),
			),
		}),
	});

	const getSearchLocation = useCallback(() => {
		if (searchQuery.length) {
			GeoService.getLocations({ phrase: searchQuery, maxResults: '10' })
				.then(({ data }) => {
					setLocations(data);
				})
				.catch(console.error);
		} else {
			setLocations([]);
		}
	}, [searchQuery]);

	useEffect(() => {
		getSearchLocation();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchQuery]);

	const toggleShowMore = (id: number) => {
		setShowMore((prev) => ({ ...prev, [id]: !prev[id] }));
	};

	const fieldDefinitions = {
		initialFields: [
			{ label: 'Login code', key: 'code' },
			{ label: 'Truck ID number', key: 'id' },
			{ label: 'Truck plates', key: 'licensePlates' },
		],
		additionalFieldsOne: [
			{ label: 'Truck make', key: 'truckBrand' },
			{ label: 'Truck model', key: 'truckModel' },
			{ label: 'Truck color', key: 'truckColor' },
			{ label: 'Year of production', key: 'truckYear' },
			{ label: 'Emission type', key: 'emissionType' },
		],
		additionalFieldsTwo: [
			{ label: 'Driver first name', key: 'driverFullName' },
			{ label: 'Driver last name', key: 'driverFullName' },
			{ label: 'Driver phone number', key: 'driverPhone' },
		],
	};

	const getNameParts = (fullName: string) => {
		const parts = fullName.trim().split(' ');
		const lastName = parts.pop();
		const firstName = parts.join(' ');
		return { firstName, lastName };
	};

	const InfoField = ({ label, value, countryCode }: any) => {
		let displayValue = value;
		if (label === 'Driver first name' && typeof value === 'string') {
			displayValue = getNameParts(value).firstName;
		} else if (label === 'Driver last name' && typeof value === 'string') {
			displayValue = getNameParts(value).lastName;
		} else if (label === 'Truck color' && typeof value === 'string') {
			displayValue = value.charAt(0).toUpperCase() + value.slice(1);
		}

		return (
			<div className="flex w-[380px] justify-between whitespace-nowrap">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-40">{label}:</p>
				</SypacText>
				<div className="w-[150px]">
					{label === 'Driver phone number' ? (
						<div className="flex col-span-7 gap-2.5 items-center">
							<span className="rounded-[3px] overflow-hidden">
								<Flag code={countryCode} width={24} height={16} />
							</span>
							<SypacText variant="body-regular-medium">
								<p className="text-gray-80 -tracking-[0.01em]">
									{value ? `+${String(value)}` : '-'}
								</p>
							</SypacText>
						</div>
					) : (
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">
								{displayValue ? String(displayValue) : '-'}
							</p>
						</SypacText>
					)}
				</div>
			</div>
		);
	};

	const renderFields = (fields: any, truck: any) =>
		fields.map((field: any) => (
			<InfoField
				key={field.label}
				label={field.label}
				value={truck[field.key]}
				countryCode={truck.company.countryCode}
			/>
		));

	const closeEdit = () => {
		if (formik.values.baseLocation) {
			const carrierMarker = {
				latitude: carrierBase?.location.coordinates[1],
				longitude: carrierBase?.location.coordinates[0],
				variant: PinVariant.fleet_base,
				fleetBase: {
					address: carrierBase?.address,
					countryCode: carrierBase?.countryCode,
				},
				radius: {
					value: carrierBase?.radiusDistance,
					isAdjustable: false,
				},
			};
			setMarkers((prevMarkers) => {
				const filteredMarkers = prevMarkers.filter(
					(marker) => marker.variant !== PinVariant.fleet_base,
				);
				return [...filteredMarkers, carrierMarker];
			});
			setLocation(carrierMarker);
		}
		setLocations([]);
		setBaseLocation(undefined);
		formik.resetForm();
		setEditable(false);
	};

	const onSelectLocation = (location: Geo) => {
		setBaseLocation(location);
		formik.setFieldValue('baseLocation', location.label);
		setLocationResults(false);
	};

	const handleOutsideClick = () => {
		if (isDropdownOpen && !formik.values.workingRadius) {
			formik.setFieldTouched('workingRadius', true);
			formik.validateField('workingRadius');
		}
		setIsDropdownOpen(false);
	};

	const getBaseCoords = useCallback(
		async (locationId: string) => {
			if (locationId) {
				try {
					const { data } = await GeoService.getLocationDetails({
						locationid: locationId,
					});
					const newMarker = {
						latitude: data.Latitude,
						longitude: data.Longitude,
						variant: PinVariant.fleet_base,
						fleetBase: {
							address: data.address?.label,
							countryCode: data.address?.countryCode,
						},
						radius: {
							value: +formik.values.workingRadius,
							isAdjustable: false,
						},
					};

					setMarkers((prevMarkers) => {
						const filteredMarkers = prevMarkers.filter(
							(marker) => marker.variant !== PinVariant.fleet_base,
						);
						return [...filteredMarkers, newMarker];
					});
					setLocation(newMarker);
				} catch (error) {
					return toastVariant(
						`Something went wrong. ${error?.toString()!}`,
						true,
					);
				}
			}
		},
		[formik.values.workingRadius],
	);

	useEffect(() => {
		if (baseLocation?.locationId) {
			getBaseCoords(baseLocation?.locationId).then(() => {});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [baseLocation?.locationId, formik.values.workingRadius]);

	const locationRef = useDetectClickOutside({
		onTriggered: () => setLocationResults(false),
	});
	const radiusRef = useDetectClickOutside({ onTriggered: handleOutsideClick });

	return (
		<div className="flex flex-col gap-4 px-10 py-4 border-0 border-solid border-t-[1px] border-gray-10">
			{carrierBase ? (
				<>
					<div className="flex items-center justify-between">
						<SypacText variant="body-regular-medium">
							<p className="text-gray-40">
								<T keyName="companyFleet.fleetBase">Fleet base</T>
							</p>
						</SypacText>

						{editable ? (
							<div className="flex gap-x-4">
								<SypacButton
									variant="secondary"
									size="small"
									onClick={closeEdit}
								>
									<button
										type="reset"
										className="px-5 py-1 border-red rounded-lg transition hover:border-red/50"
									>
										<CircleClose />
										<SypacText variant="body-regular-medium" className="ml-3">
											<p className="text-red">
												<T keyName="companyWallet.cancel">Cancel</T>
											</p>
										</SypacText>
									</button>
								</SypacButton>

								<SypacButton variant="secondary" size="small">
									<button
										type="button"
										className="px-5 py-1 border-mountain-meadow rounded-lg transition hover:border-mountain-meadow/50"
										onClick={() => formik.handleSubmit()}
									>
										<CircleValid />
										<SypacText variant="body-regular-medium" className="ml-3">
											<p className="text-mountain-meadow">
												<T keyName="companyWallet.save">Save</T>
											</p>
										</SypacText>
									</button>
								</SypacButton>
							</div>
						) : (
							<SypacButton variant="secondary" size="small">
								<button
									type="button"
									className="w-full border-none bg-transparent p-0 group"
									onClick={() => setEditable(true)}
								>
									<span className="flex m-0 scale-[1.3]">
										<SypacIcon
											iconName="Document Add"
											size="custom"
											width="32px"
											height="32px"
											className="text-gray"
										/>
									</span>
									<SypacText variant="body-normal-medium">
										<p className="text-cornflower-blue transition group-hover:text-gray-80">
											<T keyName="companyWallet.edit">Edit</T>
										</p>
									</SypacText>
								</button>
							</SypacButton>
						)}
					</div>
					{editable && (
						<div className="border-0 border-solid border-t-[1px] border-gray-10 mt-1" />
					)}

					{!editable && (
						<div className="w-full h-[162px] border border-solid border-gray-10 rounded-xl">
							<Map poligons={[]} markers={markers || []} />
						</div>
					)}

					{editable ? (
						<form className="flex gap-7 mb-3">
							<div className="w-[415px] flex flex-col gap-3">
								<SypacText variant="body-regular-small">
									<p className="text-sm">
										<T keyName="fleet.fleetBaseLocation">Fleet base location</T>
										<span className="text-red">*</span>
									</p>
								</SypacText>

								<div ref={locationRef} className="relative">
									<SypacInput
										error={
											!!(
												formik.touched.baseLocation &&
												formik.errors.baseLocation
											)
										}
									>
										<input
											autoComplete="off"
											type="text"
											name="baseLocation"
											className={`py-3 pl-3 border border-gray-10 rounded-lg placeholder:text-gray-40 placeholder:text-base transition ${
												locationResults
													? 'border-cornflower-blue'
													: 'hover:border-gray-30'
											}`}
											placeholder={
												carrierBase.address ||
												t(
													'fleet.enterFleetBaseAddress',
													'Enter fleet base address',
												)
											}
											onBlur={formik.handleBlur}
											value={formik.values.baseLocation}
											onFocus={() => setLocationResults(true)}
											onChange={(event) => {
												formik.handleChange(event);
												setSearchLocation(event.target.value);
											}}
										/>
										{formik.touched.baseLocation &&
										formik.errors.baseLocation ? (
											<span className="input-error">
												{formik.errors.baseLocation}
											</span>
										) : null}
									</SypacInput>

									<div
										className={`top-[52px] flex-col absolute w-full shadow-dropdown border-[1px] border-gray-10 rounded-lg max-h-72 overflow-hidden bg-white z-50 ${
											locationResults ? 'flex' : 'hidden'
										}`}
									>
										{locations?.length ? (
											<div className="flex flex-col w-full p-3 overflow-y-scroll">
												{locations.map((location: Geo) => (
													<div
														key={location.locationId}
														className="flex items-center hover:bg-gray-10-opacity-50 rounded-md cursor-pointer mr-[9px]"
														onClick={() => onSelectLocation(location)}
													>
														<div className="mx-5">
															<Flag
																className="rounded-[3px]"
																code={location.countryCode.toLocaleUpperCase()}
																width={22}
																height={16}
															/>
														</div>
														<SypacText
															variant="body-normal-medium"
															className="my-[15px]"
														>
															<p>
																{location.address?.city},{' '}
																{location.address?.street} str.,
																{location.address?.houseNumber
																	? ` ${location.address?.houseNumber},`
																	: ''}{' '}
																Zip {location.address?.postalCode}
															</p>
														</SypacText>
													</div>
												))}
											</div>
										) : null}
									</div>
								</div>
							</div>

							<div className="w-[201px] flex flex-col gap-3">
								<SypacText variant="body-regular-small">
									<p className="text-sm">
										<T keyName="fleet.workingRadius">Working radius</T>
										<span className="text-red">*</span>
									</p>
								</SypacText>

								<div ref={radiusRef} className="relative">
									<div
										className={`flex justify-between items-center h-[46px] box-border border border-solid border-gray-10 bg-white rounded-lg py-2.5 px-3 cursor-pointer transition ${
											isDropdownOpen
												? 'border-cornflower-blue'
												: 'hover:border-gray-30'
										} ${
											formik.touched.workingRadius &&
											formik.errors.workingRadius
												? 'border-red-orange'
												: 'border-gray-10'
										}`}
										onClick={() => setIsDropdownOpen(true)}
									>
										<SypacText variant="body-normal-small">
											<p
												className={`text-base ${
													formik.values.workingRadius
														? 'text-gray-80'
														: 'text-gray-40'
												}`}
											>
												{formik.values.workingRadius ? (
													radiusOptions.find(
														(option) =>
															option.value === formik.values.workingRadius,
													)?.label
												) : carrierBase.radiusDistance ? (
													`${carrierBase.radiusDistance} km`
												) : (
													<T keyName="fleet.selectWorkingRadius">
														Select working radius
													</T>
												)}
											</p>
										</SypacText>

										<span
											className={classNames(
												'flex my-auto transition-all mr-[2px]',
												{
													'transform rotate-90': isDropdownOpen,
												},
											)}
										>
											<ChevronRightIcon />
										</span>
									</div>

									{isDropdownOpen && (
										<div
											className={`fixed w-[191px] box-border flex flex-col p-3 rounded-lg shadow-dropdown bg-white z-30`}
											style={{
												// @ts-ignore
												bottom: `${radiusRef?.current?.offsetTop - 119}px`,
											}}
										>
											{radiusOptions.map((radius) => (
												<div
													key={radius.value}
													className="no-underline flex hover:bg-gray-10-opacity-50 bg-white border-none pt-4 pb-[17px] px-5 rounded-md cursor-pointer"
													onClick={() => {
														formik.setFieldValue('workingRadius', radius.value);
														// setBaseRadius(+radius.value);
														setIsDropdownOpen(false);
													}}
												>
													<SypacText
														variant="body-regular-medium"
														className="mr-auto"
													>
														<p className="text-gray-80 leading-[19px]">
															{radius.label}
														</p>
													</SypacText>
												</div>
											))}
										</div>
									)}

									{formik.touched.workingRadius &&
									formik.errors.workingRadius ? (
										<span className="input-error">
											{formik.errors.workingRadius}
										</span>
									) : null}
								</div>
							</div>
						</form>
					) : (
						<div className="flex gap-9 mb-3">
							<div className="flex flex-col gap-3">
								<SypacText variant="body-regular-medium" className="mr-3">
									<p className="text-sm text-gray-40">
										<T keyName="companyFleet.fleetBaseAddress">
											Fleet base address:
										</T>
									</p>
								</SypacText>

								<div className="flex items-center gap-3">
									<span className="mb-auto pt-[2px]">
										<Flag
											className="w-[20px] h-[14px] object-cover border border-solid border-gray-10 rounded"
											code={carrierBase.countryCode}
											width={24}
											height={16}
										/>
									</span>
									<SypacText variant="body-regular-medium">
										<p className="text-gray-80 w-[284px]">
											{carrierBase.address}
										</p>
									</SypacText>
								</div>
							</div>

							<div className="flex flex-col gap-3">
								<SypacText variant="body-regular-medium" className="mr-3">
									<p className="text-sm text-gray-40">
										<T keyName="companyFleet.workingRadius">Working radius:</T>
									</p>
								</SypacText>

								<SypacText variant="body-regular-medium">
									<p className="text-gray-80 w-[284px]">
										{carrierBase.radiusDistance} km.
									</p>
								</SypacText>
							</div>
						</div>
					)}

					{editable && (
						<div className="w-full h-[162px] border border-solid border-gray-10 rounded-xl">
							<Map poligons={[]} markers={markers || []} />
						</div>
					)}

					{!editable && (
						<div className="border-0 border-solid border-t-[1px] border-gray-10" />
					)}
				</>
			) : null}

			{!editable ? (
				<>
					<SypacText variant="body-regular-medium">
						<p className="text-gray-40">
							<T keyName="companyFleet.fleetSize">Fleet size</T>
						</p>
					</SypacText>

					{fleet?.items?.map((truck: DriverDto) => (
						<SypacBox
							key={truck.id}
							hover={false}
							box-style="solid"
							box-color="primary"
							className="bg-alabaster transition hover:border-gray-80"
						>
							<div className="flex justify-between px-5 py-4 border-0 border-b border-solid border-gray-10">
								<div className="flex gap-6 items-center">
									<div className="flex items-center justify-center w-[100px] h-[55px] border border-solid border-gray-10 rounded-10 bg-white">
										{truck.type === 'Dump Truck' ? (
											<DumpTruck />
										) : (
											<SemiTrailer />
										)}
									</div>
									<div className="flex flex-col gap-2">
										<SypacText variant="body-regular-medium">
											<p className="text-gray-80">
												<T keyName="companyStats.statistics">{truck.type}</T>
											</p>
										</SypacText>
										<SypacText variant="body-regular-medium">
											<p className="text-sm text-gray-40">{`Payload: ${truck.truckPayload} tons`}</p>
										</SypacText>
									</div>
								</div>
								{/*<SypacBadge color="gray" outlined={false}>
							<div className="flex justify-between items-center text-sm font-medium whitespace-nowrap min-w-[110px] max-h-[22px]">
								<span className="p-0 m-0 flex scale-[1.2]">
									<StatusClock />
								</span>
								<p className="m-0 flex-1 text-center">In Progress</p>
							</div>
						</SypacBadge>*/}
							</div>

							<div className="flex flex-col gap-4 p-5 border-0 border-b-0 border-solid border-gray-10">
								{renderFields(fieldDefinitions.initialFields, truck)}
							</div>

							<div
								className={`transition-all ease-in-out duration-500 ${
									showMore[truck.id]
										? 'max-h-screen opacity-100'
										: 'max-h-0 opacity-0 overflow-hidden'
								}`}
							>
								<div className="flex flex-col gap-4 p-5 border-0 border-b-0 border-solid border-gray-10">
									{renderFields(fieldDefinitions.additionalFieldsOne, truck)}
								</div>
								<div className="flex flex-col gap-4 p-5 border-0 border-b-0 border-solid border-gray-10">
									{renderFields(fieldDefinitions.additionalFieldsTwo, truck)}
								</div>
							</div>

							<div className="flex justify-center px-5 py-1 border-0 border-t border-solid border-gray-10">
								<SypacButton
									variant="secondary"
									size="small"
									onClick={() => toggleShowMore(truck.id)}
								>
									<button
										className="px-5 py-2 border-0 bg-transparent"
										type="button"
									>
										<SypacText variant="body-regular-medium" className="mr-3">
											<p className="text-primary-violet">
												{showMore[truck.id] ? (
													<T keyName="companyStats.showLess">Show less</T>
												) : (
													<T keyName="companyStats.showMore">Show more</T>
												)}
											</p>
										</SypacText>
										<span
											className={classNames(
												'flex my-auto transform mr-1 transition-all duration-300 ease-in-out',
												{
													'-rotate-180': showMore[truck.id],
												},
											)}
										>
											<ChevronDownIcon />
										</span>
									</button>
								</SypacButton>
							</div>
						</SypacBox>
					))}
				</>
			) : null}
		</div>
	);
};

export default CompanyFleet;
