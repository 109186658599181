const AlertBig = () => {
	return (
		<svg
			width="68"
			height="62"
			viewBox="0 0 68 62"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M11.9286 26.8716C21.5592 9.62388 26.3745 1 34 1C41.6255 1 46.4408 9.62387 56.0713 26.8716L57.2714 29.0209C65.2744 43.3537 69.2759 50.5201 65.6593 55.76C62.0428 61 53.0952 61 35.2001 61H32.7999C14.9047 61 5.95717 61 2.34065 55.76C-1.27587 50.5201 2.72561 43.3537 10.7286 29.0209L11.9286 26.8716Z"
				stroke="#454545"
				strokeWidth="2"
			/>
			<path
				d="M34 17.667V34.3337"
				stroke="#454545"
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<circle cx="34" cy="44" r="1.5" fill="#1C274C" stroke="#454545" />
		</svg>
	);
};

export default AlertBig;
