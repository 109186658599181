import { useEffect, useState } from 'react';
import { SypacBox, SypacText } from '@sypac/component-library-react';
import { ListMenuProps } from './ListMenu.interface';

export const ListMenu = ({ items, selected }: ListMenuProps) => {
	const [active, setActive] = useState<string>(selected || '');
	useEffect(() => {
		if (!selected && items?.length) {
			setActive(items[0].id);
		}
	}, [selected, items]);

	return (
		<SypacBox hover={false} boxStyle="none" className="w-[255px]">
			<div className="flex flex-col gap-2.5">
				{items?.map(({ id, name, onClick }, index) => {
					return (
						<button
							key={`${Math.random()}-${index}`}
							className={`w-full flex items-center rounded-lg py-2.75 px-3 border-none transition cursor-pointer ${
								active === id
									? 'bg-primary-violet text-white'
									: 'bg-transparent hover:bg-gray-10 text-gray-80'
							}`}
							onClick={() => {
								setActive(id);
								onClick && onClick();
							}}
						>
							<SypacText variant="body-regular-medium">
								<p className="">{name}</p>
							</SypacText>
						</button>
					);
				})}
			</div>
		</SypacBox>
	);
};
