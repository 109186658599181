import { IconInterface } from '../interfaces/icon.interface';

export const PlusIcon = (props: IconInterface) => {
	const { color } = props;

	return (
		<svg
			width="14"
			height="14"
			viewBox="0 0 14 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7 1V13M13 7L1 7"
				stroke={color || '#494C83'}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
