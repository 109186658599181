import React from 'react';
import {
	SypacVerticalTimeline,
	SypacVerticalTimelineStep,
} from '@sypac/component-library-react';
import Default from '../../../../../../components/Notification/Default';
import { useTranslate } from '@tolgee/react';

interface NotificationPendingPaymentProps {
	notification: boolean;
	date: string;
}
const NotificationPendingPayment = ({
	notification,
	date,
}: NotificationPendingPaymentProps) => {
	const { t } = useTranslate();

	return (
		<div className="flex flex-row gap-6">
			<SypacVerticalTimeline>
				<SypacVerticalTimelineStep isActive={notification} minWidth={98}>
					{date}
				</SypacVerticalTimelineStep>
			</SypacVerticalTimeline>
			<div className="h-fit w-full">
				<Default
					notification={notification}
					title={t(
						'notificationPendingPayment.pendingPayment',
						'Pending payment',
					)}
					description={t(
						'notificationPendingPayment.PendingPaymentDescription',
						'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
					)}
				/>
			</div>
		</div>
	);
};

export default NotificationPendingPayment;
