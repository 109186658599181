const Invoice = () => {
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M13.7734 16.6371C14.3178 16.1531 15.1382 16.1531 15.6826 16.6371C16.0525 16.9658 16.6372 16.7033 16.6372 16.2084V1.79164C16.6372 1.29673 16.0525 1.03415 15.6826 1.36295C15.1382 1.84688 14.3178 1.84688 13.7734 1.36295C13.229 0.879018 12.4086 0.879018 11.8641 1.36295C11.3197 1.84688 10.4993 1.84688 9.95488 1.36295C9.41046 0.879018 8.59006 0.879018 8.04564 1.36295C7.50122 1.84688 6.68081 1.84688 6.13639 1.36295C5.59197 0.879018 4.77157 0.879018 4.22715 1.36295C3.68273 1.84688 2.86232 1.84688 2.3179 1.36295C1.94801 1.03415 1.36328 1.29673 1.36328 1.79164V16.2084C1.36328 16.7033 1.94801 16.9658 2.3179 16.6371C2.86232 16.1531 3.68273 16.1531 4.22715 16.6371C4.77157 17.121 5.59197 17.121 6.13639 16.6371C6.68081 16.1531 7.50122 16.1531 8.04564 16.6371C8.59006 17.121 9.41046 17.121 9.95488 16.6371C10.4993 16.1531 11.3197 16.1531 11.8641 16.6371C12.4086 17.121 13.229 17.121 13.7734 16.6371Z"
				stroke="#8B8B8B"
			/>
			<path
				d="M5.18164 11.9707H12.8186"
				stroke="#8B8B8B"
				strokeLinecap="round"
			/>
			<path d="M5.18164 9H12.8186" stroke="#8B8B8B" strokeLinecap="round" />
			<path
				d="M5.18164 6.0293H12.8186"
				stroke="#8B8B8B"
				strokeLinecap="round"
			/>
		</svg>
	);
};

export default Invoice;
