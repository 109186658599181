import React from 'react';
import { SypacText } from '@sypac/component-library-react';
import { TabsButtonInterface } from './TabsButton.interface';

export const TabsButton: React.FC<TabsButtonInterface> = ({
	label,
	buttonId,
	onClick,
	isSelected,
	handleSelectedTab,
	notification,
	color,
	classNames,
}) => {
	const defaultColor = color || '#F44A77';

	return (
		<div
			className={`relative w-fit h-[36px] ${classNames}`}
			onClick={handleSelectedTab}
		>
			<button
				type="button"
				className={`w-fit h-[36px] flex gap-3 items-center justify-center border-0 rounded-md px-3.5 py-1.5 transition ${
					['all', 'to_do'].includes(buttonId) ? 'xl:px-12' : ''
				} ${
					isSelected
						? 'bg-primary-violet'
						: `bg-transparent ${
								color ? 'hover:bg-gray-20' : 'hover:bg-gray-10'
						  } cursor-pointer`
				} ${classNames}`}
				onClick={onClick}
			>
				<SypacText variant="body-regular-medium">
					<p
						className={`mt-[2px] ${isSelected ? 'text-white' : 'text-gray-80'}`}
					>
						{label}
					</p>
				</SypacText>
				{notification ? (
					<div
						className={`h-5 flex items-center justify-center rounded-md text-gray-80 transition ${
							isSelected ? 'bg-white' : ''
						} ${notification > 9 ? 'px-1' : 'px-[7px]'}`}
						style={!isSelected ? { backgroundColor: defaultColor } : {}}
					>
						<SypacText variant="body-regular-medium">
							<p
								className={`text-xs ${
									isSelected || color ? 'text-gray-80' : 'text-white'
								}`}
							>
								{notification}
							</p>
						</SypacText>
					</div>
				) : null}
			</button>
		</div>
	);
};
