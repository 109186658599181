import React from 'react';
import {
	SypacVerticalTimeline,
	SypacVerticalTimelineStep,
} from '@sypac/component-library-react';
import Default from '../../../../../../components/Notification/Default';
import { useTranslate } from '@tolgee/react';

interface NotificationPaidProps {
	notification: boolean;
	date: string;
}
const NotificationPaid = ({ notification, date }: NotificationPaidProps) => {
	const { t } = useTranslate();

	return (
		<div className="flex flex-row gap-6">
			<SypacVerticalTimeline>
				<SypacVerticalTimelineStep isActive={notification} minWidth={98}>
					{date}
				</SypacVerticalTimelineStep>
			</SypacVerticalTimeline>
			<div className="h-fit w-full">
				<Default
					notification={notification}
					title={t('notificationPaid.orderPayed', 'Order paid')}
					description={t(
						'notificationPaid.thankYouCooperation',
						'Thank you for cooperation!',
					)}
				/>
			</div>
		</div>
	);
};

export default NotificationPaid;
