import { Point } from '../../../services/company.services';
import {
	AdminOrderStatusesEnum,
	OrderStatusesEnum,
} from '../../../services/orders.services';
import { FleetDto } from '../../Map/Map.interface';
import { TruckDto } from '../../../services/truck.services';
import { UsersInterface } from '../../../interfaces/Users.interface';
import {
	OrderTarget,
	PaymentMethod,
	ProductUnitType,
} from './OrderStatus.interface';
import { TransportSolutions } from '../../../containers/Admin/OrderDetails/adminOrder.interface';

export interface Quantity {
	type?: string | undefined;
	count: number;
}

export interface ProductOrderInterface {
	id: number;
	photoUrl: string;
	pricePerUnit: number;
	pricePerUnitWithProcent: number;
	quantity: number;
	name: string;
	size?: string | undefined;
	type?: string | undefined;
	unit?: ProductUnitType | undefined;
	unlimited?: boolean | undefined;
	currency?: string;
	country?: string;
}

export enum NotificationCategory {
	NEW_OFFER = 'new_offer',
	UPDATE_OFFER = 'update_offer',
	NEW_ORDER = 'new_order',
	ORDER_ACCEPTED_BY_DRIVER = 'order_accepted_by_driver',
	NEW_PRODUCT = 'new_product',
	NEW_ISSUE = 'new_issue',
	DRIVER_RESOLVE_ISSUE = 'driver_resolve_issue',
	DISPATCHER_RESOLVE_ISSUE = 'dispatcher_resolve_issue',
	DRIVER_CHANGE_TRUCK = 'driver_change_truck',
}

export interface NotificationInterface {
	id: string;
	recipientId: string;
	groupId: string;
	content?: any;
	category: string;
	readAt?: string | undefined;
	canceledAt?: string | undefined;
	createdAt: string;
}
export interface ResponseNotificationListInterface {
	count: number;
	items: NotificationInterface[];
}
export interface DriverLoadDetails {
	comments?: string | undefined;
	wight: number;
	documents: string[];
}

export interface OrderInterface {
	id: number;
	orderId?: number;
	offerId?: number;
	target: OrderTarget;
	status: OrderStatusesEnum & AdminOrderStatusesEnum;
	state: OrderStatusesEnum & AdminOrderStatusesEnum;
	paymentMethod: PaymentMethod;
	paymentTerm?: number;
	quantity: number;
	product: ProductOrderInterface;
	pickupLocation: Point;
	pickupAddress?: string;
	deliveryLocation: Point;
	deliveryAddress?: string;
	deliveryIncluded?: boolean;
	createdBy: string;
	createdByUser: UsersInterface;
	assigneeId: string;
	assignee?: UsersInterface;
	assigneeUser?: UsersInterface;
	companyId: number;
	sourceId?: number;
	notificationCount?: number;
	source?: OrderInterface;
	deliveryFrom: string;
	deliveryTo: string;
	createdAt: string;
	updatedAt: string;
	pickupCountryCode?: string;
	deliveryCountryCode?: string;
	notifications?: ResponseNotificationListInterface;
	loadDetails?: DriverLoadDetails;
	producerLoadDetails?: DriverLoadDetails;
	unloadDetails?: DriverLoadDetails;
	truck?: FleetDto;
	trucks?: Array<TruckDto>;
	priceForProducer?: number;
	priceForProducerWithVat?: number;
	producer?: UsersInterface;
	priceForTransporter?: number;
	priceForTransporterWithVat?: number;
	transportPriceForClient?: number;
	productPriceForClient?: number;
	productPriceForClientWithVat?: number;
	transportPriceForClientWithVat?: number;
	transportVat?: number;
	transporterPricePerKm?: number;
	transporterStartPrice?: number;
	distance?: number;
	locationPhoto?: string;
	solutions?: TransportSolutions[];
}

export enum TransporterNotificationsEnums {
	ORDER_ASSIGNED = 'order_assigned',
	ORDER_ACCEPTED = 'order_accepted',
	DRIVER_ALLOCATED = 'driver_allocated',
	ORDER_ACCEPTED_BY_DRIVER = 'order_accepted_by_driver',
}

export enum AdminNotificationsEnums {
	ORDER_CONFIRMED = 'order_confirmed',
	ORDER_ACCEPTED_BY_TRANSPORTER = 'order_accepted_by_transporter',
	ORDER_ACCEPTED_BY_DRIVER = 'order_accepted_by_driver',
	ORDER_SEND_TO_DRIVER = 'order_send_to_driver',
	ORDER_SENT_TO_PRODUCER = 'order_send_to_producer',
	ORDER_ACCEPTED_BY_PRODUCER = 'order_accepted_by_producer',
	ORDER_PRODUCER_LOADING_GOODS = 'order_producer_loading_goods',
	TRANSPORTER_IGNORE_ISSUE = 'transporter_ignore_issue',
	TRANSPORTER_RESOLVE_ISSUE = 'transporter_resolve_issue',
}

export enum CommonNotificationsEnums {
	ORDER_START_TRIP_TO_LOADING = 'order_start_trip_to_loading',
	ORDER_ARRIVED_TO_LOADING = 'order_arrived_to_loading',
	ORDER_START_LOADING_GOODS = 'order_start_loading_goods',
	ORDER_CONFIRM_LOADING_GOODS = 'order_confirm_loading_goods',
	ORDER_START_TRIP_TO_UNLOADING = 'order_start_trip_to_unloading',
	ORDER_ARRIVED_TO_UNLOADING = 'order_arrived_to_unloading',
	ORDER_START_UNLOADING_GOODS = 'order_start_unloading_goods',
	ORDER_CONFIRM_UNLOADING_GOODS = 'order_confirm_unloading_goods',
	ORDER_PRODUCER_LOADING_GOODS = 'order_producer_loading_goods',
	ORDER_COMPLETE = 'order_complete',
	ORDER_PAID = 'order_paid',
}
