import React, { useEffect, useMemo, useRef, useState } from 'react';
import OrderDetailsStepper from '../../../../../../components/OrderDetailsStepper/OrderDetailsStepper';
import TruckSchedule from '../../AdditionalDetails/TruckSchedule';
import PickUpDeliveryDetails from '../../../../../Admin/OrderDetails/components/AdditionalDetails/PickUp&DeliveryDetails';
import BaseOrderDetails from '../../../../../../components/BaseOrderDetails/BaseOrderDetails';
import TruckDetailsMap from '../../../../../Admin/OrderDetails/components/AdditionalDetails/TruckDetailsMap';
import { TransporterOrderProps } from '../../../transporterOrder.interface';
import DriverIssueBlock from '../../DriverIssueBlock/DriverIssueBlock';
import AllocateTruckTable from '../../AllocateTruckTable/AllocateTruckTable';
import { DriverDto } from '../../../../../../services/truck.services';
import { T } from '@tolgee/react';
import { SypacText } from '@sypac/component-library-react';

const CommonStep: React.FC<TransporterOrderProps> = (props) => {
	const { selectedOrder, currentStep } = props;
	const [stepperMargin, setStepperMargin] = useState<number>(0);
	const boxRef = useRef<HTMLDivElement>(null);
	const [boxHeight, setBoxHeight] = useState<number>(0);
	const [resolvingIssue, setResolvingIssue] = useState<boolean>(false);

	const [changingTruck, setChangingTruck] = useState<boolean>(false);
	const [allocatedTruck, setAllocatedTruck] = useState<DriverDto | undefined>(
		undefined,
	);

	const driverIssue = useMemo(() => {
		return selectedOrder?.trucks?.[0].issues?.[0];
	}, [selectedOrder?.trucks]);

	useEffect(() => {
		const element = boxRef.current;
		const resizeObserver = new ResizeObserver((entries) => {
			for (let entry of entries) {
				const firstChild = entry.target.childNodes[0] as HTMLElement;
				if (firstChild && firstChild.offsetHeight) {
					setBoxHeight(firstChild.offsetHeight);
				}
			}
		});

		if (element) {
			resizeObserver.observe(element);
		}

		return () => {
			if (element) {
				resizeObserver.unobserve(element);
			}
		};
	}, [selectedOrder]);

	const handleWidthChange = (value: number) => {
		setStepperMargin(value);
	};

	const handleIssue = (value: boolean) => {
		setResolvingIssue(value);
	};

	const handleChangingTruck = (value: boolean) => {
		setChangingTruck(value);
	};

	const allocateTruck = (value: DriverDto) => {
		setAllocatedTruck(value);
		setChangingTruck(false);
	};

	return (
		<>
			<div style={{ marginBottom: `${stepperMargin}px` }}>
				<OrderDetailsStepper
					current={currentStep!}
					simpleOrder={selectedOrder?.trucks?.length === 1}
					order={selectedOrder}
					onWidthChange={handleWidthChange}
				/>
			</div>

			{!resolvingIssue && currentStep! > 3 && currentStep! < 10 && (
				<div className="px-10 pt-7 pb-3.5">
					<TruckDetailsMap selectedOrder={selectedOrder} />
				</div>
			)}

			{resolvingIssue && !changingTruck ? (
				<>
					<div className="px-10 pt-7 pb-3.5">
						{allocatedTruck ? (
							<SypacText variant="body-regular-medium" className="pb-6">
								<p className="text-gray-60">
									<T keyName="driverIssueBlock.changeTruckForThisOrder">
										Change truck for this order
									</T>
								</p>
							</SypacText>
						) : null}

						<TruckSchedule
							selectedOrder={selectedOrder}
							allocatedTruck={allocatedTruck}
						/>
					</div>

					{allocatedTruck ? (
						<div className="px-10 py-3.5">
							<TruckDetailsMap
								selectedOrder={selectedOrder}
								allocatedTruck={allocatedTruck}
							/>
						</div>
					) : null}
				</>
			) : resolvingIssue && changingTruck ? (
				<AllocateTruckTable
					driverIssue={driverIssue}
					allocateTruck={allocateTruck}
				/>
			) : null}

			{!resolvingIssue && (
				<div
					className={`px-10 ${
						currentStep! > 3 && currentStep! < 10 ? 'py-3.5' : 'pt-7 pb-3.5'
					}`}
				>
					{currentStep === 7 ? (
						<PickUpDeliveryDetails
							selectedOrder={selectedOrder}
							target={selectedOrder?.target}
							isPickUp
							simpleOrder={selectedOrder?.trucks?.length === 1}
						/>
					) : (
						<TruckSchedule selectedOrder={selectedOrder} />
					)}
				</div>
			)}

			{!resolvingIssue && (
				<div className="px-10 py-3.5">
					{currentStep === 7 ? (
						<TruckSchedule selectedOrder={selectedOrder} />
					) : (
						<PickUpDeliveryDetails
							selectedOrder={selectedOrder}
							target={selectedOrder?.target}
							isPickUp={![8, 9, 10].includes(currentStep!)}
							simpleOrder={selectedOrder?.trucks?.length === 1}
						/>
					)}
				</div>
			)}

			{!resolvingIssue && (
				<div className="px-10 py-3.5">
					<PickUpDeliveryDetails
						selectedOrder={selectedOrder}
						target={selectedOrder?.target}
						isPickUp={[8, 9, 10].includes(currentStep!)}
						simpleOrder={selectedOrder?.trucks?.length === 1}
					/>
				</div>
			)}

			{!resolvingIssue && (
				<div className="px-10 pb-7 pt-3.5">
					<BaseOrderDetails
						selectedOrder={selectedOrder}
						isOpen
						simpleOrder={selectedOrder?.trucks?.length === 1}
						deliveryIncluded={selectedOrder?.deliveryIncluded!}
						target={selectedOrder?.target!}
					/>
				</div>
			)}

			{driverIssue?.status === 'created' && !changingTruck ? (
				<div ref={boxRef} style={{ marginTop: `${boxHeight}px` }}>
					<DriverIssueBlock
						selectedOrder={selectedOrder}
						resolvingIssue={handleIssue}
						changingTruck={handleChangingTruck}
						allocatedTruck={allocatedTruck}
						allocateAgain={setAllocatedTruck}
					/>
				</div>
			) : null}
		</>
	);
};

export default CommonStep;
