import React, { useCallback, useEffect, useState } from 'react';
import { T } from '@tolgee/react';
import { AllocateTruckTableProps } from './AllocateTruckTable.interface';
import {
	SypacBadge,
	SypacBox,
	SypacButton,
	SypacCheckbox,
	SypacImage,
	SypacText,
} from '@sypac/component-library-react';
import HideMap from '../../../../../assets/HideMap';
import { MapIcon } from '../../../../../assets/MapIcon';
import Map from '../../../../../components/Map/Map';
import { Search } from '../../../../../assets/Search';
import {
	MapMarker,
	PinVariant,
} from '../../../../../components/Map/Map.interface';
import {
	DriverDto,
	FleetQueryParams,
	TruckService,
} from '../../../../../services/truck.services';
import { LIMIT } from '../../../../../constants';
import AlertSmall from '../../../../../assets/AlertSmall';

const TrackStatusMap: Record<string, { color: string; label: string }> = {
	available: { color: 'green', label: 'Available' },
	not_available: { color: 'yellow', label: 'In Progress' },
	returning: { color: 'primary-violet', label: 'Returning' },
	issue: { color: 'red', label: 'Issue reported' },
};

const AllocateTruckTable: React.FC<AllocateTruckTableProps> = (props) => {
	const { driverIssue, allocateTruck } = props;
	const [fleet, setFleet] = useState<any>();
	const [page, setPage] = useState<number>(0);
	const [currentStatus, setCurrentStatus] = useState<string>('');
	const [activeTruck, setActiveTruck] = useState<number>();
	const [isMapOpen, setIsMapOpen] = useState<boolean>(true);
	const [markers, setMarkers] = useState<MapMarker[]>([]);
	const [loadingMap, setLoading] = useState<boolean>(false);

	const getFleetCallback = useCallback(async () => {
		try {
			let query: FleetQueryParams = {
				offset: page * LIMIT,
				limit: LIMIT,
				status: currentStatus,
			};
			const { data } = await TruckService.getTrucks(query, 'transporter');
			setFleet(data.items as unknown as DriverDto[]);
		} catch (e) {}
	}, [currentStatus, page]);

	useEffect(() => {
		getFleetCallback().then(() => {});
	}, [getFleetCallback, currentStatus]);

	const toggleMap = () => {
		setIsMapOpen(!isMapOpen);
	};

	const setCurrentTab = (status: string) => {
		setPage(0);
		setCurrentStatus(status);
	};

	const handleRowClick = useCallback(
		(idx: number) => {
			setActiveTruck(idx);
			const selectedTruck = fleet?.find((_: any, i: number) => i === idx);
			if (selectedTruck && selectedTruck?.id) {
				setLoading(true);
				setMarkers([
					{
						latitude: selectedTruck?.lastLocation?.coordinates[1] || 47.04014,
						longitude: selectedTruck?.lastLocation?.coordinates[0] || 28.80058,
						variant: PinVariant.truck,
						truck: selectedTruck,
					},
				]);
				setTimeout(() => {
					setLoading(false);
				}, 300);
			}
		},
		[fleet],
	);

	const handleCheckboxChange = (idx: number) => {
		if (activeTruck === idx) {
			setActiveTruck(undefined);
			markersMap();
		} else {
			setActiveTruck(idx);
			handleRowClick(idx);
		}
	};

	const markersMap = useCallback(() => {
		if (fleet !== undefined) {
			setLoading(true);
			const newMarkers = fleet.map(
				(r: { lastLocation: { coordinates: any[] } }) => ({
					latitude: r?.lastLocation?.coordinates[1] || 47.04014,
					longitude: r?.lastLocation?.coordinates[0] || 28.80058,
					variant: PinVariant.truck,
					truck: r,
				}),
			);
			setMarkers(newMarkers);
			setTimeout(() => {
				setLoading(false);
			}, 300);
		}
	}, [fleet]);

	useEffect(() => {
		markersMap();
	}, [fleet, markersMap]);

	const tableRow = (item: DriverDto, idx: number) => {
		const isIssue = item.id === driverIssue?.oldDriverData.id;
		const { color, label } =
			TrackStatusMap[item.status] || TrackStatusMap['not_available'];
		const bgColor = isIssue ? TrackStatusMap['issue'].color : color;
		const badgeLabel = isIssue ? TrackStatusMap['issue'].label : label;

		return (
			<tr
				key={item.id}
				className={`border border-solid border-l-0 border-r-0 border-b-gray-10 border-t-gray-10 transition ${
					isIssue ? '' : 'hover:bg-gray-10-opacity-50 cursor-pointer'
				}`}
				onClick={!isIssue ? () => handleCheckboxChange(idx) : undefined}
			>
				<td className="p-2 border border-solid border-r-gray-10 border-l-0 border-t-0 border-b-0">
					{isIssue ? (
						<AlertSmall color="#F44A77" />
					) : (
						<SypacCheckbox
							size="md"
							className="flex items-center justify-center"
						>
							<input
								type="checkbox"
								checked={activeTruck === idx}
								onChange={() => handleCheckboxChange(idx)}
								className="m-0 border-gray-10 cursor-pointer"
							/>
						</SypacCheckbox>
					)}
				</td>
				<td className="p-2 border border-solid border-r-gray-10 border-l-0 border-t-0 border-b-0">
					{bgColor && badgeLabel && (
						<SypacBadge color={bgColor} outlined={false}>
							<div className="text-xs font-medium whitespace-nowrap min-w-[74px] flex justify-center">
								<T keyName={`acceptedStep.${badgeLabel}`}>{badgeLabel}</T>
							</div>
						</SypacBadge>
					)}
				</td>
				{['licensePlates', 'name'].map((field, i) => (
					<td
						key={i}
						className={`px-4 py-2 border border-solid border-r-gray-10 border-l-0 border-t-0 border-b-0 ${
							isIssue ? 'opacity-30' : ''
						}`}
					>
						{/*@ts-ignore*/}
						{item[field] || '-'}
					</td>
				))}
				<td className={`p-2 ${isIssue ? 'opacity-40' : ''}`}>
					<div className="flex gap-2 rounded-md items-center py-1 px-2 bg-gray-10-opacity-50">
						<SypacImage className="w-[47px] h-[18px]">
							<img src="/tipper.svg" alt={item.type?.split('_').join(' ')} />
						</SypacImage>

						<div className="flex gap-1">
							<SypacText variant="overline-regular-large">
								<p className="text-xs text-gray-80 capitalize whitespace-nowrap">
									{item.type?.split('_').join(' ')}
								</p>
							</SypacText>
							<div className="text-xs text-gray-80">/</div>
							<SypacText variant="overline-regular-large">
								<p className="text-xs text-gray-80 capitalize whitespace-nowrap">
									{`Payload: ${item.truckPayload} tons`}
								</p>
							</SypacText>
						</div>
					</div>
				</td>
			</tr>
		);
	};

	return (
		<div>
			<div className="flex flex-col px-10 pt-7 pb-3.5 h-[calc(100vh-296px)]">
				<div className="flex justify-between">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-60">
							<T keyName="driverIssueBlock.changeTruckForThisOrder">
								Change truck for this order
							</T>
						</p>
					</SypacText>

					<div className="flex items-center gap-2">
						<span className="-ml-[2px] mr-[2px] scale-[1.2]">
							{isMapOpen ? <HideMap /> : <MapIcon />}
						</span>
						<SypacButton variant="secondary" size="small">
							<button
								className="w-full border-none bg-transparent p-0 group"
								type="button"
								onClick={toggleMap}
							>
								<SypacText variant="body-normal-medium">
									<p className="transition group-hover:text-gray-80 group-hover:-translate-y-[3px]">
										{isMapOpen ? (
											<T keyName="acceptedStep.hideMap">Hide map</T>
										) : (
											<T keyName="acceptedStep.showMap">Show map</T>
										)}
									</p>
								</SypacText>
							</button>
						</SypacButton>
					</div>
				</div>

				{isMapOpen ? (
					<div
						className={`w-full h-[65%] pt-6 pb-4 ${
							loadingMap ? 'bg-gray-1' : 'bg-transparent'
						}`}
					>
						{!loadingMap ? <Map markers={markers} /> : null}
					</div>
				) : null}

				<div className="flex gap-[3px] p-3 mt-3 bg-white border border-solid border-gray-10 border-b-0 rounded-t-[10px]">
					<SypacButton variant="secondary" size="small" className="w-full">
						<button
							className={`w-full h-[40px] rounded-lg flex justify-center items-center transition hover:border-gray-20 ${
								currentStatus === ''
									? 'border-kimberly'
									: 'border-transparent text-gray-80'
							}`}
							onClick={() => setCurrentTab('')}
						>
							<SypacText variant="body-regular-medium">
								<p>
									<T keyName="acceptedStep.tableTabAll">All</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
					<SypacButton variant="secondary" size="small" className="w-full">
						<button
							className={`w-full h-[40px] rounded-lg flex gap-3 transition hover:border-gray-20 ${
								currentStatus === 'available'
									? 'border-kimberly'
									: 'border-transparent text-gray-80'
							}`}
							onClick={() => setCurrentTab('available')}
						>
							<div className="w-[8px] h-[8px] rounded-full bg-mountain-meadow" />
							<SypacText variant="body-regular-medium">
								<p>
									<T keyName="acceptedStep.tableTabAvailable">Available</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
					<SypacButton variant="secondary" size="small" className="w-full">
						<button
							className={`w-full h-[40px] rounded-lg flex gap-3 transition hover:border-gray-20 ${
								currentStatus === 'not_available'
									? 'border-kimberly'
									: 'border-transparent text-gray-80'
							}`}
							onClick={() => setCurrentTab('not_available')}
						>
							<div className="w-[8px] h-[8px] rounded-full bg-texas-rose" />
							<SypacText variant="body-regular-medium">
								<p className="whitespace-nowrap">
									<T keyName="acceptedStep.tableTabInProgress">In Progress</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
					<SypacButton variant="secondary" size="small" className="w-full">
						<button
							className={`w-full h-[40px] rounded-lg flex gap-3 transition hover:border-gray-20 ${
								currentStatus === 'returning'
									? 'border-kimberly'
									: 'border-transparent text-gray-80'
							}`}
							onClick={() => setCurrentTab('returning')}
							disabled={true}
						>
							<div className="w-[8px] h-[8px] rounded-full bg-primary-violet/30" />
							<SypacText variant="body-regular-medium">
								<p>
									<T keyName="acceptedStep.tableTabReturning">Returning</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
				</div>

				<div className="bg-white h-full overflow-x-hidden overflow-y-auto border border-solid border-gray-10 border-t-0 border-b-0 mb-[1px]">
					<table className="w-full text-sm text-left border-collapse">
						<thead>
							<tr className="whitespace-nowrap [&_p]:text-gray-40" key="thead">
								<th
									scope="col"
									className="px-4 py-3 border border-solid border-r-gray-10 border-l-0 border-t-gray-10 border-b-gray-10"
								/>
								<th
									scope="col"
									className="px-4 py-3 border border-solid border-r-gray-10 border-l-0 border-t-gray-10 border-b-gray-10"
								>
									<SypacText variant="body-regular-small">
										<p>
											<T keyName="acceptedStep.status">Status</T>
										</p>
									</SypacText>
								</th>
								<th
									scope="col"
									className="px-4 py-3 border border-solid border-r-gray-10 border-l-0 border-t-gray-10 border-b-gray-10"
								>
									<SypacText variant="body-regular-small">
										<p>
											<T keyName="acceptedStep.truckPlates">Truck plates</T>
										</p>
									</SypacText>
								</th>
								<th
									scope="col"
									className="px-4 py-3 border border-solid border-r-gray-10 border-l-0 border-t-gray-10 border-b-gray-10"
								>
									<SypacText variant="body-regular-small">
										<p>
											<T keyName="acceptedStep.truckName">Truck name</T>
										</p>
									</SypacText>
								</th>
								<th
									scope="col"
									className="px-4 py-3 border border-solid border-r-0 border-l-0 border-t-gray-10 border-b-gray-10"
								>
									<SypacText variant="body-regular-small">
										<p>
											<T keyName="acceptedStep.truckType">Truck type</T>
										</p>
									</SypacText>
								</th>
							</tr>
						</thead>
						<tbody>
							{fleet !== undefined ? (
								fleet?.map(tableRow)
							) : (
								<tr key="opened">
									<td colSpan={5}>
										<div className="flex flex-col items-center mt-[75px] gap-8">
											<Search />
											<SypacText variant="body-normal-small">
												<p className="text-gray-50">
													<T keyName="acceptedStep.notImplementedYetDescription">
														Lorem ipsum dolor sit amet, consectetur adipiscing
														elit.
													</T>
												</p>
											</SypacText>
										</div>
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>

				<SypacBox
					hover={false}
					boxColor="primary"
					className="fixed z-50 bottom-0 pb-6 w-[621px] h-fit bg-white box-border border-none rounded-none"
				>
					<div className="border border-solid border-gray-10 rounded-b-[10px]">
						<div className="flex gap-4 items-center justify-center p-2">
							<div className="w-full">
								<SypacButton variant="primary" size="large" className="w-full">
									<button
										className="w-full transition"
										disabled={activeTruck === undefined}
										onClick={() => {
											if (activeTruck === undefined || !fleet) return;
											const item = fleet[activeTruck];
											allocateTruck && allocateTruck(item);
										}}
									>
										<SypacText variant="body-regular-medium">
											<p className="text-white">
												<T keyName="driverIssueBlock.nextStep">Next step</T>
											</p>
										</SypacText>
									</button>
								</SypacButton>
							</div>
						</div>
					</div>
				</SypacBox>
			</div>
		</div>
	);
};

export default AllocateTruckTable;
