import React, { useMemo, useState } from 'react';
import OrderDetailsStepper from '../../../../../../components/OrderDetailsStepper/OrderDetailsStepper';
import FulfilmentSchedule from '../../AdditionalDetails/FulfilmentSchedule';
import PickUpDeliveryDetails from '../../AdditionalDetails/PickUp&DeliveryDetails';
import BaseOrderDetails from '../../../../../../components/BaseOrderDetails/BaseOrderDetails';
import { AdminOrderProps } from '../../../adminOrder.interface';

const AwaitingTransport: React.FC<AdminOrderProps> = (props) => {
	const { selectedOrder } = props;
	const [stepperMargin, setStepperMargin] = useState<number>(0);

	const isSimpleOrder = useMemo(() => {
		return selectedOrder?.trucks?.length === 1;
	}, [selectedOrder?.trucks]);
	const deliveryIncluded = useMemo(() => {
		return selectedOrder?.deliveryIncluded;
	}, [selectedOrder?.deliveryIncluded]);

	const handleWidthChange = (value: number) => {
		setStepperMargin(value);
	};

	return (
		<>
			<div style={{ marginBottom: `${stepperMargin}px` }}>
				<OrderDetailsStepper
					current={2}
					simpleOrder={isSimpleOrder}
					order={selectedOrder}
					onWidthChange={handleWidthChange}
				/>
			</div>

			<div className="px-10 pt-7 pb-3.5">
				{deliveryIncluded ? (
					<FulfilmentSchedule
						selectedOrder={selectedOrder}
						step={2}
						deliveryIncluded={deliveryIncluded!}
						simpleOrder={isSimpleOrder}
					/>
				) : (
					<PickUpDeliveryDetails
						selectedOrder={selectedOrder}
						target={selectedOrder?.target}
						isPickUp={true}
						simpleOrder={isSimpleOrder}
					/>
				)}
			</div>

			<div className="px-10 py-3.5">
				{deliveryIncluded && isSimpleOrder ? (
					<PickUpDeliveryDetails
						selectedOrder={selectedOrder}
						target={selectedOrder?.target}
						isPickUp={true}
						simpleOrder={isSimpleOrder}
					/>
				) : (
					<PickUpDeliveryDetails
						selectedOrder={selectedOrder}
						target={selectedOrder?.target}
						isPickUp={false}
						simpleOrder={isSimpleOrder}
					/>
				)}
			</div>

			<div className="px-10 py-3.5">
				{isSimpleOrder && deliveryIncluded ? (
					<PickUpDeliveryDetails
						selectedOrder={selectedOrder}
						target={selectedOrder?.target}
						isPickUp={false}
						simpleOrder={isSimpleOrder}
					/>
				) : !deliveryIncluded ? (
					<BaseOrderDetails
						selectedOrder={selectedOrder}
						isOpen={true}
						simpleOrder={isSimpleOrder}
						deliveryIncluded={deliveryIncluded!}
						target={selectedOrder?.target!}
					/>
				) : (
					<PickUpDeliveryDetails
						selectedOrder={selectedOrder}
						target={selectedOrder?.target}
						isPickUp={true}
						simpleOrder={isSimpleOrder}
					/>
				)}
			</div>

			<div className="px-10 pb-7 pt-3.5">
				{deliveryIncluded ? (
					<BaseOrderDetails
						selectedOrder={selectedOrder}
						isOpen={true}
						simpleOrder={isSimpleOrder}
						deliveryIncluded={deliveryIncluded!}
						target={selectedOrder?.target!}
					/>
				) : (
					<FulfilmentSchedule
						selectedOrder={selectedOrder}
						step={2}
						deliveryIncluded={deliveryIncluded!}
						simpleOrder={isSimpleOrder}
					/>
				)}
			</div>
		</>
	);
};

export default AwaitingTransport;
