import React, { DragEvent, useState } from 'react';
import {
	SypacButton,
	SypacInput,
	SypacText,
} from '@sypac/component-library-react';
import Modal from 'react-modal';
import { T, useTranslate } from '@tolgee/react';
import Close from '../../../../../assets/Close';
import FolderIcon from '../../../../../assets/FolderIcon';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ChevronRightIcon } from '../../../../../assets/ChevronRightIcon';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { ProductService } from '../../../../../services/product.services';
import { COUNTRIES } from '../../../../../components/DropDownOption/countries.constant';
import Flag from 'react-world-flags';
import { toastVariant } from '../../../../../components/CompaniesTable/toastVariant/toastVariant';
import { FileInterface } from '../../../../../components/CompanyVerification/components/CompanyDocuments/CompanyDocuments.interface';
import { FileService } from '../../../../../services/file.service';
import BouncingLoader from '../../../../../components/BouncingLoader/BouncingLoader';
import { ModalAddProductProps } from './ModalAddProduct.interface';
import { useGetCategories } from '../../../../../hooks/use-get-categories';

const MAX_FILE_SIZE_MB: number = 2;

const ModalAddProduct: React.FC<ModalAddProductProps> = ({
	isOpen,
	onClose,
}) => {
	const { t } = useTranslate();
	const [groups] = useGetCategories({}, 'admin');
	const [activeGroup, setActiveGroup] = useState<string>('');
	const [categories] = useGetCategories(
		{
			parentIds: [activeGroup!],
		},
		'admin',
	);
	const [file, setFile] = useState<FileInterface | undefined>(undefined);
	const [fileLoading, setFileLoading] = useState<boolean>(false);
	const [categoryDropdownOpen, setCategoryDropdownOpen] =
		useState<boolean>(false);
	const [groupDropdownOpen, setGroupDropdownOpen] = useState<boolean>(false);
	const [countryDropdownOpen, setCountryDropdownOpen] =
		useState<boolean>(false);

	const handleCloseModal = () => {
		formik.resetForm();
		handleDeleteFile();
		onClose();
	};

	const formik = useFormik({
		initialValues: {
			productCategory: '',
			productGroup: '',
			size: '',
			type: '',
			productName: '',
			countryStore: '',
		},
		onSubmit: async (values) => {
			const body = {
				name: values.productName,
				categoryId: values.productCategory,
				photoUrl: file?.file?.name,
				countryCode: values.countryStore,
				size: values.size,
				type: values.type,
				unit: 'ton',
				translations: [
					{
						language: 'en',
						name: values.productName,
					},
				],
			};
			await ProductService.createProductAdmin(body);
			handleCloseModal();
		},
		validationSchema: Yup.object({
			productCategory: Yup.string().required(
				t(
					'modalAddProduct.productCategoryRequired',
					'Please select a product category',
				),
			),
			productGroup: Yup.string().required(
				t(
					'modalAddProduct.productGroupRequired',
					'Please select a product group',
				),
			),
			productName: Yup.string().required(
				t(
					'modalAddProduct.productNameRequired',
					'Please enter the product name',
				),
			),
			countryStore: Yup.string().required(
				t(
					'modalAddProduct.countryStoreRequired',
					'Please select the store country',
				),
			),
			size: Yup.string(),
			type: Yup.string(),
		}),
	});

	const getDataFiles = async (file: File) => {
		setFileLoading(true);
		try {
			const { data } = await FileService.uploadFiles([file]);
			const newFile = data.items[0];
			const [name, type] = newFile.originalName?.split('.') || [];
			const mimeType =
				newFile.type?.split('/').pop()?.toUpperCase() ||
				type?.toUpperCase() ||
				'';
			const size = `${(file.size / (1024 * 1024)).toFixed(1)} MB`;

			setFile({ name, mimeType, size, file: newFile });
		} catch (e) {
		} finally {
			setFileLoading(false);
		}
	};

	const handleDrag = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const handleDrop = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
		if (e.dataTransfer.files && e.dataTransfer.files.length) {
			const droppedFile = e.dataTransfer.files[0];
			if (droppedFile.size / (1024 * 1024) > MAX_FILE_SIZE_MB) {
				toastVariant(
					`File size exceeds ${MAX_FILE_SIZE_MB} MB. Please upload a smaller file.`,
					true,
				);
				return;
			}
			getDataFiles(droppedFile).then(() => {});
		}
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		if (e.target.files && e.target.files.length) {
			const selectedFile = e.target.files[0];
			if (selectedFile.size / (1024 * 1024) > MAX_FILE_SIZE_MB) {
				toastVariant(
					`File size exceeds ${MAX_FILE_SIZE_MB} MB. Please upload a smaller file.`,
					true,
				);
				return;
			}
			getDataFiles(selectedFile).then(() => {});
		}
	};

	const handleDeleteFile = () => {
		setFile(undefined);
	};

	const handleCategoryOutsideClick = () => {
		if (categoryDropdownOpen && !formik.values.productCategory) {
			formik.setFieldTouched('productCategory', true);
			formik.validateField('productCategory');
		}
		setCategoryDropdownOpen(false);
	};

	const handleGroupOutsideClick = () => {
		if (groupDropdownOpen && !formik.values.productGroup) {
			formik.setFieldTouched('productGroup', true);
			formik.validateField('productGroup');
		}
		setGroupDropdownOpen(false);
	};

	const handleCountryStoreOutsideClick = () => {
		if (countryDropdownOpen && !formik.values.countryStore) {
			formik.setFieldTouched('countryStore', true);
			formik.validateField('countryStore');
		}
		setCountryDropdownOpen(false);
	};

	const categoryRef = useDetectClickOutside({
		onTriggered: handleCategoryOutsideClick,
	});
	const groupRef = useDetectClickOutside({
		onTriggered: handleGroupOutsideClick,
	});
	const countryRef = useDetectClickOutside({
		onTriggered: handleCountryStoreOutsideClick,
	});

	const isAddProductDisabled = !file || !formik.isValid;

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={handleCloseModal}
			className="w-[716px] modal-inside outline-none box-border"
			overlayClassName="modal-overlay"
			shouldCloseOnOverlayClick={false}
			ariaHideApp={false}
		>
			<form onSubmit={formik.handleSubmit} className="flex flex-col">
				<div className="flex items-center justify-between p-3">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">
							<T keyName="modalAddProduct.addProduct">Add product</T>
						</p>
					</SypacText>
					<SypacButton variant="subTitle">
						<button
							type="button"
							className="flex justify-center items-center h-[32px] w-[32px] p-0 bg-white border border-solid border-gray-10 transition hover:border-gray-60 rounded-lg"
							onClick={handleCloseModal}
						>
							<Close width="10" height="10" />
						</button>
					</SypacButton>
				</div>
				<div className="border-0 border-solid border-t-[1px] border-gray-10" />

				<div className="flex flex-col gap-3 p-3">
					<div className="flex flex-col gap-3 p-3 border border-solid border-gray-10 rounded-10 bg-alabaster">
						<SypacText variant="body-regular-medium">
							<p className="text-sm text-gray-40">
								<T keyName="modalAddProduct.uploadProductPhoto">
									1 — Upload product photo:
								</T>
							</p>
						</SypacText>

						{!file && !fileLoading ? (
							<div
								onDragEnter={handleDrag}
								onDragLeave={handleDrag}
								onDragOver={handleDrag}
								onDrop={handleDrop}
								className="flex px-5 py-[38px] bg-white border border-dashed border-gray-40 rounded-lg cursor-pointer transition hover:border-gray-50"
							>
								<input
									type="file"
									id="input-file-upload"
									className="hidden"
									multiple={true}
									accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
									onChange={handleChange}
								/>
								<label
									htmlFor="input-file-upload"
									className="w-full flex flex-col gap-6 items-center cursor-pointer"
								>
									<FolderIcon />
									<div className="flex flex-col gap-3 items-center">
										<SypacText variant="body-regular-medium" className="flex">
											<p className="text-gray-80">
												<T keyName="userSettings.dragAndDrop">
													Drag and drop or
												</T>
											</p>
											&nbsp;
											<p className="text-cornflower-blue underline">
												<T keyName="userSettings.browseFile">Browse File</T>
											</p>
										</SypacText>
										<SypacText variant="body-regular-medium">
											<p className="text-xs text-nevada">
												<T keyName="modalAddProduct.weSupport">
													We support JPEG, or PNG, files. Max file size: 2 MB.
												</T>
											</p>
										</SypacText>
									</div>
								</label>
							</div>
						) : !file && fileLoading ? (
							<div className="flex w-full h-[86px] justify-center items-center">
								<BouncingLoader />
							</div>
						) : file && !fileLoading ? (
							<div className="flex items-center mt-4" key={file.name}>
								<div
									style={{
										backgroundImage: `url(${file.file.url})`,
									}}
									className="w-[66px] h-[66px] bg-no-repeat bg-cover bg-center rounded-10 border border-solid border-gray-10 box-border"
								/>
								<div className="flex flex-col ml-4">
									<SypacText variant="body-regular-medium">
										<p className="text-gray-80">{file.name}</p>
									</SypacText>

									<div className="flex gap-2 justify-start items-center mt-1">
										<SypacText variant="overline-regular-large">
											<p className="text-gray-20">{file.mimeType}</p>
										</SypacText>
										<div className="w-[2px] h-[2px] bg-gray-20 rounded-full" />
										<SypacText variant="overline-regular-large">
											<p className="text-gray-20">{file.size}</p>
										</SypacText>
									</div>

									<SypacText
										variant="overline-normal-large"
										className="mt-3 cursor-pointer"
										onClick={handleDeleteFile}
									>
										<p className="text-red">
											<T keyName="uploadDocuments.deleteFile">Delete file</T>
										</p>
									</SypacText>
								</div>
							</div>
						) : null}
					</div>

					<div className="flex flex-col gap-4 p-3 border border-solid border-gray-10 rounded-10 bg-alabaster">
						<SypacText variant="body-regular-medium">
							<p className="text-sm text-gray-40">
								<T keyName="modalAddProduct.selectCategoryAndGroup">
									2 — Select product group and category:
								</T>
							</p>
						</SypacText>

						<div className="grid grid-cols-2 grid-rows-2 gap-3">
							<div className="flex flex-col gap-1 w-full">
								<SypacText variant="body-regular-medium">
									<p className="text-xs text-primary-dark-gray">
										<T keyName="modalAddProduct.productGroup">Product group</T>
										<span className="text-red">*</span>
									</p>
								</SypacText>

								<div ref={groupRef} className="relative">
									<div
										className={`h-[44px] flex justify-between items-center box-border border border-solid bg-white rounded-lg py-2.5 px-3 cursor-pointer transition ${
											groupDropdownOpen
												? 'border-cornflower-blue'
												: formik.touched.productGroup &&
												  formik.errors.productGroup
												? 'border-red-orange'
												: 'border-gray-10 hover:border-gray-30'
										}`}
										onClick={() => setGroupDropdownOpen(true)}
									>
										<SypacText variant="body-normal-small">
											<p
												className={`text-base ${
													formik.values.productGroup
														? 'text-gray-80'
														: 'text-gray-22'
												}`}
											>
												{formik.values.productGroup ? (
													groups?.items.find(
														(group) => group.id === formik.values.productGroup,
													)?.name
												) : (
													<T keyName="modalAddProduct.selectProductGroup">
														Select product group
													</T>
												)}
											</p>
										</SypacText>

										<span
											className={`flex my-auto transition-all mr-[2px] ${
												groupDropdownOpen ? 'transform rotate-90' : ''
											}`}
										>
											<ChevronRightIcon />
										</span>
									</div>

									{groupDropdownOpen && (
										<div className="absolute top-[48px] w-full h-fit max-h-[238px] flex flex-col p-1 border border-solid border-gray-10 rounded-lg shadow-dropdown bg-white z-30 box-border overflow-x-hidden overflow-y-auto">
											{groups?.items.map((group) => (
												<div
													key={group.id}
													className={`no-underline flex items-center gap-3 border-none p-3 rounded-md cursor-pointer hover:bg-gray-10/90 ${
														group.id === formik.values.productGroup
															? 'bg-gray-10-opacity-50'
															: 'bg-white'
													}`}
													onClick={() => {
														formik.setFieldValue('productGroup', group.id);
														setActiveGroup(group.id);
														setGroupDropdownOpen(false);
													}}
												>
													<div
														style={{
															backgroundImage: `url(${group.imageUrl})`,
														}}
														className="w-[52px] h-[52px] bg-no-repeat bg-cover bg-center rounded-10"
													/>

													<SypacText
														variant="body-regular-medium"
														className="mr-auto"
													>
														<p className="text-gray-80">{group.name}</p>
													</SypacText>
												</div>
											))}
										</div>
									)}

									{formik.touched.productGroup && formik.errors.productGroup ? (
										<span className="input-error">
											{formik.errors.productGroup}
										</span>
									) : null}
								</div>
							</div>
							<div className="flex flex-col gap-1 w-full">
								<SypacText variant="body-regular-medium">
									<p className="text-xs text-primary-dark-gray">
										<T keyName="modalAddProduct.productCategory">
											Product category
										</T>
										<span className="text-red">*</span>
									</p>
								</SypacText>

								<div ref={categoryRef} className="relative">
									<div
										className={`h-[44px] flex justify-between items-center box-border border border-solid bg-white rounded-lg py-2.5 px-3 cursor-pointer transition ${
											categoryDropdownOpen
												? 'border-cornflower-blue'
												: formik.touched.productCategory &&
												  formik.errors.productCategory
												? 'border-red-orange'
												: 'border-gray-10 hover:border-gray-30'
										}`}
										onClick={() => setCategoryDropdownOpen(true)}
									>
										<SypacText variant="body-normal-small">
											<p
												className={`text-base ${
													formik.values.productCategory
														? 'text-gray-80'
														: 'text-gray-22'
												}`}
											>
												{formik.values.productCategory ? (
													categories?.items.find(
														(category) =>
															category.id === formik.values.productCategory,
													)?.name
												) : (
													<T keyName="modalAddProduct.selectProductCategory">
														Select product category
													</T>
												)}
											</p>
										</SypacText>

										<span
											className={`flex my-auto transition-all mr-[2px] ${
												categoryDropdownOpen ? 'transform rotate-90' : ''
											}`}
										>
											<ChevronRightIcon />
										</span>
									</div>

									{categoryDropdownOpen && (
										<div className="absolute top-[48px] w-full h-fit max-h-[214px] flex flex-col border border-solid border-gray-10 rounded-lg shadow-dropdown bg-white z-30 box-border overflow-hidden">
											<div className="w-full flex flex-col py-1 pl-1 pr-3 overflow-y-scroll">
												{categories ? (
													categories.items.map((category) => (
														<div
															key={category.id}
															className={`no-underline flex items-center gap-3 border-none p-3 rounded-md cursor-pointer hover:bg-gray-10/90 -mr-[7px] ${
																category.id === formik.values.productCategory
																	? 'bg-gray-10-opacity-50'
																	: 'bg-white'
															}`}
															onClick={() => {
																formik.setFieldValue(
																	'productCategory',
																	category.id,
																);
																setCategoryDropdownOpen(false);
															}}
														>
															<div
																style={{
																	backgroundImage: `url(${category.imageUrl})`,
																}}
																className="w-[52px] h-[52px] bg-no-repeat bg-cover bg-center rounded-10"
															/>

															<SypacText
																variant="body-regular-medium"
																className="mr-auto"
															>
																<p className="text-gray-80">{category.name}</p>
															</SypacText>
														</div>
													))
												) : (
													<div className="h-[40px] flex items-center justify-center">
														<SypacText variant="body-regular-medium">
															<p className="text-gray-80">
																Please select a group first.
															</p>
														</SypacText>
													</div>
												)}
											</div>
										</div>
									)}

									{formik.touched.productCategory &&
									formik.errors.productCategory ? (
										<span className="input-error">
											{formik.errors.productCategory}
										</span>
									) : null}
								</div>
							</div>
							<SypacInput error={!!(formik.touched.size && formik.errors.size)}>
								<SypacText className="mb-1" variant="overline-normal-large">
									<p>
										<T keyName="modalAddProduct.productSize">Product size</T>
									</p>
								</SypacText>
								<input
									className={`w-full py-[11px] pl-3 border rounded-lg placeholder:text-gray-22 placeholder:text-base transition ${
										formik.touched.size && formik.errors.size
											? 'border-red-orange'
											: 'border-gray-10 hover:border-gray-30 focus:border-cornflower-blue'
									}`}
									name="size"
									type="text"
									placeholder={t(
										'modalAddProduct.enterProductSize',
										'Enter product size',
									)}
									value={formik?.values.size}
									onChange={(event) => formik?.handleChange(event)}
								/>
								{formik?.touched.size && formik?.errors.size ? (
									<span className="input-error">{formik.errors.size}</span>
								) : null}
							</SypacInput>
							<SypacInput error={!!(formik.touched.type && formik.errors.type)}>
								<SypacText className="mb-1" variant="overline-normal-large">
									<p>
										<T keyName="modalAddProduct.productType">Product type</T>
									</p>
								</SypacText>
								<input
									className={`w-full py-[11px] pl-3 border rounded-lg placeholder:text-gray-22 placeholder:text-base transition ${
										formik.touched.type && formik.errors.type
											? 'border-red-orange'
											: 'border-gray-10 hover:border-gray-30 focus:border-cornflower-blue'
									}`}
									name="type"
									type="text"
									placeholder={t(
										'modalAddProduct.enterProductType',
										'Enter product type',
									)}
									value={formik?.values.type}
									onChange={(event) => formik?.handleChange(event)}
								/>
								{formik?.touched.type && formik?.errors.type ? (
									<span className="input-error">{formik.errors.type}</span>
								) : null}
							</SypacInput>
						</div>
					</div>

					<div className="flex flex-col gap-3 p-3 border border-solid border-gray-10 rounded-10 bg-alabaster">
						<SypacText variant="body-regular-medium">
							<p className="text-sm text-gray-40">
								<T keyName="modalAddProduct.productNameAndStore">
									3 — Product name and store:
								</T>
							</p>
						</SypacText>

						<div className="flex gap-3">
							<SypacInput
								error={
									!!(formik.touched.productName && formik.errors.productName)
								}
							>
								<SypacText className="mb-1" variant="overline-normal-large">
									<p>
										<T keyName="modalAddProduct.productName">Product name</T>{' '}
										<span className="text-red">*</span>
									</p>
								</SypacText>
								<input
									className={`w-[438px] py-[11px] pl-3 border rounded-lg placeholder:text-gray-22 placeholder:text-base transition ${
										formik.touched.productName && formik.errors.productName
											? 'border-red-orange'
											: 'border-gray-10 hover:border-gray-30 focus:border-cornflower-blue'
									}`}
									name="productName"
									type="text"
									placeholder={t(
										'modalAddProduct.enterProductName',
										'Enter product name',
									)}
									value={formik?.values.productName}
									onChange={(event) => formik?.handleChange(event)}
								/>
								{formik?.touched.productName && formik?.errors.productName ? (
									<span className="input-error">
										{formik.errors.productName}
									</span>
								) : null}
							</SypacInput>

							<div className="flex flex-col gap-1 w-full">
								<SypacText variant="body-regular-medium">
									<p className="text-xs text-primary-dark-gray">
										<T keyName="modalAddProduct.countryStore">Country store</T>
										<span className="text-red">*</span>
									</p>
								</SypacText>

								<div ref={countryRef} className="relative">
									<div
										className={`h-[44px] flex justify-between items-center box-border border border-solid bg-white rounded-lg py-2.5 px-3 cursor-pointer transition ${
											countryDropdownOpen
												? 'border-cornflower-blue'
												: formik.touched.countryStore &&
												  formik.errors.countryStore
												? 'border-red-orange'
												: 'border-gray-10 hover:border-gray-30'
										}`}
										onClick={() => setCountryDropdownOpen(true)}
									>
										<SypacText variant="body-normal-small">
											<p
												className={`text-base ${
													formik.values.countryStore
														? 'text-gray-80'
														: 'text-gray-22'
												}`}
											>
												{formik.values.countryStore ? (
													<div className="flex gap-3 items-center">
														<Flag
															className="w-[20px] h-[14px] object-cover border border-solid border-gray-10 rounded"
															code={
																COUNTRIES.find(
																	(country) =>
																		country['alpha-3'] ===
																		formik.values.countryStore,
																)?.value
															}
															width={24}
															height={16}
														/>
														<SypacText
															variant="body-regular-medium"
															className="mt-1"
														>
															<p className="text-gray-80 max-w-[148px] truncate">
																{
																	COUNTRIES.find(
																		(country) =>
																			country['alpha-3'] ===
																			formik.values.countryStore,
																	)?.label
																}
															</p>
														</SypacText>
													</div>
												) : (
													<T keyName="modalAddProduct.selectCountryStore">
														Select store country
													</T>
												)}
											</p>
										</SypacText>

										<span
											className={`flex my-auto transition-all mr-[2px] ${
												countryDropdownOpen ? 'transform rotate-90' : ''
											}`}
										>
											<ChevronRightIcon />
										</span>
									</div>

									{countryDropdownOpen && (
										<div className="absolute top-[48px] w-full h-fit flex flex-col p-1 border border-solid border-gray-10 rounded-lg shadow-dropdown bg-white z-30 box-border">
											{COUNTRIES.filter(
												(country) =>
													country.value === 'PL' || country.value === 'MD',
											).map((country) => (
												<div
													key={country.value}
													className={`no-underline flex items-center gap-3 border-none p-3 rounded-md cursor-pointer hover:bg-gray-10/90 ${
														country['alpha-3'] === formik.values.countryStore
															? 'bg-gray-10-opacity-50'
															: 'bg-white'
													}`}
													onClick={() => {
														formik.setFieldValue(
															'countryStore',
															country['alpha-3'],
														);
														setCountryDropdownOpen(false);
													}}
												>
													<Flag
														className="w-[20px] h-[14px] object-cover border border-solid border-gray-10 rounded"
														code={country.value}
														width={24}
														height={16}
													/>
													<SypacText
														variant="body-regular-medium"
														className="mr-auto"
													>
														<p className="text-gray-80 max-w-[148px] truncate">
															{country.label}
														</p>
													</SypacText>
												</div>
											))}
										</div>
									)}

									{formik.touched.countryStore && formik.errors.countryStore ? (
										<span className="input-error">
											{formik.errors.countryStore}
										</span>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="flex w-full py-2.5 xl-2xl:py-3 gap-3 [&_button]:transition border border-solid border-gray-10 border-b-0 border-l-0 border-r-0 rounded-xl mt-auto">
					<SypacButton
						variant="secondary"
						size="small"
						className="w-full pl-2.5 xl-2xl:pl-3"
					>
						<button
							type="reset"
							className="w-full py-2.5 rounded-lg"
							onClick={handleCloseModal}
						>
							<SypacText variant="body-regular-medium">
								<p>
									<T keyName="modalAddProduct.back">Back</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
					<SypacButton
						variant="primary"
						size="small"
						className="w-full pr-2.5 xl-2xl:pr-3"
					>
						<button
							type="submit"
							className="w-full py-2.75 rounded-lg"
							disabled={isAddProductDisabled}
						>
							<SypacText variant="body-regular-medium">
								<p className="text-white">
									<T keyName="modalAddProduct.addProduct">Add product</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
				</div>
			</form>
		</Modal>
	);
};

export default ModalAddProduct;
