import React, { useCallback, useEffect, useState } from 'react';
import { SypacInput, SypacText } from '@sypac/component-library-react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { Geo } from '../../SearchLocation/locations.interface';
import useDebounce from '../../../hooks/useDebounce';
import { GeoService } from '../../../services/geo.services';
import Flag from 'react-world-flags';
import { useTranslate } from '@tolgee/react';
import { LocationInputProps } from '../ProductLocation.interface';

export const LocationInput = ({
	setLocation,
	placeholder,
	className,
	defaultValue,
	role,
	errorLocation,
}: LocationInputProps) => {
	const { t } = useTranslate();
	const [expandResults, setExpandResults] = useState<boolean>(false);
	const [search, setSearch] = useState<string>('');
	const [locations, setLocations] = useState<Geo[]>([]);
	const searchQuery = useDebounce(search, 500);
	const onSelectLocation = (location: Geo) => {
		const input = document.getElementById('location') as HTMLInputElement;
		setLocation(location);
		setExpandResults(false);
		setSearch(location.label);
		input.value = location.label;
	};

	const renderDisplayLocation = (location: Geo) => {
		const label = location.lat
			? location.label
			: [
					[location?.address?.street, location?.address?.houseNumber].join(
						' ',
					) || '',
					location?.address?.postalCode || '',
					location?.address?.city || '',
					location?.address?.country || '',
			  ]
					.filter((r) => r)
					.join(', ');

		return (
			<div
				className="flex items-center hover:bg-gray-10-opacity-50 rounded-md cursor-pointer mr-[9px] 3xl:-mr-[7px]"
				onClick={() => onSelectLocation(location)}
			>
				<div className="mx-5">
					<Flag
						className="rounded-[3px]"
						code={location.countryCode.toLocaleUpperCase()}
						width={22}
						height={16}
					/>
				</div>
				<SypacText variant="body-normal-medium" className="my-[15px]">
					<p>{label}</p>
				</SypacText>
			</div>
		);
	};

	const getResult = useCallback(async () => {
		try {
			let newLocations: Geo[] = [];
			if (role === 'producer') {
				const respAddresses = await GeoService.getAddresses({
					search: searchQuery,
					limit: 10,
					offset: 0,
				});
				newLocations =
					respAddresses?.data?.items?.map((r) => ({
						matchLevel: '1',
						label: r.address,
						countryCode: r.country,
						lat: r.lat,
						long: r.long,
					})) || [];
			}
			if (searchQuery?.length) {
				const resp = await GeoService.getLocations({
					phrase: searchQuery,
					maxResults: '10',
				});
				newLocations = [...newLocations, ...resp.data];
			}
			setLocations(newLocations);
		} catch (e) {
			console.log(e);
		}
	}, [role, searchQuery]);

	useEffect(() => {
		getResult().then(() => {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchQuery]);

	const outsideRef = useDetectClickOutside({
		onTriggered: () => setExpandResults(false),
	});

	const setSearchValue = (value: string) => {
		setSearch(value);
		if (!value) {
			setLocation(undefined);
		}
	};

	return (
		<div className="relative" ref={outsideRef}>
			<SypacInput error={!!errorLocation}>
				<input
					autoComplete="off"
					id="location"
					name="location"
					type="text"
					className={`${
						'w-full py-2.5 px-3 rounded-lg placeholder:text-gray-22' ||
						className
					}`}
					placeholder={
						placeholder ||
						t('locationInput.enterLoadingPoint', 'Enter loading point')
					}
					onFocus={() => setExpandResults(true)}
					value={search || defaultValue}
					onChange={(event) => {
						setSearchValue(event.target.value);
					}}
				/>
			</SypacInput>

			<div
				className={`absolute top-[48px] w-full flex-col border border-solid border-gray-10 shadow-dropdown rounded-lg max-h-72 overflow-hidden bg-white z-50 ${
					locations.length && expandResults ? 'flex' : 'hidden'
				}`}
			>
				<div className="flex flex-col w-full p-1 pr-3 overflow-y-scroll">
					{locations.map((location: Geo, index) => (
						<React.Fragment key={location.locationId || index}>
							{renderDisplayLocation(location)}
						</React.Fragment>
					))}
				</div>
			</div>
		</div>
	);
};
