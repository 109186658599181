import Api from '../axios/api';

export interface TruckResponse {
	count: number;
	items: DriverDto[];
}
interface PostTruckResponse {
	data: DriverDto;
}

interface DeleteTruckResponse {
	success: boolean;
}

interface ResponseTruckListResponse {
	data: TruckResponse;
}

export interface DriverDto {
	id: number;
	name: string;
	type: string;
	truckModel: string;
	truckYear: number;
	truckColor: string;
	truckNumber: string;
	truckBrand: string;
	truckPhoto: string;
	licensePlates: string;
	emissionType: string;
	driverFullName: string;
	driverAvatar: string;
	driverEmail: string;
	driverPhone: string;
	companyId: number;
	code: string;
	uid: string;
	status: string;
	createdAt: string;
	updatedAt: string;
	truckPayload: number;
	lastAddress?: string;
	qrCode?: string;
	qrPdf?: string;
	qrCodeUrl?: string;
	qrPdfUrl?: string;
	rating?: string;
	lastLocation?: {
		type: string;
		coordinates: number[];
	};
}

export interface IssuesDto {
	comment: string;
	createdAt: string;
	driverData: DriverDto | null;
	extensionId: number;
	id: number;
	oldDriver: string;
	oldDriverData: DriverDto;
	reason: string;
	status: string;
	transporterComment: string;
	updatedAt: string;
}

export enum DriverIssuesEnum {
	CREATED = 'created',
	IGNORE = 'ignore',
	RESOLVE = 'resolve',
}

export interface TruckDto {
	id: number;
	orderId: number;
	truckType: string;
	quantity: number;
	loadedQuantity?: number;
	payload: number;
	deliveryFrom: string;
	deliveryTo: string;
	priceForTransporter?: number;
	priceForTransporterWithVat?: number;
	transportVat?: number;
	priceForProducer?: number;
	priceForProducerWithVat?: number;
	createdAt: string;
	updatedAt: string;
	deliveryTimeFrom: string;
	deliveryTimeTo: string;
	referenceId?: string;
	loadDetails: {
		comments: string;
		wight: number;
		documents: string[];
	};
	unloadDetails: {
		comments: string;
		wight: number;
		documents: string[];
	};
	distance: number;
	driver: DriverDto;
	orderStatus: string;
	producerLoadDetails: {
		comments: string;
		wight: number;
		documents: string[];
	};
	issues: IssuesDto[];
}

export interface CreateTruckParams {
	name: string;
	type: string;
	truckModel?: string;
	truckYear?: number;
	truckColor?: string;
	truckNumber: string;
	truckBrand?: string;
	truckPhoto: string;
	licensePlates: string;
	emissionType?: string;
	driverFullName: string;
	driverEmail: string;
	driverAvatar: string;
	driverPhone: string;
}

export interface FleetQueryParams {
	limit?: number;
	offset?: number;
	ids?: string[];
	status?: string;
	companyId?: number;
}

export interface UpdateTruck {
	name?: string;
	type?: string;
	truckModel?: string;
	truckYear?: number;
	truckColor?: string;
	truckNumber?: string;
	truckPhoto?: string;
	truckBrand?: string;
	licensePlates?: string;
	emissionType?: string;
	driverFullName?: string;
	driverAvatar?: string;
	driverEmail?: string;
	driverPhone?: string;
	status?: string;
	lastAddress?: string;
	lat?: number;
	long?: number;
}

export interface FleetBaseDto {
	id: number;
	createdBy: string;
	companyId: number;
	radiusDistance: number;
	address: string;
	location: {
		type: string;
		coordinates: number[];
	};
	createdAt: string;
	updatedAt: string;
	countryCode?: string;
}

interface FleetBaseResponse {
	data: FleetBaseDto;
}

interface GetFleetBasesResponse {
	data: {
		count: number;
		items: FleetBaseDto[];
	};
}

export interface UpdateFleetBase {
	radiusDistance: number;
	address: string;
	lat: number;
	long: number;
}

export interface ResolveIssuesBody {
	assigneeId: string;
	orderAcceptedForDriver: boolean;
}

export class TruckService {
	static createTruck(body: CreateTruckParams): Promise<PostTruckResponse> {
		return Api.post(`/v1/transporter/fleets`, {
			...body,
		});
	}

	static allocateTruck(
		orderId: number,
		truckUid: string,
	): Promise<PostTruckResponse> {
		return Api.post(`/v1/transporter/orders/${orderId}/assign`, {
			truckUid,
		});
	}

	static getTrucks(
		query?: FleetQueryParams,
		target = 'transporter',
	): Promise<ResponseTruckListResponse> {
		return Api.get(`/v1/${target}/fleets`, {
			params: query,
		});
	}

	static deleteTruck(id: number): Promise<DeleteTruckResponse> {
		return Api.delete(`/v1/transporter/fleets/${id}`);
	}

	static editTruck(id: number, body: UpdateTruck): Promise<PostTruckResponse> {
		return Api.patch(`/v1/transporter/fleets/${id}`, {
			...body,
		});
	}

	static generateQr(
		id: number,
		target = 'transporter',
	): Promise<PostTruckResponse> {
		const lang = localStorage.getItem('lang') || 'pl';
		return Api.post(`/v1/${target}/fleets/generate-qr`, { id, lang });
	}

	static getMyFleetBase(): Promise<FleetBaseResponse> {
		return Api.get('/v1/transporter/fleet-bases');
	}

	static getFleetBases(params: any): Promise<GetFleetBasesResponse> {
		return Api.get('/v1/admin/fleet-bases', { ...params });
	}

	static updateFleetBase(body: UpdateFleetBase): Promise<FleetBaseResponse> {
		return Api.post('/v1/transporter/fleet-bases', {
			...body,
		});
	}

	static updateFleetBaseById(
		companyId: string,
		body: UpdateFleetBase,
	): Promise<FleetBaseResponse> {
		return Api.patch(`/v1/admin/fleet-bases/${companyId}`, {
			...body,
		});
	}

	static ignoreIssue(
		orderId: number,
		issueId: number,
		comment: string,
	): Promise<PostTruckResponse> {
		return Api.post(
			`/v1/transporter/orders/${orderId}/issue/${issueId}/ignore`,
			{ comment },
		);
	}

	static resolveIssues(
		orderId: number,
		issueId: number,
		body: ResolveIssuesBody,
	): Promise<PostTruckResponse> {
		return Api.post(
			`/v1/transporter/orders/${orderId}/issue/${issueId}/resolve`,
			{ ...body },
		);
	}
}
