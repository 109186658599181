import React from 'react';
import Modal from 'react-modal';
import { SypacButton, SypacText } from '@sypac/component-library-react';
import { ModalSuccessPaymentProps } from './ModalSuccessPayment.interface';
import PaymentSuccess from '../../assets/PaymentSuccess';
import { T } from '@tolgee/react';
import Close from '../../assets/Close';

const ModalSuccessPayment = ({ isOpen, onClose }: ModalSuccessPaymentProps) => {
	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={onClose}
			className="modal-inside outline-none w-[451px]"
			overlayClassName="modal-overlay"
			shouldCloseOnOverlayClick={false}
			ariaHideApp={false}
		>
			<div className="flex flex-col">
				<div className="flex flex-row items-center justify-between p-3">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">Payment processed</p>
					</SypacText>
					<SypacButton variant="subTitle">
						<button
							type="button"
							className="flex justify-center items-center h-[32px] w-[32px] p-0 bg-white border border-solid border-gray-10 transition hover:border-gray-60 rounded-lg"
							onClick={onClose}
						>
							<Close width="10" height="10" />
						</button>
					</SypacButton>
				</div>

				<div className="flex flex-col items-start justify-center modal-confirmation-code-border px-3 pb-3 gap-10">
					<div className="pt-10">
						<PaymentSuccess />
					</div>

					<div className="flex flex-col gap-6">
						<SypacText variant="heading-5">
							<p className="text-gray-80">Payment successfully processed</p>
						</SypacText>
						<SypacText variant="body-regular-small">
							<p className="text-gray-40 text-left">
								Payment confirmed and successfully processed.
							</p>
						</SypacText>
					</div>
				</div>

				<div className="w-full flex justify-center p-3 border-0 border-t border-solid border-gray-10 box-border">
					<SypacButton
						variant="secondary"
						size="small"
						className="w-full"
						onClick={onClose}
					>
						<button
							type="reset"
							className="w-full h-[44px] flex items-center justify-center rounded-lg transition"
						>
							<SypacText variant="body-regular-medium">
								<p>
									<T keyName="modalSuccessPayment.gotIt">Got it</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
				</div>
			</div>
		</Modal>
	);
};

export default ModalSuccessPayment;
