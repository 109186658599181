import {
	SypacSecondStepper,
	SypacSecondStepperStep,
} from '@sypac/component-library-react';
import React from 'react';
import { useTranslate } from '@tolgee/react';

interface CompanyStepperProps {
	current: number;
	minWidth: number;
	target: string;
}

const CompanyStepper: React.FC<CompanyStepperProps> = (props) => {
	const { t } = useTranslate();

	const COMPANY_VERIFICATION: string[] = [
		t('stepper.companyDetails', 'Company details'),
		t('stepper.companyDocuments', 'Company documents'),
		t('stepper.userDetails', 'User details'),
		t('stepper.bankWallet', 'Bank and wallet'),
	];

	return (
		<SypacSecondStepper>
			{props.target === 'company'
				? COMPANY_VERIFICATION.map((item, idx) => {
						return (
							<SypacSecondStepperStep
								description={item}
								step={idx + 1}
								name={t('stepStep.step', 'Step')}
								isActive={props.current >= idx}
								minWidth={props.minWidth}
								isCurrent={props.current === idx}
								isFinalStep={COMPANY_VERIFICATION.length === idx + 1}
								key={idx}
							/>
						);
				  })
				: null}
		</SypacSecondStepper>
	);
};

export default CompanyStepper;
