import React, { useCallback, useEffect, useState } from 'react';
import Table from 'rc-table';
import 'react-toastify/dist/ReactToastify.css';
import { SypacAvatar, SypacText } from '@sypac/component-library-react';
import './products-table.css';
import { ProductsTableInterface } from './ProductsTable.interface';
import dayjs from 'dayjs';
import { T, useTranslate } from '@tolgee/react';
import {
	ProductInterface,
	ProductStatusEnum,
} from '../../interfaces/product.interface';
import Flag from 'react-world-flags';
import { NoResults } from '../../assets/NoResult';
import TableStatus from '../TableStatus/TableStatus';

export const ProductsTable: React.FC<ProductsTableInterface> = ({
	rows,
	rowClick,
	search,
	openCompany,
	clickedOutside,
}) => {
	const { t } = useTranslate();
	const [keyRows, setRows] = useState<ProductInterface[]>([]);
	const [selectedRowKey, setSelectedRowKey] = useState<number>();

	const columns = (search?: string) => [
		{
			title: t('productTable.country', 'Country'),
			dataIndex: '',
			className: 'text-left',
			key: 'country',
			render(value: ProductInterface) {
				const countryCode = value?.countryCode;
				const hasSecond = false;

				return (
					<div className="flex items-center gap-3">
						<Flag
							className="w-[20px] h-[14px] mb-[2px] object-cover border border-solid border-gray-10 rounded"
							code={countryCode}
							width={22}
							height={16}
						/>
						<SypacText variant="body-regular-medium">
							<p>{countryCode}</p>
						</SypacText>

						{hasSecond && (
							<>
								<div className="w-[2px] h-[2px] bg-gray-40 rounded-full" />
								<Flag
									className="w-[20px] h-[14px] mb-[2px] object-cover border border-solid border-gray-10 rounded z-10"
									code={countryCode}
									width={22}
									height={16}
								/>
								<SypacText variant="body-regular-medium">
									<p>{countryCode}</p>
								</SypacText>
							</>
						)}
					</div>
				);
			},
		},
		{
			title: t('productTable.status', 'Status'),
			dataIndex: '',
			className: 'text-left',
			key: 'status',
			render(value: ProductInterface) {
				const status: string = value?.status || ProductStatusEnum.PENDING;

				return (
					<div className="flex">
						<TableStatus state={status} isPartial={false} insideOut={true} />
					</div>
				);
			},
		},
		{
			title: t('productTable.id', 'ID'),
			dataIndex: '',
			className: 'text-left',
			key: 'id',
			render(value: ProductInterface) {
				const html = search
					? value.id
							.toString()
							.replace(search, `<span class="text-kimberly">${search}</span>`)
					: value.id.toString();

				return (
					<SypacText variant="body-regular-medium">
						<p
							className="text-gray"
							dangerouslySetInnerHTML={{ __html: html }}
						/>
					</SypacText>
				);
			},
		},
		{
			title: t('productTable.productName', 'Product name'),
			dataIndex: '',
			className: 'text-left',
			key: 'name',
			render(value: ProductInterface) {
				const productDescription = `${value?.name || ''}`;
				const html = search
					? productDescription?.replace(
							new RegExp(search, 'gi'),
							(match) => `<span class="text-kimberly">${match}</span>`,
					  )
					: productDescription;
				const hasSecond = true;

				return (
					<div className="flex gap-4">
						<div
							style={{
								backgroundImage: `url(${value?.photoUrl})`,
							}}
							className="flex flex-none w-[48px] h-[48px] bg-no-repeat bg-cover border border-solid border-gray-10 rounded-10 box-border"
						/>

						<div className="flex flex-col gap-[6px]">
							<div className="flex flex-row items-center gap-3">
								<SypacText variant="body-regular-medium">
									<p
										className="text-gray-80"
										dangerouslySetInnerHTML={{ __html: html }}
									/>
								</SypacText>
								{value?.type || value?.size ? (
									<>
										<div className="w-[2px] h-[2px] bg-gray-40 rounded-full" />
										<SypacText variant="body-regular-medium">
											<p className="text-gray-80">
												{value?.type || ''}{' '}
												{value?.size ? `(${value?.size})` : ''}
											</p>
										</SypacText>
									</>
								) : null}
							</div>

							<div className="flex flex-row items-center gap-4">
								{value.countryCode ? (
									<Flag
										className="w-[18px] h-[14px] mb-[2px] object-cover border border-solid border-gray-10 rounded"
										code={value.countryCode}
										width={22}
										height={16}
									/>
								) : null}
								<SypacText variant="body-regular-medium">
									<p
										className={`text-sm text-gray-50 ${
											hasSecond ? 'w-[162px] truncate' : ''
										}`}
									>
										{value.address}
									</p>
								</SypacText>

								{hasSecond && (
									<>
										<div className="w-[2px] h-[2px] bg-gray-40 rounded-full" />
										<Flag
											className="w-[18px] h-[14px] mb-[2px] object-cover border border-solid border-gray-10 rounded"
											code={value.countryCode}
											width={22}
											height={16}
										/>
										<SypacText variant="body-regular-medium">
											<p className="w-[162px] text-sm text-gray-50 truncate">
												{value.address}
											</p>
										</SypacText>
									</>
								)}
							</div>
						</div>
					</div>
				);
			},
		},
		{
			title: t('productTable.addedBY', 'Added by'),
			dataIndex: '',
			className: 'text-left',
			key: 'addedBY',
			render(value: ProductInterface) {
				const companyName = value?.company?.name;
				const date = dayjs(value.createdAt).format('DD MMM. YYYY');

				return (
					<div className="flex gap-4">
						<div className="flex w-11 h-11">
							<SypacAvatar initials={companyName} size="md" />
						</div>

						<div className="flex flex-col gap-[6px]">
							<SypacText variant="body-regular-medium">
								<p className="max-w-[330px] text-cornflower-blue truncate transition hover:text-gray-90">
									{companyName}
								</p>
							</SypacText>

							<SypacText variant="body-regular-medium">
								<p className="text-sm text-gray-50">{date}</p>
							</SypacText>
						</div>
					</div>
				);
			},
		},
		{
			title: t('productTable.approvedRejected', 'Approved / Rejected by'),
			dataIndex: '',
			className: 'text-left',
			key: 'approvedRejected',
			render(value: ProductInterface) {
				const status = value.status || ProductStatusEnum.PENDING;
				const date = dayjs(value.updatedAt).format('DD MMM. YYYY');
				const fullName =
					[
						value.verifiedByUser?.firstName || '',
						value.verifiedByUser?.lastName || '',
					]
						.join(' ')
						.trim() || value.verifiedByUser?.name;

				return [
					ProductStatusEnum.APPROVED.toString(),
					ProductStatusEnum.REJECTED.toString(),
				].includes(status) ? (
					<div className="flex items-center gap-4">
						<SypacAvatar initials={fullName} size="sm">
							{value.verifiedByUser?.avatarUrl ? (
								<img src={value.verifiedByUser?.avatarUrl} alt={fullName} />
							) : null}
						</SypacAvatar>

						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">{fullName}</p>
						</SypacText>

						<div className="flex items-center justify-center px-2 py-[2px] bg-gray-1 border border-solid border-gray-10 rounded-full">
							<SypacText variant="body-regular-medium">
								<p className="text-xs text-gray-40">{date}</p>
							</SypacText>
						</div>
					</div>
				) : null;
			},
		},
	];

	useEffect(() => {
		const newRows = rows.map((row) => ({ ...row, key: row?.id }));
		setRows(newRows);
	}, [rows]);

	const getTrProps = (
		record: ProductInterface,
		_index: number,
		_indent: number,
	): string => {
		const baseClass = `product-table-row-${record.id}`;
		const bgClass = ` product-table-row-${record.status}`;
		const selected =
			record.id === selectedRowKey && !clickedOutside
				? ' product-table-row-selected'
				: '';

		return `${baseClass}${bgClass}${selected}`;
	};

	const handleClick = useCallback(
		(event: Event, row: any) => {
			event.stopImmediatePropagation();
			const className = row.className;
			const regexes = className.match(/product-table-row-[0-9]+/gm);
			const regex = regexes?.length && regexes[0];
			if (!regex) {
				return;
			}
			const query = regex.replace('product-table-row-', '');
			if (!query) {
				return;
			}
			const productId = parseInt(query);
			if (!productId) {
				return;
			}
			setSelectedRowKey(productId);
			const product = rows.find((r) => r.id === productId);
			if (product) {
				rowClick(product);
			}
		},
		[rowClick, rows],
	);

	useEffect(() => {
		const rows = document.querySelectorAll('.product-table .rc-table-row');
		for (let i = 0; i < rows.length; i++) {
			rows[i].addEventListener('click', (e) => handleClick(e, rows[i]));

			const productId = keyRows[i]?.id;
			const product = keyRows.find((row) => row.id === productId);
			const companyId = product?.company?.id;

			const tdElements = rows[i].querySelectorAll('td');
			if (tdElements.length >= 5) {
				tdElements[4].addEventListener('click', (e) => {
					e.stopImmediatePropagation();
					if (companyId && product) {
						setSelectedRowKey(product.id);
						openCompany(companyId);
					}
				});
			}
		}

		return () => {
			const rows = document.querySelectorAll('.product-table .rc-table-row');
			for (let i = 0; i < rows.length; i++) {
				rows[i].removeEventListener('click', (e) => handleClick(e, rows[i]));

				const productId = keyRows[i]?.id;
				const product = keyRows.find((row) => row.id === productId);
				const companyId = product?.company?.id;

				const tdElements = rows[i].querySelectorAll('td');
				if (tdElements.length >= 5) {
					tdElements[4].removeEventListener('click', (e) => {
						e.stopImmediatePropagation();
						if (companyId && product) {
							setSelectedRowKey(product.id);
							openCompany(companyId);
						}
					});
				}
			}
		};
	}, [handleClick, keyRows, openCompany]);

	return (
		<>
			<Table
				className={`product-table ${rows.length === 0 ? 'h-full' : ''}`}
				columns={columns(search)}
				data={keyRows}
				rowClassName={getTrProps}
				emptyText={() => {
					return (
						<div className="flex flex-col justify-center items-center h-[calc(100vh-371px)] gap-8">
							<NoResults />
							<SypacText variant="heading-3">
								<p className="text-gray-80">
									{search ? (
										<T keyName="productTable.noResultFoundSearch">
											Feature is not available at the moment
										</T>
									) : (
										<T keyName="productTable.noResultFound">No products yet.</T>
									)}
								</p>
							</SypacText>
							<SypacText variant="body-normal-small">
								<p className="text-gray-50">
									{search ? (
										<T keyName="productTable.noResultFoundDescription">
											Lorem ipsum dolor sit amet, consectetur adipiscing elit,
											sed do eiusmod tempor incididunt
										</T>
									) : null}
								</p>
							</SypacText>
						</div>
					);
				}}
			/>
		</>
	);
};
