import { IconInterface } from '../interfaces/icon.interface';

const AlertSmall = (props: IconInterface) => {
	const { color = '#454545', reverseColor, secondColor } = props;

	return (
		<svg
			width="19"
			height="18"
			viewBox="0 0 19 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M3.65816 7.86809C6.30814 3.28216 7.63313 0.989193 9.71277 0.999812C11.7924 1.01043 13.0939 3.31681 15.6969 7.92956L16.0213 8.50436C18.1844 12.3375 19.2659 14.2541 18.2725 15.6464C17.279 17.0387 14.8388 17.0262 9.95837 17.0013L9.30378 16.9979C4.42335 16.973 1.98313 16.9606 1.00396 15.5582C0.0247795 14.1559 1.12584 12.2504 3.32795 8.43955L3.65816 7.86809Z"
				fill={reverseColor ? 'white' : color}
			/>
			<path
				d="M9.63379 5.39819V9.84264"
				stroke={reverseColor ? color : secondColor || 'white'}
				strokeWidth="0.888889"
				strokeLinecap="round"
			/>
			<mask id="path-3-inside-1_16560_24592" fill="white">
				<ellipse cx="9.63359" cy="12.1946" rx="0.652148" ry="0.644097" />
			</mask>
			<ellipse
				cx="9.63359"
				cy="12.1946"
				rx="0.652148"
				ry="0.644097"
				fill={reverseColor ? color : secondColor || 'white'}
			/>
			<path
				d="M9.39685 12.1946C9.39685 12.0491 9.51329 11.9498 9.63359 11.9498V13.7276C10.4742 13.7276 11.1746 13.0517 11.1746 12.1946H9.39685ZM9.63359 11.9498C9.7539 11.9498 9.87033 12.0491 9.87033 12.1946H8.09256C8.09256 13.0517 8.79295 13.7276 9.63359 13.7276V11.9498ZM9.87033 12.1946C9.87033 12.3402 9.7539 12.4394 9.63359 12.4394V10.6616C8.79295 10.6616 8.09256 11.3376 8.09256 12.1946H9.87033ZM9.63359 12.4394C9.51329 12.4394 9.39685 12.3402 9.39685 12.1946H11.1746C11.1746 11.3376 10.4742 10.6616 9.63359 10.6616V12.4394Z"
				fill={reverseColor ? color : secondColor || 'white'}
				mask="url(#path-3-inside-1_16560_24592)"
			/>
		</svg>
	);
};

export default AlertSmall;
