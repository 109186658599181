import React, { useEffect, useMemo, useState } from 'react';
import { T, useTranslate } from '@tolgee/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
	DriverDto,
	DriverIssuesEnum,
	TruckService,
} from '../../../../../services/truck.services';
import {
	SypacBadge,
	SypacButton,
	SypacInput,
	SypacText,
} from '@sypac/component-library-react';
import AlertSmall from '../../../../../assets/AlertSmall';
import {
	ChangingState,
	DriverIssueBlockProps,
	IssueTypeAction,
	ResolvingState,
} from './DriverIssueBlock.interface';
import dayjs from 'dayjs';
import ExclamationCircle from '../../../../../assets/ExclamationCircle';
import { Tooltip } from 'react-tooltip';
import Emitter, { EventType } from '../../../../../services/events';
import { toastVariant } from '../../../../../components/CompaniesTable/toastVariant/toastVariant';

const DriverIssueBlock: React.FC<DriverIssueBlockProps> = (props) => {
	const { t } = useTranslate();
	const {
		selectedOrder,
		resolvingIssue,
		changingTruck,
		allocatedTruck,
		allocateAgain,
	} = props;
	const [step, setStep] = useState<number>(1);
	const [isResolving, setIsResolving] = useState<ResolvingState>({
		markAsFixed: false,
		confirm: false,
	});
	const [isChanging, setIsChanging] = useState<ChangingState>({
		changeTruck: false,
		confirm: false,
	});
	const [charCount, setCharCount] = useState<number>(0);
	const [newTruck, setNewTruck] = useState<DriverDto | undefined>(undefined);
	const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);

	const driverIssues = useMemo(() => {
		return selectedOrder?.trucks?.[0].issues?.[0];
	}, [selectedOrder?.trucks]);

	const formik = useFormik({
		initialValues: {
			info: '',
		},
		enableReinitialize: true,
		onSubmit: async (values) => {
			await TruckService.ignoreIssue(
				selectedOrder?.id!,
				driverIssues?.id!,
				values.info,
			);
			resolvingIssue(false);
			Emitter.emit(EventType.ORDER_REFRESH, selectedOrder);
			toastVariant(
				t('driverIssueBlock.issueMarkedAsResolved', 'Issue marked as resolved'),
				false,
				'issue-marked-as-resolved',
			);
		},
		validationSchema: Yup.object({
			info: Yup.string(),
		}),
	});

	const resolveIssues = async (choice: boolean) => {
		const resolveIssueBody = {
			assigneeId: newTruck?.uid!,
			orderAcceptedForDriver: choice,
		};

		try {
			await TruckService.resolveIssues(
				selectedOrder?.id!,
				driverIssues?.id!,
				resolveIssueBody,
			);
			resolvingIssue(false);
			Emitter.emit(EventType.ORDER_REFRESH, selectedOrder);
		} catch (e) {}
	};

	const actionClick = (type: string) => {
		switch (type) {
			case IssueTypeAction.ignore:
				return setIsResolving((prevState) => ({
					...prevState,
					markAsFixed: true,
				}));
			case IssueTypeAction.resolve:
				return changingTruck(true);
		}
	};

	const handleBack = () => {
		formik.resetForm();
		setCharCount(0);
		setIsResolving((prevState) => ({
			...prevState,
			markAsFixed: false,
		}));
	};

	const handleBackToForm = () => {
		setIsResolving((prevState) => ({
			...prevState,
			confirm: false,
		}));
	};

	const handleTextareaChange = (event: any) => {
		const text = event.target.value;
		formik.handleChange(event);
		setCharCount(text.length);
	};

	const handleResolvingIssue = () => {
		setStep(2);
		resolvingIssue(true);
	};

	const confirmResolvingIssue = () => {
		setIsResolving((prevState) => ({
			...prevState,
			confirm: true,
		}));
	};

	useEffect(() => {
		if (allocatedTruck) {
			setNewTruck(allocatedTruck);
			setIsChanging((prevState) => ({
				...prevState,
				changeTruck: true,
			}));
		}
	}, [allocatedTruck]);

	const backToTruckAllocation = () => {
		changingTruck(true);
		setNewTruck(undefined);
		allocateAgain(undefined);
		setIsChanging((prevState) => ({
			...prevState,
			changeTruck: false,
		}));
	};

	const confirmChangingTruck = () => {
		setIsChanging((prevState) => ({
			...prevState,
			confirm: true,
		}));
	};

	const IssueDetailsList = () => {
		return (
			<ul className="list-disc text-gray-40 m-0 pl-8 [&_p]:ml-1.5">
				<li>
					<SypacText variant="body-regular-medium">
						<p>
							<T keyName="driverIssueBlock.reportedIssue">Reported Issue:</T>{' '}
							<T
								keyName={`driverIssueBlock.${driverIssues?.reason
									.toLowerCase()
									.replace(/ (.)/g, (_, c) => c.toUpperCase())}`}
							>
								{driverIssues?.reason}
							</T>
						</p>
					</SypacText>
				</li>
				<li>
					<SypacText variant="body-regular-medium">
						<p>
							<T keyName="driverIssueBlock.comment">Comment:</T>{' '}
							{driverIssues?.comment || '-'}
						</p>
					</SypacText>
				</li>
				<li>
					<SypacText variant="body-regular-medium">
						<p>
							<T keyName="driverIssueBlock.timeOfReport">Time of Report:</T>{' '}
							{dayjs(driverIssues?.createdAt).format('DD MMM. YYYY — HH:mm')}
						</p>
					</SypacText>
				</li>
				<li>
					<SypacText variant="body-regular-medium">
						<p>
							<T keyName="driverIssueBlock.issueStatus">Issue Status:</T>{' '}
							{driverIssues?.status === DriverIssuesEnum.CREATED ? (
								<T keyName="driverIssueBlock.pendingResolution">
									Pending resolution
								</T>
							) : [DriverIssuesEnum.IGNORE, DriverIssuesEnum.RESOLVE].includes(
									driverIssues?.status as DriverIssuesEnum,
							  ) ? (
								<T keyName="driverIssueBlock.resolved">Resolved</T>
							) : null}
						</p>
					</SypacText>
				</li>
			</ul>
		);
	};

	return (
		<div
			className={`fixed bottom-0 w-[702px] h-fit bg-white border border-solid rounded-2xl shadow-order-assign ${
				isResolving.markAsFixed || isChanging.changeTruck
					? 'border-primary-violet'
					: 'border-red'
			} ${isTooltipVisible ? 'z-[9999]' : ''}`}
		>
			{step === 1 ? (
				<div className="flex flex-col justify-center p-10 gap-6">
					{isChanging.changeTruck && !isChanging.confirm ? (
						<div className="flex flex-col gap-3">
							<SypacText variant="heading-5">
								<p className="text-2xl leading-7 tracking-tighter text-gray-90">
									<T keyName="driverIssueBlock.areYouSureToReassignTruck">
										Are you sure you want to reassign this order to the selected
										truck?
									</T>
								</p>
							</SypacText>

							<SypacText variant="body-regular-medium">
								<p className="text-gray-40">
									<T keyName="driverIssueBlock.thisOrderWillBeReassigned">
										This order will be removed from the current driver and
										reassigned to the new one. Both drivers will be notified.
									</T>
								</p>
							</SypacText>
						</div>
					) : isChanging.confirm && isChanging.changeTruck ? (
						<div className="flex flex-col gap-8">
							<div className="flex justify-between">
								<div
									data-tooltip-id="driver-issues-resume-restart"
									className="flex gap-3 items-center ml-auto"
								>
									<ExclamationCircle />
									<SypacText variant="body-regular-medium">
										<p className="text-primary-violet mt-[1px]">
											<T keyName="driverIssueBlock.howDoesItWork">
												How does it work
											</T>
										</p>
									</SypacText>
								</div>
							</div>

							<div className="flex flex-col gap-5 mb-4">
								<SypacText variant="heading-5">
									<p className="text-2xl leading-7 tracking-tighter text-gray-90">
										<T keyName="driverIssueBlock.pleaseSelectHowNewDriver">
											Please select how you would like the new driver to
											continue:
										</T>
									</p>
								</SypacText>
							</div>
						</div>
					) : (
						<>
							<div className="flex h-[22px] gap-6">
								<SypacBadge color="red">
									<span className="flex items-center py-0 px-[11.5px]">
										<AlertSmall color="#F44A77" reverseColor={true} />
									</span>
								</SypacBadge>

								<SypacText variant="body-regular-medium">
									<p className="text-gray-80 mt-[2px]">
										<T keyName="driverIssueBlock.driverReportedIssue">
											Driver reported an issue
										</T>
									</p>
								</SypacText>
							</div>

							<SypacText variant="heading-5">
								<p className="text-2xl leading-7 tracking-tighter text-gray-90">
									<T keyName="driverIssueBlock.thisOrderCannotBeExecuted">
										This order cannot be executed until the reported issue is
										marked as solved
									</T>
								</p>
							</SypacText>

							<IssueDetailsList />

							<SypacText variant="body-regular-medium">
								<p className="text-gray-80">
									<T keyName="driverIssueBlock.pleaseTakeAction">
										Please take action to resolve the issue and unblock the
										order execution.
									</T>
								</p>
							</SypacText>

							<SypacButton variant="primary" size="large" className="w-full">
								<button
									className="w-full h-[54px] rounded-10 transition bg-primary-violet text-white hover:bg-primary-violet/70 hover:text-white"
									onClick={() => handleResolvingIssue()}
								>
									<SypacText variant="body-regular-medium">
										<p>
											<T keyName="driverIssueBlock.resolveIssue">
												Resolve issue
											</T>
										</p>
									</SypacText>
								</button>
							</SypacButton>
						</>
					)}

					{isChanging.changeTruck ? (
						<div className="flex flex-col gap-6">
							<div className="flex gap-6 justify-center">
								<SypacButton variant="primary" size="large" className="w-full">
									<button
										type="button"
										className={`w-full h-[54px] ${
											isChanging.confirm
												? 'rounded-10 transition bg-primary-violet text-white hover:bg-primary-violet/70 hover:text-white'
												: 'bg-white border border-solid border-gray-10 text-gray-80 rounded-10 transition hover:border-gray-40 hover:text-gray-90'
										}`}
										onClick={() =>
											isChanging.confirm
												? resolveIssues(true)
												: backToTruckAllocation()
										}
									>
										<SypacText variant="body-regular-medium">
											<p>
												{isChanging.changeTruck && (
													<T
														keyName={
															isChanging.confirm
																? 'driverIssueBlock.restartOrder'
																: 'modalLoadDetails.back'
														}
													>
														{isChanging.confirm ? 'Restart order' : 'Back'}
													</T>
												)}
											</p>
										</SypacText>
									</button>
								</SypacButton>
								<SypacButton variant="primary" size="large" className="w-full">
									<button
										type="button"
										className="w-full h-[54px] rounded-10 transition bg-primary-violet text-white hover:bg-primary-violet/70 hover:text-white"
										onClick={() =>
											isChanging.confirm
												? resolveIssues(false)
												: confirmChangingTruck()
										}
									>
										<SypacText variant="body-regular-medium">
											<p>
												{isChanging.changeTruck && (
													<T
														keyName={
															isChanging.confirm
																? 'driverIssueBlock.resumeOrder'
																: 'driverIssueBlock.nextStep'
														}
													>
														{isChanging.confirm ? 'Resume order' : 'Next step'}
													</T>
												)}
											</p>
										</SypacText>
									</button>
								</SypacButton>
							</div>

							{isChanging.confirm ? (
								<SypacButton variant="primary" size="large" className="w-full">
									<button
										type="button"
										className="w-full h-[54px] bg-white border border-solid border-gray-10 text-gray-80 rounded-10 transition hover:border-gray-40 hover:text-gray-90"
										onClick={() =>
											setIsChanging((prevState) => ({
												...prevState,
												confirm: false,
											}))
										}
									>
										<SypacText variant="body-regular-medium">
											<p>
												<T keyName="modalLoadDetails.back">Back</T>
											</p>
										</SypacText>
									</button>
								</SypacButton>
							) : null}
						</div>
					) : null}
				</div>
			) : (
				<div className="flex flex-col justify-center p-10 gap-6">
					{!isResolving.markAsFixed ? (
						<>
							<div className="flex justify-between">
								<div className="flex h-[22px] gap-6">
									<SypacBadge color="red">
										<span className="flex items-center py-0 px-[11.5px]">
											<AlertSmall color="#F44A77" reverseColor={true} />
										</span>
									</SypacBadge>

									<SypacText variant="body-regular-medium">
										<p className="text-gray-80 mt-[2px]">
											<T keyName="driverIssueBlock.resolveReportedIssue">
												Resolve reported issue
											</T>
										</p>
									</SypacText>
								</div>

								<div
									data-tooltip-id="driver-issues-fix-or-change"
									className="flex gap-3 items-center"
								>
									<ExclamationCircle />
									<SypacText variant="body-regular-medium">
										<p className="text-primary-violet mt-[1px]">
											<T keyName="driverIssueBlock.howDoesItWork">
												How does it work
											</T>
										</p>
									</SypacText>
								</div>
							</div>

							<SypacText variant="heading-5">
								<p className="text-2xl leading-7 tracking-tighter text-gray-90">
									<T keyName="driverIssueBlock.examineTheIssueDetails">
										Examine the issue details and choose how to proceed with the
										resolution
									</T>
								</p>
							</SypacText>

							<IssueDetailsList />
						</>
					) : isResolving.confirm && isResolving.markAsFixed ? (
						<div className="flex flex-col gap-3">
							<SypacText variant="heading-5">
								<p className="text-2xl leading-7 tracking-tighter text-gray-90">
									<T keyName="driverIssueBlock.confirmResolution">
										Confirm resolution
									</T>
								</p>
							</SypacText>

							<SypacText variant="body-regular-medium">
								<p className="text-gray-40">
									<T keyName="driverIssueBlock.areYouSureToMark">
										Are you sure you want to mark this issue as fixed? Once
										confirmed, the driver will be notified to continue the
										execution of this order.
									</T>
								</p>
							</SypacText>
						</div>
					) : (
						<>
							<div className="flex flex-col gap-3">
								<SypacText variant="heading-5">
									<p className="text-2xl leading-7 tracking-tighter text-gray-90">
										<T keyName="driverIssueBlock.resolveIssue">Resolve issue</T>
									</p>
								</SypacText>

								<SypacText variant="body-regular-medium">
									<p className="text-gray-40">
										<T keyName="driverIssueBlock.youAreAboutToMark">
											You are about to mark the following issue as resolved.
										</T>
									</p>
								</SypacText>
							</div>

							<div className="border-0 border-solid border-t-[1px] border-gray-10" />

							<div className="flex flex-col gap-3">
								<div className="grid grid-cols-[120px,auto] gap-10 items-start">
									<SypacText variant="body-regular-medium">
										<p className="text-gray-40">
											<T keyName="driverIssueBlock.reportedIssue">
												Reported Issue:
											</T>
										</p>
									</SypacText>
									<SypacText variant="body-regular-medium">
										<p className="text-gray-80">
											<T
												keyName={`driverIssueBlock.${driverIssues?.reason
													.toLowerCase()
													.replace(/ (.)/g, (_, c) => c.toUpperCase())}`}
											>
												{driverIssues?.reason}
											</T>
										</p>
									</SypacText>
								</div>

								<div className="grid grid-cols-[120px,auto] gap-10 items-start">
									<SypacText variant="body-regular-medium">
										<p className="text-gray-40">
											<T keyName="driverIssueBlock.timeOfReport">
												Time of Report:
											</T>
										</p>
									</SypacText>
									<SypacText variant="body-regular-medium">
										<p className="text-gray-80">
											{dayjs(driverIssues?.createdAt).format(
												'DD MMM. YYYY — HH:mm',
											)}
										</p>
									</SypacText>
								</div>
							</div>

							<SypacInput className="w-full flex gap-2">
								<SypacText variant="overline-normal-large" className="mb-1">
									<p className="flex gap-[2px] text-sm">
										<T keyName="driverIssueBlock.addCommentOptional">
											Add comment (optional)
										</T>
									</p>
								</SypacText>
								<div className="w-full">
									<textarea
										name="info"
										className="w-full box-border h-[80px] font-sans py-3.5 pl-3 border border-solid border-gray-20 rounded-xl transition hover:border-gray-40/75 focus:border-[#5682fa] placeholder:text-gray-40 placeholder:text-base resize-none outline-none"
										rows={2}
										maxLength={500}
										placeholder={t(
											'driverIssueBlock.addYourComment',
											'Add your comment',
										)}
										onChange={handleTextareaChange}
										onBlur={formik.handleBlur}
										value={formik.values.info}
									/>
								</div>
								<div className="w-fit flex items-center h-[22px] bg-[#F6F6F6] text-xs text-gray-40 rounded-[15px] px-2 mt-3 ml-auto">
									{`${charCount}/500 `}
									<T keyName="driverIssueBlock.addCommentSymbols">symbols</T>
								</div>
							</SypacInput>
						</>
					)}

					<div className="flex gap-3 justify-center">
						<SypacButton variant="primary" size="large" className="w-full">
							<button
								type="button"
								className="w-full h-[54px] bg-white border border-solid border-gray-10 text-gray-80 rounded-10 transition hover:border-gray-40 hover:text-gray-90"
								onClick={() =>
									isResolving.markAsFixed
										? isResolving.confirm && isResolving.markAsFixed
											? handleBackToForm()
											: handleBack()
										: actionClick(IssueTypeAction.ignore)
								}
							>
								<SypacText variant="body-regular-medium">
									<p>
										{isResolving.markAsFixed ? (
											<T keyName="modalLoadDetails.back">Back</T>
										) : (
											<T keyName="driverIssueBlock.markAsFixed">
												Mark as fixed
											</T>
										)}
									</p>
								</SypacText>
							</button>
						</SypacButton>
						<SypacButton variant="primary" size="large" className="w-full">
							<button
								type="button"
								className="w-full h-[54px] rounded-10 transition bg-primary-violet text-white hover:bg-primary-violet/70 hover:text-white"
								onClick={() =>
									isResolving.markAsFixed && !isResolving.confirm
										? confirmResolvingIssue()
										: isResolving.markAsFixed && isResolving.confirm
										? formik.submitForm()
										: actionClick(IssueTypeAction.resolve)
								}
							>
								<SypacText variant="body-regular-medium">
									<p>
										{isResolving.markAsFixed && !isResolving.confirm ? (
											<T keyName="driverIssueBlock.resolveIssue">
												Resolve issue
											</T>
										) : isResolving.markAsFixed && isResolving.confirm ? (
											<T keyName="driverIssueBlock.yesMarkAsResolved">
												Yes, mark as resolved
											</T>
										) : (
											<T keyName="driverIssueBlock.changeTruck">Change truck</T>
										)}
									</p>
								</SypacText>
							</button>
						</SypacButton>
					</div>
				</div>
			)}
			<Tooltip
				place="top"
				id="driver-issues-fix-or-change"
				style={{
					backgroundColor: '#E8E8E8',
					color: '#000000',
				}}
				afterShow={() => setIsTooltipVisible(true)}
				afterHide={() => setIsTooltipVisible(false)}
			>
				<div className="w-[240px] flex flex-col gap-1 p-1">
					<SypacText variant="body-regular-medium">
						<p className="text-sm text-gray-90 font-bold">
							<T keyName="driverIssueBlock.changeTheTruck">Change the truck:</T>
						</p>
					</SypacText>
					<SypacText variant="body-regular-medium">
						<p className="text-sm text-gray-80">
							<T keyName="driverIssueBlock.changeTheTruckInfo">
								If you choose to change the truck, the order will be removed
								from the current driver and reassigned to a new truck. The new
								driver will take over the delivery.
							</T>
						</p>
					</SypacText>

					<SypacText variant="body-regular-medium">
						<p className="text-sm text-gray-90 font-bold">
							<T keyName="driverIssueBlock.markIssueAsFixed">
								Mark issue as fixed:
							</T>
						</p>
					</SypacText>
					<SypacText variant="body-regular-medium">
						<p className="text-sm text-gray-80">
							<T keyName="driverIssueBlock.markIssueAsFixedInfo">
								If the reported issue has been resolved, you can mark it as
								fixed. The current driver will proceed with the delivery.
							</T>
						</p>
					</SypacText>
				</div>
			</Tooltip>
			<Tooltip
				place="top"
				id="driver-issues-resume-restart"
				style={{
					backgroundColor: '#E8E8E8',
					color: '#000000',
				}}
				afterShow={() => setIsTooltipVisible(true)}
				afterHide={() => setIsTooltipVisible(false)}
			>
				<div className="w-[240px] flex flex-col gap-1 p-1">
					<SypacText variant="body-regular-medium">
						<p className="text-sm text-gray-90 font-bold">
							<T keyName="driverIssueBlock.resumeOrder">Resume Order</T>
						</p>
					</SypacText>
					<SypacText variant="body-regular-medium">
						<p className="text-sm text-gray-80">
							<T keyName="driverIssueBlock.resumeOrderInfo">
								The new driver will take over from the point where the previous
								driver left off. This option allows the order to continue
								seamlessly without restarting any of the steps already
								completed, ensuring minimal disruption in the process.
							</T>
						</p>
					</SypacText>

					<SypacText variant="body-regular-medium">
						<p className="text-sm text-gray-90 font-bold">
							<T keyName="driverIssueBlock.restartOrder">Restart Order</T>
						</p>
					</SypacText>
					<SypacText variant="body-regular-medium">
						<p className="text-sm text-gray-80">
							<T keyName="driverIssueBlock.restartOrderInfo">
								Selecting this option means the new driver will begin the entire
								order execution process from the start. This is ideal if the
								order requires a fresh start, including pick-up and all
								associated steps.
							</T>
						</p>
					</SypacText>
				</div>
			</Tooltip>
		</div>
	);
};

export default DriverIssueBlock;
