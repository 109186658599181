import { OrderInterface } from '../../../../../components/OrdersTable/interfaces/Order.interface';
import { DriverDto } from '../../../../../services/truck.services';

export interface DriverIssueBlockProps {
	selectedOrder: OrderInterface | undefined;
	resolvingIssue: (value: boolean) => void;
	changingTruck: (value: boolean) => void;
	allocatedTruck: DriverDto | undefined;
	allocateAgain: (value: undefined) => void;
}

export enum IssueTypeAction {
	ignore = 'ignore',
	resolve = 'resolve',
}

export type ResolvingState = {
	markAsFixed: boolean;
	confirm: boolean;
};

export type ChangingState = {
	changeTruck: boolean;
	confirm: boolean;
};
