import React, { useEffect, useRef, useState } from 'react';
import {
	SypacVerticalTimeline,
	SypacVerticalTimelineStep,
} from '@sypac/component-library-react';
import { useTranslate } from '@tolgee/react';
import IssuesDefault from '../../../../../../components/Notification/IssuesDefault';
import {
	DriverDto,
	DriverIssuesEnum,
} from '../../../../../../services/truck.services';

export interface ContentDto {
	id: string | number;
	comment: string;
	transporterComment?: string;
	createdAt: string;
	updatedAt?: string;
	oldDriver: string;
	driver?: string;
	driverData?: DriverDto;
	oldDriverData?: DriverDto;
	extensionId?: number;
	reason: string;
	status: string;
}

interface NotificationIssueSolvedProps {
	notification: boolean;
	date: string;
	solvedByDriver?: boolean;
	changeTruck?: boolean;
	content: ContentDto;
}

const NotificationIssueSolved = ({
	notification,
	date,
	solvedByDriver,
	changeTruck,
	content,
}: NotificationIssueSolvedProps) => {
	const { t } = useTranslate();
	const [boxHeight, setBoxHeight] = useState<number>(0);
	const boxRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const element = boxRef.current;
		const resizeObserver = new ResizeObserver((entries) => {
			for (let entry of entries) {
				setBoxHeight(entry.contentRect.height);
			}
		});

		if (element) {
			resizeObserver.observe(element);
		}

		return () => {
			if (element) {
				resizeObserver.unobserve(element);
			}
		};
	}, []);

	return (
		<div className="flex flex-row gap-6">
			<SypacVerticalTimeline>
				<SypacVerticalTimelineStep
					isActive={notification}
					minWidth={Math.ceil(boxHeight / 10) * 10}
				>
					{date}
				</SypacVerticalTimelineStep>
			</SypacVerticalTimeline>

			<div ref={boxRef} className="h-fit w-full">
				<IssuesDefault
					notification={notification}
					title={
						solvedByDriver
							? t(
									'NotificationIssueSolved.solvedByDriver',
									'Issue marked as solved (driver)',
							  )
							: changeTruck
							? t(
									'NotificationIssueSolved.solvedByDispatcherChangeTruck',
									'Issue marked as solved - change truck (dispatcher)',
							  )
							: t(
									'NotificationIssueSolved.solvedByDispatcher',
									'Issue marked as solved (dispatcher)',
							  )
					}
					content={content}
					newIssue={content.status === DriverIssuesEnum.CREATED}
					solvedByDriver={solvedByDriver}
					changeTruck={changeTruck}
				/>
			</div>
		</div>
	);
};

export default NotificationIssueSolved;
