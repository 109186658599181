import React from 'react';

const BouncingLoader: React.FC = () => (
	<div className="flex space-x-2">
		<div className="w-3 h-3 bg-primary-violet rounded-full animate-bounce-sequential animation-delay-100" />
		<div className="w-3 h-3 bg-primary-violet rounded-full animate-bounce-sequential animation-delay-200" />
		<div className="w-3 h-3 bg-primary-violet rounded-full animate-bounce-sequential animation-delay-300" />
	</div>
);

export default BouncingLoader;
