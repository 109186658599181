import React, { memo, useCallback, useEffect, useState } from 'react';
import CompanyFleet from '../../../components/CompanyFleet/CompanyFleet';
import { useGetFeet } from '../../../hooks/use-get-fleet';
import { MutatingDots } from 'react-loader-spinner';
import { LIMIT } from '../../../constants';
import { FleetBaseDto, TruckService } from '../../../services/truck.services';
import { GeoService } from '../../../services/geo.services';
import { toastVariant } from '../../../components/CompaniesTable/toastVariant/toastVariant';

interface Props {
	companyId: number | undefined;
}

export const FleetTab: React.FC<Props> = memo(({ companyId }: Props) => {
	const [baseLoading, setBaseLoading] = useState<boolean>(false);
	const [carrierBase, setCarrierBase] = useState<FleetBaseDto | undefined>(
		undefined,
	);
	const [refresh, setRefresh] = useState<number>(0);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [data, _, isLoading] = useGetFeet({
		companyId: companyId,
		target: 'admin',
	});

	const getFleetBase = useCallback(async () => {
		setBaseLoading(true);
		try {
			const params = {
				limit: LIMIT,
				offset: 0,
				ids: [],
				companyIds: [],
			};

			const { data } = await TruckService.getFleetBases(params);
			const fleetBaseData = data?.items.find(
				(item) => item.companyId === companyId,
			);
			if (fleetBaseData) {
				const { data: locationDetails } = await GeoService.getLocations({
					phrase: fleetBaseData.address,
					maxResults: '1',
				});

				setCarrierBase({
					...fleetBaseData,
					countryCode: locationDetails?.[0]?.countryCode || '',
				});
			}
		} catch (error) {
			return toastVariant(`Something went wrong. ${error?.toString()!}`, true);
		} finally {
			setBaseLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refresh]);

	useEffect(() => {
		getFleetBase().then(() => {});
	}, [getFleetBase]);

	return !isLoading && !baseLoading ? (
		<CompanyFleet
			fleet={data}
			carrierBase={carrierBase}
			companyId={companyId}
			refresh={setRefresh}
		/>
	) : (
		<div className="flex w-full h-[500px] items-center justify-center">
			<MutatingDots
				height="100"
				width="100"
				color="#7693F4"
				secondaryColor="#494C83"
				radius="12.5"
				ariaLabel="mutating-dots-loading"
				wrapperStyle={{}}
				wrapperClass=""
				visible={true}
			/>
		</div>
	);
});
