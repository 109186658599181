import React from 'react';
import {
	SypacVerticalTimeline,
	SypacVerticalTimelineStep,
} from '@sypac/component-library-react';
import UnloadingSuccessful from '../../../../../../components/Notification/UnloadingSuccessful';
import dayjs from 'dayjs';
import { useTranslate } from '@tolgee/react';

interface NotificationUnloadingSuccessfulProps {
	notification: boolean;
	date?: string;
	driver?: string;
	driverSigned?: string;
	driverDocuments?: {
		src: string;
	}[];
	client?: string;
	clientSigned?: string;
	clientDocuments?: {
		src: string;
	}[];
}
const NotificationUnloadingSuccessful = ({
	notification,
	date,
	driver,
	driverDocuments,
	driverSigned,
	clientDocuments,
	client,
	clientSigned,
}: NotificationUnloadingSuccessfulProps) => {
	const { t } = useTranslate();

	return (
		<div className="flex flex-row gap-6">
			<SypacVerticalTimeline>
				<SypacVerticalTimelineStep isActive={notification} minWidth={402}>
					{date}
				</SypacVerticalTimelineStep>
			</SypacVerticalTimeline>
			<div className="h-fit w-full">
				<UnloadingSuccessful
					notification={notification}
					title={t(
						'notificationUnloadingSuccessful.unloadingSuccessful',
						'Unloading successful. Proof of delivery documents confirmed',
					)}
					client={client}
					clientSigned={dayjs(clientSigned).format('DD MMM, YYYY — HH:mm')}
					clientDocuments={clientDocuments}
					driver={driver}
					driverSigned={dayjs(driverSigned).format('DD MMM, YYYY — HH:mm')}
					driverDocuments={driverDocuments}
				/>
			</div>
		</div>
	);
};

export default NotificationUnloadingSuccessful;
