import React, { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { CompanyWalletProps } from './CompanyWallet.interface';
import {
	SypacButton,
	SypacDropdown,
	SypacIcon,
	SypacInput,
	SypacText,
} from '@sypac/component-library-react';
import CircleValid from '../../assets/CircleValid';
import CircleClose from '../../assets/CircleClose';
import CompanyStats from '../CompanyStats/CompanyStats';
import { InvoicesTable } from '../InvoicesTable/InvoicesTable';
import Pagination from '../Pagination/Pagination';
import { PaymentPeriod } from '../PyamentPeriod';
import classNames from 'classnames';
import { T, useTranslate } from '@tolgee/react';

const creditLimits = [
	{ value: 0, label: '0 zl' },
	{ value: 10000, label: '10 000 zl' },
	{ value: 100000, label: '100 000 zl' },
	{ value: 500000, label: '500 000 zl' },
];

const paymentTerms = [
	{ value: 0, label: '0 days' },
	{ value: 5, label: '5 days' },
	{ value: 7, label: '7 days' },
	{ value: 10, label: '10 days' },
	{ value: 14, label: '14 days' },
	{ value: 30, label: '30 days' },
	{ value: 60, label: '60 days' },
	{ value: 90, label: '90 days' },
	{ value: 120, label: '120 days' },
	{ value: 180, label: '180 days' },
	{ value: 365, label: '365 days' },
];

const CompanyWallet = (props: CompanyWalletProps) => {
	const {
		company,
		stats,
		invoicesData,
		changePage,
		page,
		submitCompanyDetails,
	} = props;
	const { t } = useTranslate();
	const [editable, setEditable] = useState<boolean>(false);
	const editBankDetails = () => {
		setEditable(true);
	};

	const isCustomer = company.companyTypes?.includes('customer');
	const companyType = company.companyTypes[0];
	const formik = useFormik({
		initialValues: {
			bankAccountNumber: company?.bankDetails?.bankAccountNumber || undefined,
			swift: company?.bankDetails?.swift || undefined,
			bankName: company?.bankDetails?.bankName || undefined,
			iban: company?.bankDetails?.iban || undefined,
			paymentTerm: company?.paymentTerm || undefined,
			creditLimit: parseFloat(stats?.creditLimit) || undefined,
		},
		onSubmit: (values) => {
			setEditable(false);
			submitCompanyDetails({
				...values,
				creditLimit: values.creditLimit?.toString(),
			});
		},
		validationSchema: Yup.object({
			bankAccountNumber: Yup.string().required(
				t(
					'companyWallet.bankAccountNumberIsRequired',
					'Bank Account Number is required',
				),
			),
			swift: Yup.string().required(
				t('companyWallet.swiftIsRequired', 'SWIFT is required'),
			),
			bankName: Yup.string().required(
				t('companyWallet.bankNameIsRequired', 'Bank Name is required'),
			),
			iban: Yup.string().required(
				t('companyWallet.ibanIsRequired', 'IBAN is required'),
			),
			paymentTerm: Yup.number().required(
				t('companyWallet.paymentTermIsRequired', 'Payment Term is required'),
			),
			creditLimit: Yup.number().required(
				t('companyWallet.creditLimitIsRequired', 'Credit Limit is required'),
			),
		}),
	});

	const onSelectDropDown = (e: any) => {
		formik.setFieldValue('paymentTerm', parseInt(e?.detail?.value));
	};

	const onSelectCreditDropDown = (e: any) => {
		formik.setFieldValue('creditLimit', parseFloat(e?.detail?.value));
	};

	const setProprieties = useCallback(() => {
		formik.setFieldValue('paymentTerm', company?.paymentTerm);
		formik.setFieldValue(
			'bankAccountNumber',
			company?.bankDetails?.bankAccountNumber,
		);
		formik.setFieldValue('iban', company?.bankDetails?.iban);
		formik.setFieldValue('swift', company?.bankDetails?.swift);
		formik.setFieldValue('bankName', company?.bankDetails?.bankName);
		formik.setFieldValue('creditLimit', parseInt(stats?.creditLimit));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [company?.id, stats?.creditLimit]);

	useEffect(() => {
		setProprieties();
	}, [setProprieties]);

	const closeEdit = () => {
		setEditable(false);
		setProprieties();
	};
	return (
		<div className="flex flex-col px-10 py-6">
			<form onSubmit={formik.handleSubmit}>
				<div className="flex flex-row justify-between items-center">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">
							<T keyName="companyWallet.bankDetails">Bank details</T>
						</p>
					</SypacText>
					<div>
						{editable ? (
							<div className="flex gap-x-4">
								<SypacButton
									variant="secondary"
									size="small"
									onClick={closeEdit}
								>
									<button className="px-5 py-2 border-red" type="reset">
										<CircleClose />
										<SypacText variant="body-regular-medium" className="ml-3">
											<p className="text-red">
												<T keyName="companyWallet.cancel">Cancel</T>
											</p>
										</SypacText>
									</button>
								</SypacButton>
								<SypacButton variant="secondary" size="small">
									<button
										className="px-5 py-2 border-mountain-meadow"
										type="submit"
									>
										<CircleValid />
										<SypacText variant="body-regular-medium" className="ml-3">
											<p className="text-mountain-meadow">
												<T keyName="companyWallet.save">Save</T>
											</p>
										</SypacText>
									</button>
								</SypacButton>
							</div>
						) : (
							<SypacButton
								variant="secondary"
								size="small"
								onClick={editBankDetails}
							>
								<button className="px-5 py-1" type="reset">
									<SypacIcon
										iconName="Document Add"
										width="18px"
										height="18px"
									/>
									<SypacText variant="body-regular-medium" className="ml-3">
										<p className="text-primary-violet">
											<T keyName="companyWallet.edit">Edit</T>
										</p>
									</SypacText>
								</button>
							</SypacButton>
						)}
					</div>
				</div>
				<div className="grid grid-cols-2 gap-x-4 gap-y-5">
					<div>
						<SypacInput
							error={
								!!(
									formik.touched.bankAccountNumber &&
									formik.errors.bankAccountNumber
								)
							}
						>
							<SypacText variant="overline-regular-large">
								<p className="text-gray-80">
									<T keyName="companyWallet.bankAccountNumber">
										Bank Account Number
									</T>
								</p>
							</SypacText>
							<input
								className="py-2 px-5 border mt-1 rounded-md placeholder:text-gray-22 "
								name="bankAccountNumber"
								type="text"
								placeholder={t(
									'companyWallet.enterBankAccountNumber',
									'Enter Bank Account Number',
								)}
								value={formik?.values.bankAccountNumber}
								onChange={(event) => formik?.handleChange(event)}
								readOnly={!editable}
							/>
							{formik.touched.bankAccountNumber &&
							formik.errors.bankAccountNumber ? (
								<span className="bottom-helper">
									{formik.errors.bankAccountNumber}
								</span>
							) : null}
						</SypacInput>
					</div>

					<div>
						<SypacInput error={!!(formik.touched.swift && formik.errors.swift)}>
							<SypacText variant="overline-regular-large">
								<p className="text-gray-80">
									<T keyName="companyWallet.swift">SWIFT</T>
								</p>
							</SypacText>
							<input
								className="py-2 px-5 border mt-1 rounded-md placeholder:text-gray-22 "
								name="swift"
								type="text"
								placeholder={t('companyWallet.enterSwift', 'Enter SWIFT')}
								value={formik?.values.swift}
								onChange={(event) => formik?.handleChange(event)}
								readOnly={!editable}
							/>
							{formik.touched.swift && formik.errors.swift ? (
								<span className="bottom-helper">{formik.errors.swift}</span>
							) : null}
						</SypacInput>
					</div>

					<div>
						<SypacInput
							error={!!(formik.touched.bankName && formik.errors.bankName)}
						>
							<SypacText variant="overline-regular-large">
								<p className="text-gray-80">
									<T keyName="companyWallet.bankName">Bank Name</T>
								</p>
							</SypacText>
							<input
								className="py-2 px-5 border mt-1 rounded-md placeholder:text-gray-22 "
								name="bankName"
								type="text"
								placeholder={t(
									'companyWallet.enterBankName',
									'Enter Bank Name',
								)}
								value={formik?.values.bankName}
								onChange={(event) => formik?.handleChange(event)}
								readOnly={!editable}
							/>
							{formik.touched.bankName && formik.errors.bankName ? (
								<span className="bottom-helper">{formik.errors.bankName}</span>
							) : null}
						</SypacInput>
					</div>

					<div>
						<SypacInput error={!!(formik.touched.iban && formik.errors.iban)}>
							<SypacText variant="overline-regular-large">
								<p className="text-gray-80">
									<T keyName="companyWallet.iban">IBAN</T>
								</p>
							</SypacText>
							<input
								className="py-2 px-5 border mt-1 rounded-md placeholder:text-gray-22 "
								name="iban"
								type="text"
								placeholder={t('companyWallet.enterIban', 'Enter IBAN')}
								value={formik?.values.iban}
								onChange={(event) => formik?.handleChange(event)}
								readOnly={!editable}
							/>
							{formik.touched.iban && formik.errors.iban ? (
								<span className="bottom-helper">{formik.errors.iban}</span>
							) : null}
						</SypacInput>
					</div>

					{isCustomer ? (
						<div
							className={classNames('p-3 bg-zircon rounded-10', {
								'pointer-events-none': !editable,
							})}
						>
							<SypacText variant="overline-regular-large" className="mb-1">
								<p className="text-gray-80">
									<T keyName="companyWallet.creditLimit">Credit limit</T>
								</p>
							</SypacText>
							<SypacDropdown
								name="creditLimit"
								placeholder={t(
									'companyWallet.selectCreditLimit',
									'Select Credit Limit',
								)}
								callback={(e) => onSelectCreditDropDown(e)}
							>
								{creditLimits?.map(
									(credit: { value: number; label: string }) => {
										return (
											// @ts-ignore
											<sypac-dropdown-option
												key={credit.value}
												value={credit.value}
												label={credit.label}
												selected={credit.value === formik.values.creditLimit}
											/>
										);
									},
								)}
								{formik.touched.creditLimit && formik.errors.creditLimit ? (
									<span className="input-error">
										{formik.errors.creditLimit}
									</span>
								) : null}
							</SypacDropdown>
						</div>
					) : null}

					<div
						className={classNames('p-3 rounded-10', {
							'pointer-events-none': !editable,
							'bg-black-squeeze': isCustomer,
							'bg-zircon col-span-2': !isCustomer,
						})}
					>
						<SypacText variant="overline-regular-large" className="mb-1">
							<p className="text-gray-80">
								{isCustomer
									? t('companyWallet.paymentTerm', 'Payment term')
									: t('companyWallet.withdrawIntervals', 'Withdraw interval')}
							</p>
						</SypacText>
						<SypacDropdown
							name="paymentTerm"
							placeholder={
								isCustomer
									? t('companyWallet.selectPaymentTerm', 'Select Payment term')
									: t(
											'companyWallet.selectWithdrawIntervals',
											'Select withdraw intervals',
									  )
							}
							callback={(e) => onSelectDropDown(e)}
						>
							{paymentTerms
								.filter((term) => !(term.value === 7 && isCustomer))
								.map((term: { value: number; label: string }) => {
									return (
										// @ts-ignore
										<sypac-dropdown-option
											key={term.value}
											value={term.value}
											label={term.label}
											selected={term.value === formik.values.paymentTerm}
										/>
									);
								})}
							{formik.touched.paymentTerm && formik.errors.paymentTerm ? (
								<span className="input-error">{formik.errors.paymentTerm}</span>
							) : null}
						</SypacDropdown>
					</div>
				</div>
			</form>
			<CompanyStats stats={stats} companyType={companyType} />
			<div className="mt-6 flex flex-col justify-start">
				<div className="flex flex-row justify-between items-center px-5 py-3 border-t border-l border-r rounded-tl-10 rounded-tr-10 border-solid border-gray-10">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">
							{isCustomer
								? t('companyWallet.paymentHistory', 'Payment history')
								: t('companyWallet.withdrawHistory', 'Withdraw history')}
						</p>
					</SypacText>

					{!isCustomer ? (
						<div>
							<T keyName="companyWallet.withdrawInterval">Withdraw interval:</T>{' '}
							<PaymentPeriod value={company.paymentTerm!} />
						</div>
					) : null}
				</div>
				<div className="w-[calc(100%-2px)] ml-[1px]">
					<InvoicesTable rows={invoicesData.items} target={companyType} />
				</div>
				<div className="bg-white border border-gray-10 border-solid flex justify-between px-7 py-4 rounded-bl-10 rounded-br-10 shadow-pagination">
					<Pagination
						showText={true}
						count={invoicesData.count}
						page={page}
						onClick={(item) => changePage(item)}
					/>
				</div>
			</div>
		</div>
	);
};

export default CompanyWallet;
