import { IconInterface } from '../interfaces/icon.interface';

const FolderIcon = (props: IconInterface) => {
	const { color = '#454545' } = props;

	return (
		<svg
			width="28"
			height="26"
			viewBox="0 0 28 26"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M27 11.8V19C27 23.8 25.7 25 20.5 25H7.5C2.3 25 1 23.8 1 19V7C1 2.2 2.3 1 7.5 1H9.45C11.4 1 11.829 1.528 12.57 2.44L14.52 4.84C15.014 5.44 15.3 5.8 16.6 5.8H20.5C25.7 5.8 27 7 27 11.8Z"
				stroke={color}
				strokeMiterlimit="10"
			/>
			<path
				opacity="0.4"
				d="M8.80078 1H20.5008C23.1008 1 24.4008 2.2 24.4008 4.6V6.256"
				stroke={color}
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default FolderIcon;
