import React from 'react';
import Modal from 'react-modal';
import {
	SypacButton,
	SypacCheckbox,
	SypacText,
} from '@sypac/component-library-react';
import Close from '../../../../assets/Close';
import AlertBig from '../../../../assets/AlertBig';
import { Checkmark } from '../../../../assets/Checkmark';
import { T } from '@tolgee/react';

export interface ModalSelfPickupProps {
	isOpen: boolean;
	onClose: () => void;
	onSave: () => void;
	dontShowAnymore: () => void;
	isChecked: boolean;
}

const ModalSelfPickup = (props: ModalSelfPickupProps) => {
	const { isOpen, onClose, onSave, dontShowAnymore, isChecked } = props;

	return (
		<>
			<Modal
				isOpen={isOpen}
				onRequestClose={() => onSave()}
				className="modal-inside outline-none w-[516px] h-[379px]"
				overlayClassName="modal-overlay"
				shouldCloseOnOverlayClick={false}
			>
				<div className="flex flex-col h-full">
					<div className="flex items-center justify-between px-3 py-4">
						<SypacText variant="body-regular-medium">
							<p className="text-gray-90">
								<T keyName="modalSelfPickup.selfPickupOption">
									Self-Pickup Option
								</T>
							</p>
						</SypacText>
						<SypacButton variant="subTitle">
							<button
								type="button"
								className="flex justify-center items-center h-[32px] w-[32px] p-0 bg-white border border-solid border-gray-10 transition hover:border-gray-60 rounded-lg"
								onClick={onClose}
							>
								<Close width="10" height="10" />
							</button>
						</SypacButton>
					</div>

					<div className="border border-solid border-gray-10 border-t-0 border-l-0 border-r-0" />

					<div className="flex flex-col gap-8 px-3 mt-auto">
						<AlertBig />
						<span className="flex flex-col gap-2">
							<SypacText variant="body-regular-medium">
								<p className="text-xl text-gray-90">
									<T keyName="modalSelfPickup.optingForChoice">
										Opting for this choice means you'll be responsible for
										arranging the pickup of your order.
									</T>
								</p>
							</SypacText>
							<SypacText variant="body-regular-medium">
								<p className="text-sm text-gray-40">
									<T keyName="modalSelfPickup.necessaryArrangements">
										Ensure you have the necessary arrangements in place for
										transportation.
									</T>
								</p>
							</SypacText>
						</span>
					</div>

					<div className="mt-auto">
						<div className="border border-solid border-gray-10 border-t-0 border-l-0 border-r-0" />
						<div className="flex w-full px-3 py-3 gap-4 [&_button]:transition box-border">
							<SypacButton variant="primary" size="small" className="w-full">
								<button className="w-full py-2.75 rounded-lg" onClick={onSave}>
									<Checkmark />
									<SypacText variant="body-regular-medium">
										<p className="ml-3 text-white">
											<T keyName="modalSelfPickup.confirm">Confirm</T>
										</p>
									</SypacText>
								</button>
							</SypacButton>
						</div>

						<div className="flex gap-2 px-3 pb-3">
							<SypacCheckbox size="md">
								<input
									type="checkbox"
									name="unlimited"
									checked={isChecked}
									onChange={dontShowAnymore}
									className="cursor-pointer"
								/>
							</SypacCheckbox>
							<SypacText variant="body-regular-medium">
								<p>
									<T keyName="modalSelfPickup.dontShowAnymore">
										Don't show anymore
									</T>
								</p>
							</SypacText>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default ModalSelfPickup;
