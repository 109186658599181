const BorderLine = ({
	orientation = 'horizontal',
	color = '#E8E8E8',
	thickness = 2,
}) => {
	const isHorizontal = orientation === 'horizontal';

	return (
		<svg
			width={isHorizontal ? '100%' : `${thickness}px`}
			height={isHorizontal ? `${thickness}px` : '100%'}
			xmlns="http://www.w3.org/2000/svg"
		>
			<line
				x1="0"
				y1="0"
				x2={isHorizontal ? '100%' : '0'}
				y2={isHorizontal ? '0' : '100%'}
				stroke={color}
				strokeWidth={thickness}
			/>
		</svg>
	);
};

export default BorderLine;
