import React from 'react';
import dayjs from 'dayjs';
import {
	SypacVerticalTimeline,
	SypacVerticalTimelineStep,
} from '@sypac/component-library-react';
import DriverMultipleNotification from '../../../../../../components/Notification/DriverMultipleNotification';
import { ProductInterface } from '../../../../../../interfaces/product.interface';
import { useTranslate } from '@tolgee/react';

interface NotificationDriverUnloadedProps {
	notification: boolean;
	date?: string;
	unloadingPlace?: string;
	product?: Partial<ProductInterface>;
	quantity?: string | number;
	unloadingDate?: string;
}
const NotificationDriverUnloaded = ({
	notification,
	date,
	product,
	quantity,
	unloadingDate,
	unloadingPlace,
}: NotificationDriverUnloadedProps) => {
	const { t } = useTranslate();

	return (
		<div className="flex flex-row gap-6">
			<SypacVerticalTimeline>
				<SypacVerticalTimelineStep isActive={notification} minWidth={262}>
					{date}
				</SypacVerticalTimelineStep>
			</SypacVerticalTimeline>
			<div className="h-fit w-full">
				<DriverMultipleNotification
					notification={notification}
					title={t(
						'notificationDriverUnloaded.driverUnloaded',
						'The driver has been unloaded',
					)}
					description={t(
						'notificationDriverUnloaded.pendingUploadingDeliveryDocuments',
						'Pending uploading delivery documents.',
					)}
					unloadingPlace={unloadingPlace}
					product={product}
					quantity={quantity}
					unloadingDate={dayjs(unloadingDate).format('DD MMM, YYYY — HH:mm')}
				/>
			</div>
		</div>
	);
};

export default NotificationDriverUnloaded;
