import { IconInterface } from '../interfaces/icon.interface';

const Download = (props: IconInterface) => {
	const { color = '#454545' } = props;

	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1 10.334C1 12.5339 1 13.6338 1.68342 14.3172C2.36684 15.0007 3.46678 15.0007 5.66667 15.0007H10.3333C12.5332 15.0007 13.6332 15.0007 14.3166 14.3172C15 13.6338 15 12.5339 15 10.334"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7.99978 1.00022V11.1113M7.99978 11.1113L11.1109 7.70855M7.99978 11.1113L4.88867 7.70855"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default Download;
