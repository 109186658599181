import React, {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { SypacTabs, SypacText } from '@sypac/component-library-react';
import useDebounce from '../../../hooks/useDebounce';
import AvatarDropDown from '../../../components/AvatarDropDown/AvatarDropDown';
import { MutatingDots } from 'react-loader-spinner';
import { OrdersTable } from '../../../components/OrdersTable/OrdersTable';
import { LIMIT } from '../../../constants';
import {
	NotificationCategory,
	NotificationInterface,
	OrderInterface,
} from '../../../components/OrdersTable/interfaces/Order.interface';
import { AvatarItem } from '../../../components/AvatarDropDown/AvatarDropDown.interface';
import { AuthContext } from '../../../context/context';
import { OrderContext } from '../../../context/OrderContext/order.context';
import { OrdersService } from '../../../services/orders.services';
import { UsersInterface } from '../../../interfaces/Users.interface';
import OrderDetails from '../../Admin/OrderDetails';
import Pagination from '../../../components/Pagination/Pagination';
import Emitter, { EventType } from '../../../services/events';
import { OrderTarget } from '../../../components/OrdersTable/interfaces/OrderStatus.interface';
import { T, useTranslate } from '@tolgee/react';
import { useLocation } from 'react-router-dom';
import { TabsButton } from '../../../components/TabsButton/TabsButton';
import { NotificationsService } from '../../../services/notifications.services';

const Orders: React.FC = () => {
	const { t } = useTranslate();
	const [searchLocal] = useState('');
	const [orders, setOrders] = useState<OrderInterface[]>([]);
	const { state } = useLocation();
	const [isOpen, setOpenDetails] = useState<boolean>(false);
	const [isAssigned] = useState<boolean | undefined>();
	const [selectedOrder, setOrder] = useState<OrderInterface | undefined>(
		undefined,
	);
	const [page, setPage] = useState<number>(0);
	const [count, setCount] = useState<number>(0);
	const [users, setUsers] = useState<AvatarItem[]>([]);
	const [assigneeIds, setAssigneeIds] = useState<string[]>([]);
	const [statuses, setStatuses] = useState<string[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [selectedTab, setSelectedTab] = useState<string>('all');
	const [notifications, setNotifications] = useState<any>({});
	const { user } = useContext(AuthContext);
	const { setOrderId, additional } = useContext(OrderContext);
	const tableRef = useRef<HTMLDivElement>(null);
	const detailsRef = useRef<HTMLDivElement>(null);
	const searchQuery = useDebounce(searchLocal, 500);
	const showNotifications = useDebounce(notifications, 800);

	const getNotifications = useCallback(async () => {
		if (!user?.uid) return;

		try {
			const { data } = await NotificationsService.getCount({
				recipientId: user.uid,
				limit: 500,
			});
			const notifies = Array.from(
				new Map(
					data.items
						.filter(
							(item) => item.category !== NotificationCategory.NEW_PRODUCT,
						)
						.map((item) => [item.groupId, item]),
				).values(),
			);

			if (notifies) {
				const statuses: any = {};

				for (const notify of notifies) {
					const orderId = parseInt(notify.groupId);
					let data: OrderInterface | undefined;
					const respOrder = await OrdersService.getOrder(
						orderId,
						OrderTarget.admin,
					);
					data = respOrder.data;
					statuses[data.status] = (statuses[data.status] || 0) + 1;
				}

				setNotifications(statuses);
			}
		} catch (e) {}
	}, [user?.uid]);

	useEffect(() => {
		Emitter.on(EventType.NOTIFICATION_COUNT, () => {
			getNotifications();
		});
		Emitter.on(EventType.NOTIFICATION_COUNT_DECREASE, (count) => {
			setNotifications((prev: any) => (prev - count >= 0 ? prev - count : 0));
			getNotifications();
		});

		return () => {
			Emitter.off(EventType.NOTIFICATION_COUNT);
			Emitter.off(EventType.NOTIFICATION_COUNT_DECREASE);
		};
	}, [getNotifications]);

	useEffect(() => {
		getNotifications().then(() => {});
	}, [getNotifications]);

	const getOrdersCallback = useCallback(async () => {
		try {
			setIsLoading(true);
			const query = {
				assigneeIds,
				statuses,
				searchQuery: searchQuery,
				limit: LIMIT,
				offset: page * LIMIT,
				isAssigned,
			};
			const { data } = await OrdersService.getOrders(query, 'admin');
			setOrders((data.items as unknown as OrderInterface[]) || []);
			setCount(data.count || 0);
		} catch (e) {
			console.log(e);
		} finally {
			setIsLoading(false);
		}
	}, [assigneeIds, isAssigned, page, searchQuery, statuses]);

	useEffect(() => {
		getOrdersCallback();
	}, [getOrdersCallback]);

	const rowClick = useCallback(
		async (order: Partial<OrderInterface>) => {
			try {
				const { data } = await OrdersService.getOrder(
					order.id!,
					OrderTarget.admin,
				);
				setOrderId(order.id);
				setOrder(data as unknown as OrderInterface);
				setOpenDetails(true);
			} catch (e) {
				console.log(e);
			}
		},
		[setOrderId],
	);

	useEffect(() => {
		if (state && state.orderId) {
			rowClick({ id: parseInt(state.orderId, 10) });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state]);

	useEffect(() => {
		if (additional && additional.order) {
			rowClick(additional.order);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [additional]);

	useEffect(() => {
		Emitter.on(EventType.ORDER_REFRESH, (items: NotificationInterface[]) => {
			const opened = items.some(
				(r) => r.groupId === selectedOrder?.id.toString(),
			);
			if (opened && selectedOrder?.id) {
				rowClick(selectedOrder);
			}
		});
		return () => {
			Emitter.off(EventType.ORDER_REFRESH);
		};
	}, [rowClick, selectedOrder]);

	useEffect(() => {
		if (user) {
			const me = user as UsersInterface;
			setUsers([
				{ id: me.uid, fullName: me.name || me.email, avatarUrl: me.avatarUrl },
			]);
		}
	}, [user]);

	const changeUser = (id: string) => {
		const newAssignee = assigneeIds.includes(id)
			? assigneeIds.filter((item) => item !== id)
			: [...assigneeIds, id];
		setAssigneeIds(newAssignee);
	};

	const closeModal = () => {
		setOrder(undefined);
		setOpenDetails(false);
	};

	const setCurrentTab = (statuses: string[]) => {
		setStatuses(statuses);
		setPage(0);
	};

	const getOrderCallback = useCallback(
		async (notify: NotificationInterface) => {
			try {
				const orderId = parseInt(notify.groupId);
				const { data } = await OrdersService.getOrder(
					orderId,
					OrderTarget.admin,
				);
				if (orderId === selectedOrder?.id) {
					setOrder({
						...data,
						notificationCount: 0,
					} as unknown as OrderInterface);
				}
				let newOrders = [];
				if (notify.category === NotificationCategory.NEW_ORDER) {
					const filterData = orders.filter((r) => r.id !== data.id);
					newOrders = [{ ...data, notificationCount: 1 }, ...filterData];
				} else {
					newOrders = orders.map((order) => {
						if (order.id === orderId) {
							const notificationFilter = data.notifications.items.filter(
								(r) => !r.readAt,
							);
							return {
								...order,
								...data,
								notificationCount:
									orderId === selectedOrder?.id ? 0 : notificationFilter.length,
							};
						}
						return order;
					});
				}
				setOrders(newOrders as unknown as OrderInterface[]);
			} catch (e) {
				console.log(e);
			} finally {
				setIsLoading(false);
			}
		},
		[orders, selectedOrder?.id],
	);

	useEffect(() => {
		Emitter.on(
			EventType.ORDER_LIST_REFRESH,
			(notify: NotificationInterface) => {
				if (notify && notify.groupId) {
					getOrderCallback(notify);
				}
			},
		);
		return () => {
			Emitter.off(EventType.ORDER_LIST_REFRESH);
		};
	}, [getOrderCallback]);

	const decreaseNotification = useCallback(
		(orderId: number) => {
			const newOrders = orders.map((order) => {
				const notificationCount = order.notificationCount ?? 0;

				if (order.id === orderId && notificationCount > 0) {
					const updatedDate = new Date()
						.toUTCString()
						.replace('GMT', 'GMT+0000 (Coordinated Universal Time)');

					return {
						...order,
						notificationCount: 0,
						updatedAt: updatedDate,
					};
				}
				return order;
			});
			setOrders(newOrders as unknown as OrderInterface[]);
		},
		[orders],
	);

	useEffect(() => {
		Emitter.on(
			EventType.ORDER_NOTIFICATION_COUNT_DECREASE,
			(orderId: number) => {
				decreaseNotification(orderId);
			},
		);

		return () => {
			Emitter.off(EventType.ORDER_NOTIFICATION_COUNT_DECREASE);
		};
	}, [decreaseNotification]);

	const clickOutsideDetails = useCallback((event: MouseEvent) => {
		const toastContainer = document.querySelector('.Toastify');

		if (
			!detailsRef.current?.contains(event.target as Node) &&
			!tableRef.current?.contains(event.target as Node) &&
			!additional.orderModalRef.current?.contains(event.target as Node) &&
			!(toastContainer && toastContainer.contains(event.target as Node))
		) {
			closeModal();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		document.addEventListener('mousedown', clickOutsideDetails);
		return () => {
			document.removeEventListener('mousedown', clickOutsideDetails);
		};
	}, [clickOutsideDetails]);

	const sortedOrders = orders.sort((a, b) => {
		if (a.notificationCount! > 0 && b.notificationCount! > 0) {
			return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
		}

		if (a.notificationCount! > 0) return -1;
		if (b.notificationCount! > 0) return 1;

		return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
	});

	return (
		<>
			{/*<div className="flex flex-col gap-2.5 w-[270px] flex-none">
				<div className="border border-solid relative border-gray-40 rounded-xl py-3 px-5 flex items-center">
					<div className="flex w-full">
						<button className="flex items-center border-0 p-0 bg-transparent cursor-pointer">
							<SypacIcon
								icon-name="Magnifer"
								className="text-gray-40"
								size="custom"
								width="20px"
								height="20px"
							/>
						</button>
						<input
							className="flex items-center text-base placeholder:text-gray-40 py-0 px-2.5 w-full outline-none border-0 bg-transparent"
							placeholder={t('orders.search', 'Search')}
							type="text"
							onChange={setSearch}
						/>
					</div>
					<button className="flex items-center border-0 p-0 bg-transparent cursor-pointer">
						<SypacIcon
							icon-name="Tuning 2"
							className="text-gray-40"
							size="custom"
							width="22px"
							height="22px"
						/>
					</button>
				</div>
				<div className="border border-solid border-gray-10 rounded-xl h-full">
					<Menu changeStatus={changeStatus} status={status} />
				</div>
			</div>*/}

			<div className="flex flex-col gap-5 w-[calc(100vw-67px)] xl-2xl:w-[calc(100vw-83px)] h-full relative">
				<div className="hidden xl:flex flex-col gap-4">
					<div className="flex flex-col gap-4 h-[78px] ml-5">
						<SypacText variant="body-regular-large">
							<p className="text-gray-80 text-3xl tracking-tighter">
								<T keyName="ordersDashboard.orderListDashboard">
									Order list dashboard
								</T>
							</p>
						</SypacText>
						<SypacText variant="body-regular-large">
							<p className="text-gray-40 text-base tracking-tighter">
								<T keyName="ordersDashboard.trackYourOrdersFromProcessingDelivery">
									Track your orders, from processing to delivery.
								</T>
							</p>
						</SypacText>
					</div>
					<div className="border border-solid border-gray-10 border-t-0 border-l-0 border-r-0" />
				</div>

				<div className="flex items-center gap-6 2xl:gap-12 max-w-[1047px] ml-5">
					<SypacTabs className="flex w-fit p-[3px] gap-[4px]">
						{[
							{ label: t('orders.all', 'All'), buttonId: 'all', statuses: [] },
							{
								label: t('orders.toDo', 'To do'),
								buttonId: 'to_do',
								statuses: ['to_do'],
							},
							{
								label: t('orders.inProgress', 'Preparing order'),
								buttonId: 'progress',
								statuses: [
									'in_progress',
									'transporter_confirmed',
									'truck_confirmed',
									'to_loading',
									'to_unloading',
									'awaiting_confirmation',
								],
							},
							// {
							// 	label: t('orders.shipped', 'Shipped'),
							// 	buttonId: 'shipped',
							// 	statuses: ['shipped'],
							// },
							{
								label: t('orders.delivered', 'Delivered'),
								buttonId: 'delivered',
								statuses: ['delivered'],
							},
							{
								label: t('orders.completed', 'Completed'),
								buttonId: 'completed',
								statuses: ['paid'],
							},
							// {
							// 	label: t('orders.canceled', 'Canceled'),
							// 	buttonId: 'canceled',
							// 	statuses: ['canceled'],
							// },
						].map(({ label, buttonId, statuses }) => (
							<TabsButton
								key={buttonId}
								label={label}
								buttonId={buttonId}
								onClick={() => setCurrentTab(statuses)}
								isSelected={selectedTab === buttonId}
								handleSelectedTab={() => setSelectedTab(buttonId)}
								notification={
									statuses.length && showNotifications
										? statuses.reduce(
												(total, status) =>
													total + (showNotifications?.[status] || 0),
												0,
										  )
										: Object.values(showNotifications).reduce<number>(
												(sum, count) => sum + (count as number),
												0,
										  )
								}
							/>
						))}
					</SypacTabs>

					<div className="h-[40px] -mt-[6px] flex-none">
						<div className="flex flex-row justify-end">
							<AvatarDropDown items={users} onChange={changeUser} />
						</div>
					</div>
				</div>

				{isLoading ? (
					<div className="flex w-full h-full items-center justify-center">
						<MutatingDots
							height="100"
							width="100"
							color="#7693F4"
							secondaryColor="#494C83"
							radius="12.5"
							ariaLabel="mutating-dots-loading"
							wrapperStyle={{}}
							wrapperClass=""
							visible={true}
						/>
					</div>
				) : (
					<div className="h-full relative ml-5 mr-7.5 border border-solid border-gray-10 rounded-10 overflow-hidden whitespace-nowrap">
						<div
							className="w-full h-[calc(100%-56px)] overflow-y-auto scroll-smooth pr-[5px]"
							ref={tableRef}
						>
							<OrdersTable
								rows={sortedOrders}
								target={OrderTarget.admin}
								rowClick={rowClick}
								search={searchQuery}
							/>
						</div>
						<div className="w-full absolute bottom-0 border border-solid border-gray-10 border-l-0 border-r-0 border-b-0 shadow-pagination">
							<div className="flex justify-between items-center h-[56px] px-4">
								<Pagination
									count={count}
									page={page}
									onClick={(item) => setPage(item)}
								/>
							</div>
						</div>
					</div>
				)}
			</div>
			<div ref={detailsRef}>
				<OrderDetails
					isOpen={!!(isOpen && selectedOrder?.id)}
					selectedOrder={selectedOrder}
				/>
			</div>
		</>
	);
};

export default Orders;
