import React from 'react';
import {
	SypacVerticalTimeline,
	SypacVerticalTimelineStep,
} from '@sypac/component-library-react';
import { useTranslate } from '@tolgee/react';
import DriverMultipleNotification from '../../../../../../components/Notification/DriverMultipleNotification';

interface NotificationDriverArrivedLoadingProps {
	notification: boolean;
	date: string;
	loadingPlace: string;
}

const NotificationDriverArrivedLoading = ({
	notification,
	date,
	loadingPlace,
}: NotificationDriverArrivedLoadingProps) => {
	const { t } = useTranslate();

	return (
		<div className="flex flex-row gap-6">
			<SypacVerticalTimeline>
				<SypacVerticalTimelineStep isActive={notification} minWidth={120}>
					{date}
				</SypacVerticalTimelineStep>
			</SypacVerticalTimeline>
			<div className="h-fit w-full">
				<DriverMultipleNotification
					notification={notification}
					title={t(
						'timeline.driverArrivedLoadingPlace',
						'The driver arrived at the loading place',
					)}
					description={t(
						'timeline.pendingLoadingGoods',
						'Pending loading of goods.',
					)}
					loadingPlace={loadingPlace}
				/>
			</div>
		</div>
	);
};

export default NotificationDriverArrivedLoading;
