import Api from '../axios/api';

export interface NotificationDto {
	id: string;
	recipientId: string;
	groupId: string;
	content?: any;
	category: string;
	readAt?: string | undefined;
	canceledAt?: string | undefined;
	createdAt: string;
}

export interface NotificationResponse {
	count: number;
	items: NotificationDto[];
}

export interface NotificationListResponse {
	data: NotificationResponse;
}

export interface QueryParams {
	limit?: number;
	offset?: number;
	recipientId?: string;
	category?: string;
	groupId?: string;
}

export class NotificationsService {
	static getCount(params: QueryParams): Promise<NotificationListResponse> {
		return Api.get(`${process.env.REACT_APP_NOTIFY_URL}v1/notifications`, {
			params,
		});
	}

	static readNotify(ids: string[]): Promise<NotificationListResponse> {
		return Api.post(
			`${process.env.REACT_APP_NOTIFY_URL}v1/notifications/read`,
			{
				ids,
			},
		);
	}
}
