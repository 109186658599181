import React, { useEffect, useState } from 'react';
import { SypacText } from '@sypac/component-library-react';
import { DaysSelect } from './Selectors/Days/DaysSelect';
import { LocationInput } from './components/LocationInput';
import { T, useTranslate } from '@tolgee/react';
import {
	ProductLocationProps,
	VariantInterface,
} from './ProductLocation.interface';
import { HoursSelect } from './Selectors/Hours/HoursSelect';

export const ProductLocation = ({
	setLocation,
	errorLocation,
	errorPickupAvailability,
	errorWorkingHours,
	defaultValue,
	addWorkHours,
	addPickupDays,
}: ProductLocationProps) => {
	const { t } = useTranslate();
	const daysAndHoursSubmenu = [
		{ title: t('productLocation.monday', 'Monday'), variant: 'monday' },
		{ title: t('productLocation.tuesday', 'Tuesday'), variant: 'tuesday' },
		{
			title: t('productLocation.wednesday', 'Wednesday'),
			variant: 'wednesday',
		},
		{ title: t('productLocation.thursday', 'Thursday'), variant: 'thursday' },
		{ title: t('productLocation.friday', 'Friday'), variant: 'friday' },
		{ title: t('productLocation.saturday', 'Saturday'), variant: 'saturday' },
		{ title: t('productLocation.sunday', 'Sunday'), variant: 'sunday' },
	];
	const daysMenu = [
		{
			title: t('productLocation.businessDays', 'Business days'),
			variant: 'business_days',
		},
		{ title: t('productLocation.fullWeek', 'Full week'), variant: 'full_week' },
		{
			title: t('productLocation.custom', 'Custom'),
			variant: 'custom',
			submenu: daysAndHoursSubmenu,
		},
	];
	const hoursMenu = [
		{ title: t('productLocation.fullTime', 'Full Time'), variant: 'full_time' },
		{
			title: t('productLocation.fromHoursToHours', 'From 9:00 to 18:00'),
			variant: 'working_hours',
		},
		{
			title: t('productLocation.custom', 'Custom'),
			variant: 'custom',
			submenu: daysAndHoursSubmenu,
		},
	];
	const [pickupDays, setPickupDays] = useState<VariantInterface[]>([
		{
			title: t('productLocation.selectAvailability', 'Select availability'),
			variant: 'days',
		},
	]);
	const [workHours, setWorkHours] = useState<VariantInterface[]>([
		{ title: t('productLocation.selectTime', 'Select time'), variant: 'hours' },
	]);

	useEffect(() => {
		const exist = pickupDays.some((r) => r.variant !== 'days');
		if (exist) {
			addPickupDays(exist);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pickupDays]);

	useEffect(() => {
		const exist = workHours.some((r) => r.variant !== 'hours');
		if (exist) {
			addWorkHours(exist);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [workHours]);

	const handleCheckedDays = (data: VariantInterface) => {
		setPickupDays([data]);
		if (data.variant === 'custom') {
			setWorkHours([data]);
		}
	};

	const handleCheckedHours = (data: VariantInterface) => {
		setWorkHours([data]);
		if (data.variant === 'custom') {
			setPickupDays([data]);
		}
	};

	return (
		<div className="flex flex-col gap-4 p-3 border border-solid border-gray-10 rounded-10 bg-alabaster mx-3">
			<SypacText variant="body-regular-medium">
				<p className="text-sm text-gray-40">
					<T keyName="productLocation.location">1 — Location:</T>
				</p>
			</SypacText>

			<div className="grid grid-cols-3 gap-3">
				<div className="w-full">
					<SypacText className="mb-1" variant="overline-normal-large">
						<p>
							<T keyName="productLocation.selectLocationProduct">
								Select location for the product
							</T>{' '}
							<span className="text-red">*</span>
						</p>
					</SypacText>

					<LocationInput
						setLocation={(item) => setLocation && setLocation(item)}
						defaultValue={defaultValue}
						role="producer"
						errorLocation={errorLocation}
					/>
					{errorLocation ? (
						<span className="input-error">
							<T keyName="productLocation.errorLocation">{errorLocation}</T>
						</span>
					) : null}
				</div>

				<div className="w-full">
					<SypacText className="mb-1" variant="overline-normal-large">
						<p>
							<T keyName="productLocation.productPickupAvailability">
								Product pickup availability
							</T>{' '}
							<span className="text-red">*</span>
						</p>
					</SypacText>

					<DaysSelect
						daysMenu={daysMenu}
						pickupDays={pickupDays}
						workHours={workHours}
						handleCheckedDays={handleCheckedDays}
						isError={!!errorPickupAvailability}
					/>
					{errorPickupAvailability ? (
						<span className="input-error">
							<T keyName="productLocation.errorProductPickupAvailability">
								{errorPickupAvailability}
							</T>
						</span>
					) : null}
				</div>

				<div className="w-full">
					<SypacText className="mb-1" variant="overline-normal-large">
						<p>
							<T keyName="productLocation.workingHours">Working hours</T>{' '}
							<span className="text-red">*</span>
						</p>
					</SypacText>

					<HoursSelect
						hoursMenu={hoursMenu}
						pickupDays={pickupDays}
						workHours={workHours}
						handleCheckedHours={handleCheckedHours}
						isError={!!errorWorkingHours}
					/>
					{errorWorkingHours ? (
						<span className="input-error">
							<T keyName="productLocation.errorProductWorkingHours">
								{errorWorkingHours}
							</T>
						</span>
					) : null}
				</div>
			</div>
		</div>
	);
};
