import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
	SypacButton,
	SypacIcon,
	SypacText,
} from '@sypac/component-library-react';
import { OrderInterface } from '../../../components/OrdersTable/interfaces/Order.interface';
import {
	OrderStatesEnum,
	OrderStatusesEnum,
} from '../../../services/orders.services';
import { NotificationsService } from '../../../services/notifications.services';
import Emitter, { EventType } from '../../../services/events';
import Paid from './components/Steps/Paid';
import { T, useTranslate } from '@tolgee/react';
import Notifications from './components/Notifications';
import DocumentsTab from '../../../components/DocumentsTab/DocumentsTab';
import AssignOrder from './components/Steps/AssignOrder';
import InProgress from './components/Steps/InProgress';
import ConfirmPickUp from './components/Steps/ConfirmPickUp';
import PendingPayment from './components/Steps/PendingPayment';
import { OrderDetailsProps } from './producerOrder.interface';

const OrderDetails: React.FC<OrderDetailsProps> = ({
	isOpen = false,
	selectedOrder,
}) => {
	const { t } = useTranslate();
	const [order, setOrder] = useState<OrderInterface | undefined>(selectedOrder);
	const [currentTab, setCurrentTab] = useState<string>('overview');

	const notifications = useMemo(
		() => selectedOrder?.notifications?.items ?? [],
		[selectedOrder?.notifications?.items],
	);

	const selectOrder = (order: OrderInterface) => {
		setOrder(order);
	};

	useEffect(() => {
		setOrder(selectedOrder);
		setCurrentTab('overview');
	}, [selectedOrder]);

	const handleClick = (tab: string) => {
		setCurrentTab(tab);
	};

	const markReadNotifications = useCallback(async () => {
		const notificationIds = order?.notifications?.items
			?.filter((r) => !r.readAt)
			?.map((r) => r.id);
		try {
			if (notificationIds?.length) {
				await NotificationsService.readNotify(notificationIds);
				Emitter.emit(EventType.NOTIFICATION_COUNT_DECREASE, 1);
			}
			Emitter.emit(EventType.ORDER_NOTIFICATION_COUNT_DECREASE, order?.id!);
		} catch (e) {}
	}, [order]);

	useEffect(() => {
		if (order?.id) {
			markReadNotifications().then(() => {});
		}
	}, [markReadNotifications, order?.id]);

	const renderSimple = (order: OrderInterface) => {
		if (
			[OrderStatesEnum.ARRIVED_TO_LOADING, OrderStatesEnum.LOADING].includes(
				order.state,
			)
		) {
			return <ConfirmPickUp selectedOrder={order} setOrder={selectOrder} />;
		}

		switch (order.status) {
			case OrderStatesEnum.TO_DO:
				return <AssignOrder selectedOrder={order} setOrder={selectOrder} />;
			case OrderStatesEnum.IN_PROGRESS:
				return <InProgress selectedOrder={order} setOrder={selectOrder} />;
			case OrderStatusesEnum.PENDING_PAYMENT:
				return <PendingPayment selectedOrder={order} />;
			case OrderStatusesEnum.PAID:
				return <Paid selectedOrder={order} />;
			default:
				return null;
		}
	};

	const renderExcluded = (order: OrderInterface) => {
		const statusComponentMap: { [key: string]: JSX.Element } = {
			[OrderStatesEnum.TO_DO]: (
				<AssignOrder selectedOrder={order} setOrder={selectOrder} />
			),
			[OrderStatesEnum.IN_PROGRESS]: (
				<InProgress selectedOrder={order} setOrder={selectOrder} />
			),
			[OrderStatusesEnum.CONFRIM_PICKUP]: (
				<ConfirmPickUp selectedOrder={order} setOrder={selectOrder} />
			),
			[OrderStatusesEnum.PENDING_PAYMENT]: (
				<PendingPayment selectedOrder={order} />
			),
			[OrderStatusesEnum.PAID]: <Paid selectedOrder={order} />,
		};

		return statusComponentMap[order.status] || null;
	};

	const renderOverviewTab = (order: OrderInterface | undefined) => {
		if (!order) return null;
		const { deliveryIncluded } = order;

		return deliveryIncluded ? renderSimple(order) : renderExcluded(order);
	};

	return (
		<>
			{isOpen ? (
				<div className="w-[716px] h-full flex flex-col absolute top-0 right-0 bg-alabaster shadow-order-details z-[100] overflow-y-auto scroll-smooth rounded-2xl">
					<div className="px-10 py-7 flex gap-[3px]">
						<SypacButton variant="secondary" size="small" className="w-full">
							<button
								className={`w-full h-[40px] rounded-lg flex gap-2 items-center border-none transition group ${
									currentTab === 'overview'
										? 'bg-primary-violet text-white'
										: 'hover:bg-gray-10 bg-alabaster text-gray-80'
								}`}
								onClick={() => handleClick('overview')}
							>
								<SypacIcon
									iconName="Document Text"
									size="custom"
									width="24px"
									height="24px"
									className="mb-[2px]"
								/>
								<SypacText variant="body-regular-medium">
									<p>
										<T keyName="tabButton.general">General</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
						<SypacButton variant="secondary" size="small" className="w-full">
							<button
								className={`w-full h-[40px] rounded-lg flex gap-2 items-center border-none transition group ${
									currentTab === 'documents'
										? 'bg-primary-violet text-white'
										: 'hover:bg-gray-10 bg-alabaster text-gray-80'
								}`}
								onClick={() => setCurrentTab('documents')}
							>
								<SypacIcon
									iconName="Folder"
									size="custom"
									width="24px"
									height="24px"
									className="mb-[2px]"
								/>
								<SypacText variant="body-regular-medium">
									<p>
										<T keyName="tabButton.documents">Documents</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
						<SypacButton variant="secondary" size="small" className="w-full">
							<button
								className={`w-full h-[40px] rounded-lg flex gap-2 items-center border-none transition group ${
									currentTab === 'events'
										? 'bg-primary-violet text-white'
										: 'hover:bg-gray-10 bg-alabaster text-gray-80'
								}`}
								onClick={() => setCurrentTab('events')}
							>
								<SypacIcon
									iconName="Bell"
									size="custom"
									width="24px"
									height="24px"
									className="mb-[2px]"
								/>
								<SypacText variant="body-regular-medium" className="flex gap-4">
									<p>
										<T keyName="tabButton.events">Events</T>
									</p>
									{notifications.length > 1 ? (
										<div
											className={`w-fit px-2 flex justify-center rounded-full text-gray-80 transition ${
												currentTab === 'events'
													? 'bg-white'
													: 'bg-gray-10 group-hover:bg-white'
											}`}
										>
											{notifications.length - 1}
										</div>
									) : null}
								</SypacText>
							</button>
						</SypacButton>
					</div>
					<div className="flex flex-col h-full overflow-x-hidden overflow-y-scroll sypac-scrollbar">
						{currentTab === 'overview' && renderOverviewTab(order)}

						{currentTab === 'events' ? (
							<div className="flex flex-col gap-8 px-10 py-7 border-0 border-solid border-t-[1px] border-gray-10">
								<SypacText variant="body-regular-medium">
									<p className="text-base leading-6 tracking-tighter text-gray-40">
										{t('awaitingTransport.timeline', 'Timeline')}
									</p>
								</SypacText>
								<div className="flex flex-col">
									<Notifications selectedOrder={order} />
								</div>
							</div>
						) : null}

						{currentTab === 'documents' ? (
							<div className="flex flex-col gap-8 pt-8 border-0 border-solid border-t-[1px] border-gray-10">
								<DocumentsTab selectedOrder={order} />
							</div>
						) : null}
					</div>
				</div>
			) : null}
		</>
	);
};

export default OrderDetails;
