import { IconInterface } from '../interfaces/icon.interface';

const MoonIcon = (props: IconInterface) => {
	const { color = '#A2A2A2' } = props;

	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M16.2538 8.88548L15.6119 8.49759L15.6119 8.49759L16.2538 8.88548ZM9.11452 1.74621L8.72664 1.1043L8.72664 1.1043L9.11452 1.74621ZM16.25 9C16.25 13.0041 13.0041 16.25 9 16.25V17.75C13.8325 17.75 17.75 13.8325 17.75 9H16.25ZM9 16.25C4.99594 16.25 1.75 13.0041 1.75 9H0.25C0.25 13.8325 4.16751 17.75 9 17.75V16.25ZM1.75 9C1.75 4.99594 4.99594 1.75 9 1.75V0.25C4.16751 0.25 0.25 4.16751 0.25 9H1.75ZM11.8 10.65C9.34233 10.65 7.35 8.65767 7.35 6.2H5.85C5.85 9.48609 8.51391 12.15 11.8 12.15V10.65ZM15.6119 8.49759C14.8314 9.78922 13.4159 10.65 11.8 10.65V12.15C13.9626 12.15 15.8549 10.9958 16.8957 9.27336L15.6119 8.49759ZM7.35 6.2C7.35 4.58413 8.21078 3.16859 9.50241 2.38812L8.72664 1.1043C7.00422 2.14509 5.85 4.03739 5.85 6.2H7.35ZM9 1.75C8.88833 1.75 8.76627 1.70141 8.67775 1.60926C8.60073 1.5291 8.5778 1.44744 8.57162 1.4017C8.56192 1.32989 8.57993 1.19295 8.72664 1.1043L9.50241 2.38812C9.96593 2.10803 10.1117 1.59799 10.0581 1.20094C10.0022 0.786584 9.67036 0.25 9 0.25V1.75ZM16.8957 9.27336C16.807 9.42007 16.6701 9.43808 16.5983 9.42838C16.5526 9.4222 16.4709 9.39927 16.3907 9.32226C16.2986 9.23373 16.25 9.11167 16.25 9H17.75C17.75 8.32964 17.2134 7.99784 16.7991 7.94187C16.402 7.88825 15.892 8.03407 15.6119 8.49759L16.8957 9.27336Z"
				fill={color}
			/>
		</svg>
	);
};

export default MoonIcon;
