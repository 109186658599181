import React from 'react';
import { SideBarMenuItemInterface } from './SideBarMenuItem.interface';
import { SypacIcon, SypacText } from '@sypac/component-library-react';

export const SideBarMenuItem = ({
	active = false,
	url,
	logo,
	alt,
	count,
}: SideBarMenuItemInterface) => {
	const scaleUpIcons = ['Document Add', 'Money Bag', 'Shield Check'];

	return (
		<a
			href={url}
			className={`group relative flex items-center justify-center rounded-10 w-[32px] h-[32px] xl-2xl:w-[42px] xl-2xl:h-[42px] cursor-pointer hover:text-black hover:bg-gray-10 ${
				active
					? 'bg-gradient-primary text-white cursor-auto hover:text-white'
					: 'text-gray-80'
			}`}
		>
			{alt && (
				<div className="hidden group-hover:block absolute text-black left-[55px] px-[10px] py-[5px] h-[20px] rounded top-[6px] bottom-[14px] bg-gray-10 z-30">
					<p className="relative m-0 before:content-[''] before:block before:w-[0px] before:absolute before:border-solid before:border-y-[6px] before:border-r-[6px] before:border-y-transparent before:border-l-transparent before:border-gray-10 before:left-[-16px] before:top-[4px] whitespace-nowrap">
						<SypacText variant="body-regular-medium">
							<p>{alt}</p>
						</SypacText>
					</p>
				</div>
			)}
			<SypacIcon
				icon-name={logo}
				size="custom"
				width="36px"
				height="36px"
				className={`${scaleUpIcons.includes(logo) ? 'xl-2xl:scale-[1.4]' : ''}`}
			/>
			{count ? (
				<div className="absolute -top-3 -right-3 w-5 h-5 flex justify-center items-center bg-red rounded-lg border-2 border-solid border-gray-1">
					<SypacText variant="body-regular-medium">
						<p className="text-xs text-white">{count > 9 ? '9+' : count}</p>
					</SypacText>
				</div>
			) : null}
		</a>
	);
};
